import styled, { css } from 'styled-components'
import * as sc from 'constants/style'

const Placeholder = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 999;
  border: 1px solid
    ${({ disabled }) => (disabled ? sc.GRAY_BASE_HEX : sc.GREEN)};
  background: white;
  z-index: 1;
  ${({ large }) =>
    large &&
    css`
      width: 40px;
      height: 40px;
    `}
`

export default Placeholder;