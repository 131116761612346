import { root } from "index";
import { PureComponent } from "react";
import { createRoot } from "react-dom/client";

export class RenderToBody extends PureComponent {
  componentDidMount() {
    this.outsideWrapper = document.createElement("div");
    document.body.appendChild(this.outsideWrapper);
    this.renderOutsideBody();
  }

  componentDidUpdate() {
    this.renderOutsideBody();
  }

  componentWillUnmount() {
    // ReactDOM.unmountComponentAtNode(this.outsideWrapper);
    //root.unmount();
    document.body.removeChild(this.outsideWrapper);
  }

  renderOutsideBody = () => {
    const { children } = this.props;

    const root = createRoot(this.outsideWrapper);
    root.render(children);
  };

  render() {
    return null;
  }
}

export default RenderToBody;