import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'

import silverPattern from './img/pattern_silver.svg'

const config = {
  duration: 0.3, // seconds
  distance: 4, // pixels
  easing: 'cubic-bezier(0.77, 0, 0.175, 1)',
}

const translateHelper = ({ up, left, bottom, right }) => {
  if (up) {
    return `0, ${config.distance}px, 0`
  }
  if (left) {
    return `${config.distance}px, 0, 0`
  }
  if (bottom) {
    return `0, ${config.distance * -1}px, 0`
  }
  if (right) {
    return `${config.distance * -1}px, 0, 0`
  }

  return '0, 0, 0'
}

const Fader = styled.div`
  opacity: 0;
  transform: translate3d(${props => translateHelper(props)});
  transition: all ${config.duration}s ${config.easing};

  ${({ delay }) =>
    delay &&
    css`
      transition-delay: ${delay}ms;
    `};

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: translate3d(0, 0, 0);
    `}
    
    ${({ hasBackgroundPattern }) =>
    hasBackgroundPattern &&
    css`
      background: url(${silverPattern});
      background-position-x: right;
      background-repeat-x: no-repeat;
      border-bottom: 1px solid #ededed;

      @media all and (max-width: 767px) {
        background: initial;
      }
    `}
`

export class FadeIn extends PureComponent {
  static propTypes = {
    up: PropTypes.bool,
    left: PropTypes.bool,
    bottom: PropTypes.bool,
    right: PropTypes.bool,
    delay: PropTypes.number,
    minTopValue: PropTypes.number,
    partialVisibility: PropTypes.bool,
  }

  static defaultProps = {
    up: true,
    left: false,
    bottom: false,
    right: false,
    delay: 0,
    minTopValue: 100,
    partialVisibility: true,
  }

  state = {
    isVisible: false,
  }

  handleReveal = receivedState => {
    const { isVisible } = this.state

    if (!isVisible) {
      setTimeout(() => this.setState({ isVisible: receivedState }))
    }
  }

  render() {
    const {
      children,
      minTopValue,
      partialVisibility,
      ...otherProps
    } = this.props
    const { isVisible } = this.state

    return (
      <VisibilitySensor
        onChange={this.handleReveal}
        minTopValue={minTopValue}
        partialVisibility={partialVisibility}
      >
        <Fader isVisible={isVisible} {...otherProps}>
          {children}
        </Fader>
      </VisibilitySensor>
    )
  }
}

export default FadeIn
