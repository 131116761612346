import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'
import { breakpoint } from 'utils'
import Breakdown from 'components/ui/breakdown'
import Headline from 'components/ui/headline'
import moment from 'moment'

// fetch data
import fetchDataPublic from 'api/fetch-data-public'

const Container = styled.div`
  width: 100%;
  min-height: 488px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 626px) {
    display: block;
  }
  overflow: visible;
`

const Panel = styled.div`
  flex: 0 0 50%;
  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '50px')});
  transition: all 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0.5')};
  transition-delay: ${({ delay }) => delay}s;

  padding: ${({ bottomText }) => bottomText && '0'};

  ${({ borderRight }) =>
    borderRight &&
    `
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  `}

  ${({ borderLeft }) =>
    borderLeft &&
    `
    border-left: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  `}

  ${({ noBorderBottom }) =>
    noBorderBottom &&
    `
    border-bottom: none;
  `}

  ${({ noBorderTop }) =>
    noBorderTop &&
    `
    border-top: none;
  `}

  width: 50%;
  float: left;

  ${breakpoint.below('tablet')} {
    min-height: 464px;
    padding-bottom: 48px;
  }

  @media screen and (min-width: 626px) and (max-width: 800px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media screen and (max-width: 626px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;

    :last-child {
      border-bottom: none;
    }

    :first-child {
      border-top: none;
    }
  }

  ${({ noPaddingHorizontal }) =>
    noPaddingHorizontal &&
    `
    padding-left: 0 !important;
    padding-right: 0 !important;
  `}
`

const MetaContainer = styled.div`
  margin-top: 250px;
  box-sizing: border-box;

  //background: #ffffff82;
  padding: 1rem 2rem;

  margin-top: 150px;
  min-height: 325px;

  background: ${({ isRed }) => isRed && '#d21e0d'};
  color: ${({ isRed }) => isRed && 'white'};

  background: ${({ isBlue }) => isBlue && '#0050ca'};
  color: ${({ isBlue }) => isBlue && 'white'};

  background: ${({ isWhiteShadowed }) => isWhiteShadowed && '#ffffff82'};
  color: ${({ isWhiteShadowed }) => isWhiteShadowed && 'black'};

  background: ${({ isWhite }) => isWhite && 'white'};
  color: ${({ isWhite }) => isWhite && 'black'};

  background: ${({ isBlackShadowed }) => isBlackShadowed && '#00000070'};
  color: ${({ isBlackShadowed }) => isBlackShadowed && 'white'};

  background: ${({ isBlack }) => isBlack && 'black'};
  color: ${({ isBlack }) => isBlack && 'white'};

  background: ${({ noBackground }) => noBackground && 'none'};
  color: ${({ noBackground }) => noBackground && 'white'};
  margin-top: ${({ noBackground }) => noBackground && '75px'};
  min-height: ${({ noBackground }) => noBackground && '400px'};

  margin-top: ${({ bottomText }) => bottomText && '214px'};

  ${Breakdown} {
    margin-bottom: 24px;
  }
  ${Headline} {
    margin-bottom: 28px;
  }

  ${({ bottomText }) =>
    bottomText &&
    `
    ${Headline} {
      margin-bottom: 65px;
    }
  `}

  ${({ lowHeight }) =>
    lowHeight &&
    `
    min-height: 300px;
    margin-top: 314px;

    ${Breakdown} {
      bottom: 0;
    }

    ${breakpoint.below('tablet')} {
      margin-top: 225px;
      position: relative;
      bottom: -48px;
    }

    @media screen and (min-width: 627px) and (max-width: 991px) {
      margin-top: 250px;
      min-height: 250px;
    }

    @media screen and (max-width: 626px) {
      margin-top: 200px;
      min-height: 200px !important;
    }
  `}

  img {
    height: 8px;
    margin-right: 5px;
  }

  @media screen and (max-width: 626px) {
    min-height: 250px;
    min-height: ${({ noBackground }) => noBackground && 'inherit'};
  }
`

export class StokePosts extends PureComponent {
  state = {
    isVisible: true,
    allstokeposts: [],
  }

  async componentDidMount() {
    this.fetchStokePosts()
  }

  fetchStokePosts = async () => {
    try {
      const fetchStokePost = await fetchDataPublic('stoke-post/get', {
        data: [
          'category',
          'thumbnail_title',
          'image',
          'url',
          'stokepost_page_order',
          'shadowOnBottom',
          'publish_date',
        ],
        filter: {
          status: 'Active',
        },
      })
      const withoutPressRelease = fetchStokePost.list.filter(
        (x) => x.category !== 'PRESS RELEASE',
      )
      this.setState({ allstokeposts: withoutPressRelease })
    } catch (error) {
      console.log('Error fetching STOKE POSTS: ', error)
    }
  }

  handleVisibility = (receivedState) => {
    const { isVisible } = this.state

    if (!isVisible) {
      setTimeout(() => this.setState({ isVisible: receivedState }))
    }
  }

  render() {
    const { isVisible, allstokeposts } = this.state
    const readFullBottom = {
      position: 'absolute',
      bottom: '12%',
    }
    const readFullBottomNoBackground = {
      position: 'absolute',
      bottom: '3%',
    }

    return (
      <VisibilitySensor onChange={this.handleVisibility} partialVisibility>
        <Container>
          {allstokeposts.length > 0 ? (
            allstokeposts
              .sort((a, b) =>
                parseInt(a.stokepost_page_order) >
                parseInt(b.stokepost_page_order)
                  ? 1
                  : -1,
              )
              .map((item, index) => (
                <Panel
                  key={item.url}
                  src={item.image}
                  isVisible={isVisible}
                  delay={0.2}
                  borderRight={index % 2 === 0 ? true : false}
                  borderLeft={index % 2 !== 0 ? true : false}
                  noBorderTop={index === 0 || index === 1 ? true : false}
                  bottomText={item.shadowOnBottom === 'true' ? true : false}
                  noPaddingHorizontal={
                    item.shadowOnBottom === 'true' ? true : false
                  }
                >
                  <MetaContainer
                    isBlackShadowed
                    bottomText={item.shadowOnBottom === 'true' ? true : false}
                    lowHeight={item.shadowOnBottom === 'true' ? true : false}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Breakdown>{item.category}</Breakdown>
                      <Breakdown>
                        {moment(item.publish_date).format('DD MMM YYYY')}
                      </Breakdown>
                    </div>

                    <Link to={'/stoke-post/' + item.url}>
                      <Headline as="h2" stokepostheading>
                        {item.thumbnail_title}
                      </Headline>
                      <Breakdown
                        style={
                          item.shadowOnBottom === 'true'
                            ? readFullBottomNoBackground
                            : readFullBottom
                        }
                      >
                        <strong>
                          {/* <img src={require('./img/chevron.png')} alt="chevron" />  */}
                          <span>Read Full</span>
                        </strong>
                      </Breakdown>
                    </Link>
                  </MetaContainer>
                </Panel>
              ))
          ) : (
            <p></p>
          )}
        </Container>
      </VisibilitySensor>
    )
  }
}
export default StokePosts
