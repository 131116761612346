/*  */

import styled from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const BlockTowerContent = styled.div`
  margin-left: calc(100% / 16 * 2);
  margin-right: auto;
  padding-left: ${sc.BASE_MARGIN * 2}px;
  padding-right: ${sc.BASE_MARGIN * 2}px;
  max-width: ${sc.MAX_WIDTH / 2}px;

  ${breakpoint.below('desktop')} {
    margin-left: 0;
  }
  ${breakpoint.below('tablet')} {
    padding-left: 0;
    padding-right: 0;
  }
  ${breakpoint.below('mobile')} {
    // padding-left: ${sc.BASE_MARGIN * 2}px;
    // padding-right: ${sc.BASE_MARGIN * 2}px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

export default BlockTowerContent
