import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Breakdown, Headline, RichText, SocialUrls, Video } from 'components/ui'
import SocialUrlsPT from 'components/ui/socialUrls/SocialUrlsPT'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import avatarPlaceholder from 'images/avatar-placeholder.png'

const Photo = styled.img`
  border-radius: 50%;
  height: 75px;
  width: 75px;
  object-fit: cover;
  margin-right: ${sc.BASE_MARGIN}px;

  ${({ isAssociation }) =>
    isAssociation &&
    css`
      border-radius: unset;
      width: unset;
    `}

  ${({ isLhoft }) =>
    isLhoft &&
    css`
      position: relative;
      top: 6px;
    `}

      ${({ isQuote }) =>
    isQuote &&
    css`
      height: 48px;
      width: 48px;

      ${breakpoint.below('mobile')} {
        margin-right: 14px;
      }
    `}
`

const Header = styled.div`
  display: flex;
  align-items: center;

  ${Breakdown} {
    color: ${sc.GRAY_SECONDARY_HEX};
    letter-spacing: 2px;
    font-size: 9px;
  }
`
const Outer = styled.div``

const UserName = styled.div`
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: rgb(32, 32, 32);
  font-weight: 600;

  ${({ lite }) =>
    lite &&
    `
  font-weight: 300;

  `};
`

const SocialsOuter = styled.div`
  margin-top: ${sc.BASE_MARGIN * 1.5}px;
  ${SocialUrls} {
    color: ${sc.RED_BASE_HEX};
  }
`

const Description = styled.div`
  margin-top: ${sc.BASE_MARGIN * 1.5}px;
  min-height: 100px;

  ${({ isQuote }) =>
    isQuote &&
    css`
      ${breakpoint.below('mobile')} {
        margin-top: 30px;
      }
      & > h4 {
        line-height: 28px;
        font-size: 22px;
        margin: 0;
      }
    `}
`
const StyledSlideImage = styled.div`
  background-size: cover;
  background-position: center;
  padding-top: 56.25%;
`

export const UserPreview = memo((props) => {
  const { lite } = props

  const avatar =
    props.photoUrl === 'error' || props.photoUrl === undefined
      ? avatarPlaceholder
      : props.photoUrl

  return (
    <Outer>
      <Header>
        <Photo src={avatar} {...props} />
        <div>
          <UserName lite={lite}>{props.userName}</UserName>
          <Breakdown withWordWrap>{props.position}</Breakdown>
        </div>
      </Header>
      {props.description && (
        <Description {...props}>
          {props.isQuote ? (
            <Headline as="h4"> {props.description}</Headline>
          ) : (
            <RichText>{props.description}</RichText>
          )}
        </Description>
      )}
      {props.isVideo && (
        <Description>
          <Video
            src={props.videoUrl}
            iframe
            //onOpen={() => setisVideoPlaying(true)}
            //onClose={() => setisVideoPlaying(false)}
            poster={
              <StyledSlideImage
                style={{
                  backgroundImage: `url(${props.videoThumbnailUrl})`,
                }}
              />
            }
          />
        </Description>
      )}

      {!props.isQuote && (
        <SocialsOuter>
          <SocialUrls small color="transparent" {...props} />
        </SocialsOuter>
      )}
    </Outer>
  )
})

UserPreview.propTypes = {
  photoUrl: PropTypes.string,
  userName: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  lite: PropTypes.bool,
  ...SocialUrlsPT,
}

export default UserPreview
