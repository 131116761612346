import React from 'react'
import { useParams } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { generateUrl } from 'utils'

const RoutedLink = ({ attr = {}, to, staticContext, ...props }) => {
  const { params } = useParams()
  return <HashLink to={generateUrl(to, { ...params, ...attr })} {...props} />
}

export const Link = RoutedLink

export default Link
