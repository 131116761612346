import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

import Icon from 'components/ui/icon';

export const StyledSliderButton = styled.button`
  display: block;
  border: 1px solid transparent;
  width: 64px;
  height: 56px;
  line-height: 56px;
  background: ${sc.RED_BASE_HEX};
  text-align: center;
  cursor: pointer;
  border-left: 1px solid
    ${props => (props.left ? 'transparent' : sc.RED_BASE_RELEASE_HEX)};
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: background-color 0.3s;
  outline: none;

  /* Make an entry for each position (e.g. left: auto;) */
  ${({ position }) =>
    position &&
    Object.keys(position)
      .map(direction => `${direction}: ${position[direction]};`)
      .join('')}

  ${breakpoint.below('tablet')} {
    height: 48px;
    line-height: 48px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${sc.RED_BASE_RELEASE_HEX};
  }

  ${({ arrowStyle }) => arrowStyle}
`

class SliderArrow extends PureComponent {
  static propTypes = {
    left: PropTypes.bool,
    position: PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    onClick: PropTypes.func,
  }

  static defaultProps = {
    left: false,
    onClick: () => false,
  }

  render() {
    const { position, onClick, left, arrowStyle } = this.props

    return (
      <StyledSliderButton
        arrowStyle={arrowStyle}
        className={left ? 'slick-button-prev' : 'slick-button-next'}
        position={position}
        left={left}
        onClick={onClick}
      >
        <Icon name="Arrow" direction={left ? 'left' : 'right'} />
      </StyledSliderButton>
    )
  }
}

export default SliderArrow
