import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { breakpoint, config } from 'utils'
import * as sc from 'constants/style'
import Breakdown from 'components/ui/breakdown'
import Headline from 'components/ui/headline'
import FadeIn from 'components/ui/fadeIn'
import Video from 'components/ui/video'
import BackgroundPattern from 'components/ui/backgroundPattern'
import StyledDots from 'components/ui/slider/StyledDots'
import pattern from './img/pattern_silver.svg'

const stokrVision =
  'https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1622816572/How_STOKR_Works/GettyImages-672150891_d2tmkr_ixykkf_ylzhtx.webp'
//import iconBlue from './img/icon-blue.svg'

const iconBlue = require('./img/icon-blue.svg')

const cardWidth = 500
const arrowHeight = 48
const paddingTop = 56
const backgroundImageHeight = 216

const CardOutter = styled.section`
  z-index: 2;
  //margin-top: ${arrowHeight}px;
  margin-top: 0px;
  margin-bottom: 51px;

  @media screen and (max-width: ${config.mobile}) {
    padding: 0 8px !important;
  }

  @media screen and (min-width: 992px) and (max-width: 1099px) {
    margin-top: 0;
  }

  @media screen and (max-width: 767px) {
    //padding: 0 16px;
    padding: 0 !important;
  }
`

const CardTop = styled.section`
  min-height: 647px;
  // min-width: 1150px;
  min-width: fit-content;

  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  background-position: 100% 25%;

  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    min-height: 400px;
  }

  @media screen and (min-width: 992px) and (max-width: 1099px) {
    min-height: 400px;
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    min-height: 400px;
  }

  @media screen and (max-width: ${config.tablet}) {
    /*min-height: 256px;
    min-width: 256px;
    max-width: 256px;
    min-height: 487px; */
    min-width: 100%;
    min-height: 256px;
  }
`

export const ContainerWithPattern = styled.div`
  background: url(${pattern});
  //background-position: -95%;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: ${config.mobile}) {
    background-position: 35%;
  }
  @media screen and (min-width: ${config.tablet}) {
    background-position: 45%;
  }
`

const TrendingVenturesWrapper = styled.div`
  //width: 1886px;
  overflow: hidden;
`

const HeadlineMetaWrapper = styled.div`
  padding: 0 32px;

  @media screen and (max-width: ${config.mobile}) {
    /* margin-top: -96px; */
    padding-top: 40px;
  }
  @media screen and (min-width: ${config.tablet}) {
    padding-top: 92px;
    padding-bottom: 88px;
    img {
      display: none;
    }
  }
  @media screen and (max-width: ${config.tablet}) {
    /* padding-top: 72px; */
    /* padding-bottom: 88px; */
    padding-top: 56px;
    padding-bottom: 16px;
    img {
      display: block;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    // padding-top: 92px;
    // padding-left: 64px;
    // padding-bottom: 72px;

    padding-top: 92px;
    padding-left: 64px;
    padding-right: 192px;
    padding-bottom: 72px;
  }

  ${Breakdown} {
    line-height: 1;
    margin-bottom: 28px;
  }

  ${Headline} {
    line-height: 0.8;
    margin-block-start: 40px;
    margin-block-end: 0;

    @media screen and (max-width: ${config.tablet}) {
      margin-block-start: 36px;
      line-height: 1.18;
    }

    @media screen and (max-width: ${config.mobile}) {
      line-height: 24px;
    }
  }
`

const StyledSlick = styled.div`
  /* REMOVE WHEN IMPLEMENTING THE CAROUSEL  */
  display: flex;
  justify-content: center;
  height: 693px;

  padding: 0 4rem;

  ${breakpoint.below('desktop')} {
    height: 586px;
  }

  // @media screen and (max-width: 1019px) {
  //   height: 446px;
  // }

  @media screen and (max-width: 769px) {
    height: 586px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    display: initial;
    height: 400px;
  }

  @media screen and (min-width: 992px) and (max-width: 1019px) {
    height: 446px;
  }

  @media screen and (min-width: 1020px) and (max-width: 1300px) {
    height: 446px;
  }

  /* ------------------------------------------ */

  .slick-track,
  .slick-list,
  .slick-track div {
    /* height: 100%; */
  }

  .slick-slider {
    padding-top: ${paddingTop}px;
    padding-bottom: 64px;
    z-index: 2;
    max-height: 608px;
  }

  div {
    outline: none;
    width: 100%;
  }

  position: relative;

  ${(props) => props.customStyle}

  &::before {
    // content: '';
    // position: absolute;
    // top: 0;
    // left: 0;
    // background: ${sc.BLUE_BASE_HEX};
    // width: 160px;
    // height: ${paddingTop + backgroundImageHeight}px;

    // @media screen and (max-width: 1280px) {
    //   width: 256px;
    // }

    // @media screen and (max-width: 1020px) {
    //   width: 160px;
    // }

    // @media screen and (min-width: 1021px) {
    //   height: 268px;
    // }

    // @media screen and (min-width: 1650px) {
    //   left: -100px;
    //   width: 275px;
    // }

    // @media screen and (min-width: 1888px) {
    //   /* left: 0; */
    //   width: 375px;
    // }
  }
  &::after {
    // content: '';
    // position: absolute;
    // top: 0;
    // right: 0;
    // width: ${sc.COLUMN * 4}px;
    // height: 100%;
    // z-index: -1;
    // background-image: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);

    // @media screen and (min-width: 1888px) {
    //   /* left: 0; */
    //   width: 275px !important;
    // }
  }

  .slick-dots {
    transform: translateX(50%);
    right: 50%;
  }

  .slick-active ${StyledDots}::before {
    background: ${sc.BLUE_BASE_HEX};
  }

  @media screen and (max-width: ${config.tablet}) {
    &::before {
      display: none;
    }
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    &::after {
      display: none;
    }
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    .slick-slider {
      max-height: 798px;
      max-height: 680px;
    }
  }

  @media screen and (min-width: ${cardWidth * 3 + sc.COLUMN * 2}px) {
    .slick-button-prev {
      left: calc(50vw + 384px + 128px);
    }
    .slick-button-next {
      left: calc(50vw + 384px + 192px);
    }
    &::after {
      left: calc(50vw + 384px + 128px);
      width: ${cardWidth + sc.COLUMN}px;
    }
  }

  @media screen and (min-width: ${cardWidth * 5 - sc.COLUMN / 2}px) {
    .slick-button-prev {
      left: unset;
      right: ${cardWidth - 17}px;
    }
    .slick-button-next {
      left: unset;
      right: calc(${cardWidth}px - 64px - 17px);
    }

    &::after {
      left: unset;
      right: 0;
      width: ${cardWidth + sc.COLUMN - 17}px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    height: 445px;
  }
`

const StyledSlickVideo = styled.div`
  display: flex;
  justify-content: center;

  ${breakpoint.below('desktop')} {
    height: 586px;
  }

  @media screen and (max-width: 1019px) {
    height: 400px;
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    height: 400px;
  }

  @media screen and (max-width: 769px) {
    height: 256px;
  }

  /* ------------------------------------------ */

  .slick-track,
  .slick-list,
  .slick-track div {
    /* height: 100%; */
  }

  .slick-slider {
    padding-top: ${paddingTop}px;
    padding-bottom: 64px;
    z-index: 2;
    max-height: 608px;
  }

  div {
    outline: none;
    width: 100%;
  }

  position: relative;

  ${(props) => props.customStyle}

  &::before {
    // content: '';
    // position: absolute;
    // top: 0;
    // left: 0;
    // background: ${sc.BLUE_BASE_HEX};
    // width: 160px;
    // height: ${paddingTop + backgroundImageHeight}px;

    // @media screen and (max-width: 1280px) {
    //   width: 256px;
    // }

    // @media screen and (max-width: 1020px) {
    //   width: 160px;
    // }

    // @media screen and (min-width: 1021px) {
    //   height: 268px;
    // }

    // @media screen and (min-width: 1650px) {
    //   left: -100px;
    //   width: 275px;
    // }

    // @media screen and (min-width: 1888px) {
    //   /* left: 0; */
    //   width: 375px;
    // }
  }
  &::after {
    // content: '';
    // position: absolute;
    // top: 0;
    // right: 0;
    // width: ${sc.COLUMN * 4}px;
    // height: 100%;
    // z-index: -1;
    // background-image: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);

    // @media screen and (min-width: 1888px) {
    //   /* left: 0; */
    //   width: 275px !important;
    // }
  }

  .slick-dots {
    transform: translateX(50%);
    right: 50%;
  }

  .slick-active ${StyledDots}::before {
    background: ${sc.BLUE_BASE_HEX};
  }

  @media screen and (max-width: ${config.tablet}) {
    &::before {
      display: none;
    }
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    &::after {
      display: none;
    }
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    .slick-slider {
      max-height: 798px;
      max-height: 680px;
    }
  }

  @media screen and (min-width: ${cardWidth * 3 + sc.COLUMN * 2}px) {
    .slick-button-prev {
      left: calc(50vw + 384px + 128px);
    }
    .slick-button-next {
      left: calc(50vw + 384px + 192px);
    }
    &::after {
      left: calc(50vw + 384px + 128px);
      width: ${cardWidth + sc.COLUMN}px;
    }
  }

  @media screen and (min-width: ${cardWidth * 5 - sc.COLUMN / 2}px) {
    .slick-button-prev {
      left: unset;
      right: ${cardWidth - 17}px;
    }
    .slick-button-next {
      left: unset;
      right: calc(${cardWidth}px - 64px - 17px);
    }

    &::after {
      left: unset;
      right: 0;
      width: ${cardWidth + sc.COLUMN - 17}px;
    }
  }

  img {
    position: relative;
    top: calc(50% - 45px);

    @media screen and (min-width: 992px) and (max-width: 1300px) {
      top: 132px !important;
    }

    @media screen and (min-width: 1301px) {
      top: calc(50% - 80px);
    }
  }
`

const StyledSlickWhite = styled.div`
  background: #fff;
  max-width: 563px;
  margin-left: 1rem;
  height: 647px;
  // margin-top: 3rem;
  padding: 2rem;
  width: 35% !important;

  border: 1px solid #ededed;

  img {
    height: 150px;
    width: 100%;
    margin-top: 1rem;

    @media screen and (min-width: 769px) and (max-width: 1099px) {
      height: 50px !important;
      margin-top: 0rem;
    }

    @media screen and (max-width: 1300px) {
      height: 39px;
      margin-top: 0;
      width: auto;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    width: 45% !important;
    height: 400px;
  }

  @media screen and (min-width: 992px) and (max-width: 1099px) {
    width: 50% !important;
    height: 400px;
    margin-top: 0;
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    width: 70% !important;
    height: 400px;
    margin-top: 0;
  }

  @media screen and (max-width: 769px) {
    min-width: 100%;
    width: 100% !important;
    margin-left: 0;
    height: 300px;
    border: none;
    background: ${sc.BLUE_BASE_HEX};
    img {
      display: none;
    }
  }

  ${breakpoint.below('tablet')} {
    //width: 50%;
  }
`

const TextHowItWorks = styled.div`
  margin: 0.5rem 0;
  min-height: 47%;
  padding-top: 1rem;

  @media screen and (min-width: 992px) and (max-width: 1099px) {
    min-height: 62%;
    h2 {
      margin-top: 0;
      display: none;
    }
  }

  @media screen and (max-width: 1300px) {
    min-height: 47%;
    h2 {
      display: none;
    }
  }

  @media screen and (max-width: 769px) {
    min-height: 35%;
    color: #fff;
    margin: 0;
    padding-top: 0;
    margin-bottom: 1rem;
  }
`

const ButtonHowItWorksBlue = styled.div`
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  border-radius: 25px;
  font-size: 11px;
  font-weight: bold;
  -webkit-letter-spacing: 2px;
  -moz-letter-spacing: 2px;
  -ms-letter-spacing: 2px;
  letter-spacing: 2px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  background: #0050ca;
  color: white;
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  margin: 0.5rem 0;
  margin-top: 3rem;

  @media screen and (max-width: 769px) {
    background: #fff;
    color: #0050ca;
  }
`

// const ButtonHowItWorksWhite = styled.div`
//   -webkit-transition: all 0.3s;
//   transition: all 0.3s;
//   display: -webkit-inline-box;
//   display: -webkit-inline-flex;
//   display: -ms-inline-flexbox;
//   display: inline-flex;
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   position: relative;
//   border-radius: 25px;
//   font-size: 11px;
//   font-weight: bold;
//   -webkit-letter-spacing: 2px;
//   -moz-letter-spacing: 2px;
//   -ms-letter-spacing: 2px;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   outline: none;
//   cursor: pointer;
//   white-space: nowrap;
//   border: 2px solid #0050ca;
//   background: white;
//   color: #0050ca;
//   height: 40px;
//   padding-left: 32px;
//   padding-right: 32px;
//   width: 100%;
//   margin: 0.5rem 0;

//   @media screen and (max-width: 769px) {
//     background: #0050ca;
//     color: #fff;
//     border: 1px solid;
//   }
// `

const StyledBackgroundPattern = styled(BackgroundPattern)`
  position: absolute;
  height: 116px;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  z-index: -1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 31%;
    background: ${sc.BLUE_BASE_HEX};
    z-index: 1;

    ${breakpoint.below('tablet')} {
      width: 35%;
    }

    ${breakpoint.below('mobile')} {
      display: none;
    }
  }

  // &::after {
  //   z-index: 0;
  //   /* background: url(${pattern}) repeat; */
  // }

  @media screen and (min-width: 992px) and (max-width: 1099px) {
    bottom: 0;
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    //bottom: 22%;
    bottom: 0;
  }
`

const InnerBackground = styled.div`
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${sc.BLUE_BASE_HEX};
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 30%;
    border: solid transparent;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 0 300px 450px 0;
    border-color: transparent ${sc.BLUE_BASE_HEX} transparent transparent;
    transform: scaleX(-1);
    z-index: 1;

    ${breakpoint.below('tablet')} {
      left: 35%;
    }
    ${breakpoint.below('mobile')} {
      left: 0;
    }
  }
`
const postLink = {
  opacity: 1,
}

const sliderData = [
  (props) => (
    <Video
      src={require('./img/how-stokr-works.mp4')}
      onOpen={props.videoOnOpen}
      onClose={props.videoOnClose}
      isCenter
      isSmallCard
      poster={
        <CardOutter>
          <CardTop src={stokrVision} />
        </CardOutter>
      }
    />
  ),
]

export class HowItWorksHomePageBlock extends PureComponent {
  render() {
    return (
      <TrendingVenturesWrapper>
        <ContainerWithPattern>
          <FadeIn delay={80}>
            <HeadlineMetaWrapper>
              <Breakdown>HOW STOKR WORKS</Breakdown>
              <Headline as="h2">A NEW WORLD OF OPPORTUNITIES</Headline>
              {/* <img src={iconBlue} width="40" height="150" alt="blueIcon" /> */}
            </HeadlineMetaWrapper>
          </FadeIn>
          <FadeIn delay={80} partialVisibility>
            <StyledSlick>
              <StyledSlickVideo>
                {sliderData.map((Card, index) => (
                  <Card key={index} />
                ))}
              </StyledSlickVideo>
              <StyledSlickWhite>
                <img src={iconBlue} width="40" alt="blueIcon" loading="lazy" />
                <TextHowItWorks>
                  <Headline as="h2">SHAPING THE FUTURE</Headline>
                  Invest in companies you believe in. Built on the blockchain
                  for more independency, more transparency and no middlemen.
                </TextHowItWorks>
                <Link style={postLink} to="/how-stokr-works">
                  <ButtonHowItWorksBlue>Learn more</ButtonHowItWorksBlue>
                </Link>
                {/* <Link style={postLink} to="/ventures">
                  <ButtonHowItWorksWhite>For Ventures</ButtonHowItWorksWhite>
                </Link> */}
              </StyledSlickWhite>
              <StyledBackgroundPattern>
                <InnerBackground />
              </StyledBackgroundPattern>
            </StyledSlick>
          </FadeIn>
        </ContainerWithPattern>
      </TrendingVenturesWrapper>
    )
  }
}

export default HowItWorksHomePageBlock
