export const ProjectTypes = {
  FUND: 'fund',
  COMPANY: 'company',
  TRANCHES: 'tranches',
}

export const ProjectStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ON_HOLD: 'On_hold',
}

export const ProjectStates = {
  ON_HOLD: 'ON HOLD',
  LIVE: 'LIVE',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  CLOSED_SUCCESSFULLY: 'CLOSED SUCCESSFULLY',
  UPCOMING: 'UPCOMING',
}

export const ShareTypes = {
  PROFIT: 'Profit share',
  REVENUE: 'Revenue share',
  TARGET_RETURN: 'Target return',
}
