import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const colorHelper = colors => {
  if (colors.gray) {
    return '#F0F0F0'
  }

  if (colors.red) {
    return sc.RED_BASE_RELEASE_HEX
  }

  if (colors.blue) {
    return sc.BLUE_BASE_HEX
  }

  return sc.WHITE_HEX
}

export const TimelineArrow = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  display: block;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    border-bottom: 21px solid ${colorHelper};

    ${({ mobileXs }) =>
      mobileXs &&
      css`
        ${breakpoint.below('mobile')} {
          border-left-width: 8px;
          border-right-width: 8px;
          border-bottom-width: 8px;
        }
      `}
  }
`

TimelineArrow.propTypes = {
  mobileXs: PropTypes.bool, // make it smaller on mobile
  white: PropTypes.bool,
  gray: PropTypes.bool,
  red: PropTypes.bool,
  blue: PropTypes.bool,
}
