import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from 'components/ui/icon'
import * as sc from 'constants/style'
import { config } from 'utils'
import $ from 'jquery'

const AccordionItemOuter = styled.div`
  position: relative;
`

const IconWrapper = styled.div`
  transform: rotateZ(
    ${({ open, mobile }) => {
      if (mobile && open) {
        return '180deg'
      }
      if (open) {
        return '90deg'
      }
      return '0deg'
    }}
  );
  transition: transform 0.3s;
  width: 8px;
  margin-right: 15px;
  svg {
    width: ${({ mobile }) => (mobile ? '13px' : '8px')};
    polygon {
      fill: ${({ mobile }) => (mobile ? sc.BASE_COLOR_HEX : sc.RED_BASE_HEX)};
    }
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: ${config.tablet}) {
    margin-right: 0px;
  }
`

const IconsWrapper = styled.div`
  display: flex;
  ${IconWrapper} {
    margin-left: 27px;
    &::after {
      bottom: 0;
    }
  }
`

const openCss = css``

const openHelper = ({ open }) => open && openCss

const AccordionItemTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding: ${sc.COLUMN / 2}px ${sc.COLUMN / 2}px;
  border-bottom: 1px solid ${sc.GRAY_BASE_HEX};
  font-weight: 700;
  height: 104px;

  ${({ mobile }) =>
    mobile &&
    css`
      border-bottom: none;
      border-top: 1px solid ${sc.GRAY_BASE_HEX};
    `}

  ${({ mobile }) =>
    !mobile &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 100%;
        background: red;
        left: 0;
        top: 0;
        transform: translateX(${({ open }) => (open ? '0' : '-5px')});
        transition: transform 0.3s;
      }
    `}

  && {
    strong {
      font-weight: 600;
    }
  }
  ${openHelper};
  @media screen and (max-width: ${config.tablet}) {
    border-bottom: ${({ open }) => open && 'none'};
  }
`
const AccordionItemTitleText = styled.div`
  font-size: 11px;
  display: flex;
`

const AccordionItemTitleTextH1 = styled.h2`
  font-size: 11px;
  display: flex;
`

const AccordionItemContent = styled.div`
  transition: all 0.3s;
  /* border-bottom: 1px solid ${sc.GRAY_BASE_HEX}; */
`
const AccordionContentOuter = styled.div`
  overflow-x: hidden;
`

export class AccordionCheckMark extends PureComponent {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  }

  refContent = null

  state = {
    //open: this.props.open,
    open: window.location.pathname.includes('101') ? false : this.props.open,
  }

  handleContentRef = (node) => {
    const { open } = this.props
    this.refContent = node

    if (!open) {
      $(node).hide()
    }
  }

  handleOpen = () => {
    const { open } = this.state

    if (!open) {
      $(this.refContent).slideDown()
    } else {
      $(this.refContent).slideUp()
    }

    this.setState({ open: !open })
  }

  openMenu = () => {
    $(this.refContent).slideDown()
    this.setState({ open: true })
  }

  closeMenu = () => {
    $(this.refContent).slideUp()
    this.setState({ open: false })
  }

  componentDidMount() {
    this.handleOpen()
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.props
    const { active: prevActive } = prevProps

    if (active !== prevActive) {
      if (active) {
        this.openMenu()
      } else {
        this.closeMenu()
      }
    }
  }

  render() {
    const {
      title,
      children,
      noArrow,
      RenderIcon,
      completed,
      disabled,
      mobile,
      active,
    } = this.props
    const { open } = this.state

    return (
      <AccordionItemOuter>
        <AccordionItemTitle
          open={active}
          onClick={this.handleOpen}
          mobile={mobile}
        >
          <AccordionItemTitleText>
            {!mobile && (
              <IconWrapper open={open}>
                {!noArrow && <Icon name="ArrowSimple" direction="right" open />}
              </IconWrapper>
            )}
            {title}
          </AccordionItemTitleText>

          <IconsWrapper>
            {mobile && RenderIcon ? <RenderIcon active small /> : null}
            {!mobile && RenderIcon && (
              <RenderIcon active={completed} small disabled={disabled} />
            )}
            {/* {!mobile && RenderIcon ? (
              <RenderIcon active={completed} small disabled={disabled} />
            ) : (
              <IconWrapper open={open} mobile>
                <Icon name="ArrowSimple" direction="down" open />
              </IconWrapper>
            )} */}
          </IconsWrapper>
        </AccordionItemTitle>
        <AccordionContentOuter open={open} ref={this.handleContentRef}>
          <AccordionItemContent>{children}</AccordionItemContent>
        </AccordionContentOuter>
      </AccordionItemOuter>
    )
  }
}

export default AccordionCheckMark
