import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CountUp from 'react-countup'
import { breakpoint } from 'utils'
import VisibilitySensor from 'react-visibility-sensor'
import * as sc from 'constants/style'

const StyledCapital = styled.div`
  font-size: 55px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.04;
  letter-spacing: 2.4px;
  color: ${sc.WHITE_HEX};
  margin: ${sc.BASE_MARGIN * 2}px 0;
  ${breakpoint.below('desktop')} {
    font-size: 34px;
  }
`

export class CapitalRaised extends PureComponent {
  static propTypes = {
    currencySign: PropTypes.string,
    amount: PropTypes.number,
  }

  state = {
    isVisible: false,
  }

  handleVisibilityChange = newVisibility => {
    const { isVisible } = this.state

    if (!isVisible) {
      this.setState({ isVisible: newVisibility })
    }
  }

  render() {
    const { currencySign, amount } = this.props
    const { isVisible } = this.state

    return (
      <StyledCapital>
        <VisibilitySensor onChange={this.handleVisibilityChange}>
          {isVisible ? (
            <span>
              {currencySign}&nbsp;
              <CountUp
                end={amount}
                duration={sc.CAPITAL_ANIMATION_LENGTH}
                delay={0.25}
                separator=","
              />
            </span>
          ) : (
            <span>{currencySign} 0</span>
          )}
        </VisibilitySensor>
      </StyledCapital>
    )
  }
}

export default CapitalRaised
