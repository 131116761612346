import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const bg = require('./img/bg.svg')
const bgRed = require('./img/bg-red.svg')

const reflexCss = css`
  transform: scaleY(-1);
`

const relfectHelper = ({ reflect }) => reflect && reflexCss

export const BackgroundPattern = styled.div`
  position: relative;

  &:after {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: url(${({ red }) => (red ? bgRed : bg)}) repeat;
    ${relfectHelper}
  }
`

BackgroundPattern.propTypes = {
  red: PropTypes.bool,
}

export default BackgroundPattern
