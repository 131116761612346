/*  */
import styled, { css } from 'styled-components'
import { breakpoint } from 'utils'
import dropdown from 'images/arrow-down-black.svg'

export const Breakdown = styled.div`
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '11px')};
  white-space: ${({ withWordWrap }) => (withWordWrap ? 'unset' : 'nowrap')};
  line-height: 2.18;
  color: inherit;
  font-weight: bolder;
  strong& {
    font-weight: bold;
  }

  ${({ is101 }) =>
    is101 &&
    css`
      text-transform: unset;
    `}

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;

      ${breakpoint.below('mobile')} {
        display: block;
      }
    `}

  ${({ nomobile }) =>
    nomobile &&
    css`
      display: block;

      ${breakpoint.below('mobile')} {
        display: none;
      }
    `}

      ${props =>
        props.withPadding &&
        `
        padding:18px 16px;
      `}
      ${props =>
        props.red &&
        `
    background: #D2200D;
    color:white;
        border-bottom: 1px solid #979797;
  `}

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}

  @media screen and (max-width: 991px) {
    ${({ menuWithArrow }) =>
      menuWithArrow &&
      css`
        align-items: center;
        display: flex;
        justify-content: space-between;
      `}  
  }

  ${({ mobileMenu }) =>
    mobileMenu &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
     ${props =>
       props.asLink &&
       `
      cursor:pointer;
      &:hover {
        opacity: .6;
      }
    `}

    ${props =>
      props.withIcon &&
      `
      ${breakpoint.below('tablet')}{

        span {


        position:relative;
        
        &: before {
          content: url(${dropdown});
          position:absolute;
          top: -4px;
          right: -2em;
          width:12px;
          transition: transform 0.7s;

        }
        }

        
       
      }
    `}

     ${props =>
       props.active &&
       `
       ${breakpoint.below('tablet')} {

         &:before {
           
           transform: rotate(180deg);
          }
        }

      
        `}
`

export default Breakdown
