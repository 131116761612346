/* Libraries */
import axios from 'model/axios-public'

const postDataNoAuth = async (url, data, options = {}) => {
  try {
    const result = await axios.post(url, data, options)
    return result.data
  } catch (error) {
    // In failure, throw error
    console.log(`Error: ${error}`)
    throw error
  }
}

export default postDataNoAuth
