import styled from 'styled-components/macro'
import grid from 'components/signup/styles/grid'
import rwd from 'components/signup/styles/rwd'
import theme from 'components/signup/styles/theme'

export const Wrapper = styled.div`
  height: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}

  ${props =>
    props.right &&
    `
    justify-content: right;
  `}

  ${props =>
    props.spaceBetween &&
    `
    justify-content: space-between;
  `}

  ${props =>
    props.stretch &&
    `
    height: 100%;
    align-items: stretch;
  `}

  ${props =>
    props.dashboard &&
    `
    margin: -16px;
  `}

  ${props =>
    props.nowrap &&
    `
    flex-wrap: nowrap;
  `}

  ${rwd.Medium`
    ${props =>
      props.main &&
      `
      z-index: 0;
      min-height: calc(100vh - ${theme.footerHeight}px);
      padding-top: ${theme.headerHeight}px;
    `}
  `}

  height: ${({ isConfirmModal }) => isConfirmModal && '400px'};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}

  ${props =>
    props.alignCenter &&
    `
    align-items: center;
  `}

  ${props =>
    props.spaceBetween &&
    `
    justify-content: space-between;
  `}

  ${props =>
    props.flexEnd &&
    `
    justify-content: flex-end;
  `}

  ${props =>
    props.mainRelative &&
    `
    padding: 18px 0 28px;
  `}

  ${props =>
    props.isConfirmModal &&
    `
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      height: 100vh;
    }
  `}

  ${rwd.Medium`
    ${props =>
      props.part &&
      `
      width: ${grid(props.part, props.full)};
    `}

    ${props =>
      props.mainFixed &&
      `
      position: fixed;
      top: ${theme.headerHeight}px;
      left: 0;
      height: calc(100% - ${theme.headerHeight}px);
    `}

    ${props =>
      props.mainRelative &&
      `
      position: relative;
      margin-left: ${grid((props.full || 16) - props.part, props.full)};
      border-left: 1px solid ${theme.cLightGrey};
      padding: 54px 0 42px;
    `}

    ${props =>
      props.withBorder &&
      `
      border-left: 1px solid ${theme.cLightGrey};
    `}
  `}
`

export const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${Row} {
    flex-grow: 1;
  }
`
