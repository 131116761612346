import PropTypes from 'prop-types'

export const SocialUrlsPT = {
  small: PropTypes.bool,
  linkedIn: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  facebook: PropTypes.string,
  telegram: PropTypes.string,
  youtube: PropTypes.string,
  reddit: PropTypes.string,
  color: PropTypes.string,
  noborder: PropTypes.bool,
  className: PropTypes.string,
}

export default SocialUrlsPT
