export const en = {
  'text.capitalRaised': 'capital raised',
  'text.footer.copyright': `Copyright ${new Date().getFullYear()} STOKR. All rights reserved.`,
  // 'text.footer.column1': 'new to stokr?',
  // 'text.footer.column2': 'about us',
  // 'text.footer.column3': 'other stuff',
  'text.footer.column1': 'New to STOKR',
  'text.footer.column2': 'About us',
  'text.footer.column3': 'General',
  'text.footer.letConnect': 'let’s connect',
  'text.footer.subscribeTitle': 'SIGN UP FOR THE STOKR NEWSLETTER',
  'text.footer.privacy': 'Privacy',
  'text.footer.legal': 'Legal',

  /**
   * Buttons
   */
  'button.becomeInvestor': 'become an investor',
  'button.update': 'update',
  'button.cancel': 'cancel',
  /**
   * Menu
   */
  'menu.invest': 'invest',
  'menu.community': 'community',
  'menu.learn': 'learn',
  'menu.for.howstokrworks': 'for investors',
  'menu.for.opportunities': 'Invest',

  'menu.for.featuredventures': 'for ventures',
  'menu.for.offering': 'products',
  'menu.for.media': 'media',

  'menu.for.aboutus': 'team',
  'menu.offering.capital': 'fund tokenization',
  'menu.offering.tokenisation': 'asset tokenization',
  'menu.offering.fundraising': 'sme fundraising',

  'menu.media.press': 'press releases',
  'menu.media.stokepost': 'stoke post',

  'menu.the.post': 'the post',
  'menu.dashboard': 'dashboard',
  'menu.profile.and.privacy': 'profile and privacy',
  'menu.ethereum.addresses': 'ethereum addresses',
  'menu.algorand.addresses': 'algorand addresses',

  'menu.liquid.address': 'liquid securities account',

  'menu.overview': 'overview',
  'menu.pitch': 'pitch',
  'menu.team': 'team',
  'menu.terms': 'terms',
  'menu.updates': 'updates',
  'menu.comments': 'comments',
  /**
   * Updates
   */
  'updates.message.confirmation': 'Are you sure?',
  'updates.message.notifyInvestors': 'Update will be sent to all investors.',
  'updates.message.published': 'Update published and emailed to all investors',
}

export default en
