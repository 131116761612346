export default {
  colours: {
    palette: {
      main: '#0050CA',
      dark: '#004BB7',
      textDark: '#000000',
      light: '#FFFFFF',
    },
    menu: {
      button: '#202020',
    },
    disabledWhite: '#E8E8E8',
    status: {
      error: '#E3200A',
    },
    background: {
      blue: '#074BB7',
      red: '#E3200A',
      gray: 'rgba(240, 240, 240, .3)',
      white: '#FFFFFF',
    },
  },
  transition: (...properties) =>
    properties.map(property => `${property} .2s ease-in-out`).join(','),
}
