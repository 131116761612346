import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import RichText from 'components/ui/richText'
import { breakpoint } from 'utils'
import * as sc from 'constants/style'
import { TimelineHeadline, TimelineDate } from './commons'

const NumberWrapper = styled.div`
  flex: 0 0 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    /* display: block; */
    display: none;
    position: absolute;
    width: 50%;
    height: 1px;
    background: #f0f0f0;
    z-index: 0;
    top: 50%;
    left: 50%;
  }

  ${breakpoint.below('mobile')} {
    margin: 0 -32px;
    width: calc(100% + 32px + 32px);
    padding: 0 32px;
    padding-bottom: ${({ imageBottomPadding }) => imageBottomPadding && '58px'};
    padding: 0;
    border-bottom: 1px solid #f0f0f0;
    padding: ${({ isMobileFullWidth }) => isMobileFullWidth && '0'};

    &::after {
      width: 1px;
      height: 58px;
      top: -58px;
      left: 50% !important;
      display: ${({ isMobileFullWidth }) => isMobileFullWidth && 'none'};
    }
  }
`

const ContentWrapper = styled.div`
  flex: 0 0 50%;
  position: relative;
  display: flex;
  align-items: center;
  margin: 100px 0;

  ${breakpoint.below('mobile')} {
    margin-bottom: 0;
  }
`

const Container = styled.div`
  position: relative;
  padding: 0 60px;

  ${breakpoint.below('mobile')} {
    padding: 0;
  }
`

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 96px;
  height: 96px;
  background: ${sc.RED_BASE_HEX};
  color: white;
  font-size: 62px;
  font-weight: bold;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${sc.RED_BASE_HEX};
  }
`

const Section = styled.div`
  display: flex;
  min-height: 448px;
  border-bottom: 1px solid #f0f0f0;
  margin: 0 -65px;
  width: calc(100% + 65px + 65px);
  position: relative;

  @media screen and (max-width: 767px) {
    padding-top: ${({ isMobileFullWidth }) =>
      isMobileFullWidth && '0 !important'};
  }

  /* FIX SIDE GAP */
  @media screen and (min-width: 768px) and (max-width: 1160px) {
    width: ${({ fixSideGap }) => fixSideGap && '100vw'};
  }

  ${NumberWrapper} {
    order: 1;
    border-right: 1px solid #f0f0f0;
  }

  ${ContentWrapper} {
    order: 2;
  }

  ${Container}::before {
    content: '';
    display: block;
    background: ${sc.BLUE_BASE_HEX};
    width: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  ${props =>
    props.numberOnRight &&
    css`
      ${NumberWrapper} {
        order: 2;
        border-left: 1px solid #f0f0f0;
        border-right: none;
        left: -1px;

        &::after {
          left: 0;
        }
      }

      ${ContentWrapper} {
        order: 1;
      }

      ${Number}::before {
        border-left: none;
        border-right: 8px solid ${sc.RED_BASE_HEX};
        left: auto;
        right: 100%;
      }

      ${Container} {
        left: auto;
        right: 0;

        &::before {
          left: auto;
          right: 0;
        }
      }
    `}

  ${breakpoint.below('mobile')} {
    flex-wrap: wrap;
    min-height: 0;
    padding: 58px 32px 68px;
    padding-top: 0;
    width: calc(100% + 32px + 32px);
    margin: 0 -32px;
    box-sizing: border-box;

    &::after {
      display: none;
    }

    ${Container} {
      padding: 0;

      &::before {
        display: none;
      }
    }

    ${NumberWrapper},
    ${ContentWrapper} {
      order: initial;
      flex: 1 0 100%;
    }

    ${Number}::before {
      transform: translateX(-50%);
      top: 100%;
      left: 50%;
      border: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid ${sc.RED_BASE_HEX};
    }
  }
`

const numberSvgMap = {
  0: require('./img/0.svg'),
  1: require('./img/1.svg'),
  2: require('./img/2.svg'),
  3: require('./img/3.svg'),
  4: require('./img/4.svg'),
  5: require('./img/5.svg'),
  6: require('./img/6.svg'),
  7: require('./img/7.svg'),
  8: require('./img/8.svg'),
  9: require('./img/9.svg'),
}

const StyledNumberChar = styled.img`
  display: inline-block;
  height: 62px;
  width: auto;
  margin-right: 5px;
`

const parseNumberIntoImages = number => {
  const strNumber = String(number)
  return strNumber
    .split('')
    .map(numberAtPos => <StyledNumberChar src={numberSvgMap[numberAtPos]} />)
}

const TimelineImage = styled.img`
  /* border-radius: 1%; */
  /* box-shadow: 0 0 5px rgba(0,0,0,0.3); */
  width: 70%;
  z-index: 10;

  @media screen and (max-width: 767px) {
    border-radius: ${({ isMobileFullWidth }) => isMobileFullWidth && '0'};
    width: ${({ isMobileFullWidth }) => isMobileFullWidth && '100%'};
  }
`

export class TimelineNumberedSection extends PureComponent {
  static propTypes = {
    number: PropTypes.number,
    date: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.any,
    numberOnRight: PropTypes.bool,
  }

  render() {
    const {
      number,
      date,
      title,
      content,
      numberOnRight,
      image,
      titleNoTopMargin,
      fixSideGap,
      imageBottomPadding,
      isMobileFullWidth,
    } = this.props

    return (
      <Section
        numberOnRight={numberOnRight}
        fixSideGap={fixSideGap}
        isMobileFullWidth={isMobileFullWidth}
      >
        <NumberWrapper
          isMobileFullWidth={isMobileFullWidth}
          imageBottomPadding={imageBottomPadding}
        >
          {number && <Number>{parseNumberIntoImages(number)}</Number>}
          {image && (
            <TimelineImage
              isMobileFullWidth={isMobileFullWidth}
              src={image}
              alt=""
            />
          )}
        </NumberWrapper>
        <ContentWrapper>
          <Container>
            <TimelineDate>{date}</TimelineDate>
            <TimelineHeadline as="h3" titleNoTopMargin={titleNoTopMargin}>
              {title}
            </TimelineHeadline>
            {/* <RichText>{content}</RichText> */}
            <RichText>
              {Array.isArray(content)
                ? content.map((paragraph, index) => (
                    <>
                      {paragraph}
                      {index + 1 !== content.length && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </>
                  ))
                : content}
            </RichText>
          </Container>
        </ContentWrapper>
      </Section>
    )
  }
}
