import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { config } from 'utils'
import Headline from 'components/ui/headline'
import Breakdown from 'components/ui/breakdown'
import Video from 'components/ui/video'
import RichText from 'components/ui/richText'
import Button from 'components/ui/button'
import LearnSliderMobile from 'components/ui/learnSliderMobile'
import Block from 'components/ui/block'
import FadeIn from 'components/ui/fadeIn'
import Sidebar from 'components/ui/sidebar'

import { FooterLayout, HeaderHo } from '@stokr/components-library'
import check from './img/checkmark.svg'
import checkLarge from './img/checkmarkLarge.svg'

export { check, checkLarge }

const pattern = require('./img/pattern.svg')

const Container = styled(FadeIn)`
  background: white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  top: 0;
  left: 0;
  overflow-y: scroll;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  @media screen and (max-width: ${config.tablet}) {
    padding-top: 96px;
  }
`

const ContainerInner = styled.div`
  background: white;
  position: relative;
  margin: 0 auto;
  max-width: ${sc.MAX_WIDTH}px;
  overflow: hidden;
  @media screen and (max-width: ${config.tablet}) {
    display: none;
  }
  @media screen and (min-width: ${config.highDesktop}) {
    border-left: 1px solid ${sc.GRAY_BASE_HEX};
    border-right: 1px solid ${sc.GRAY_BASE_HEX};
    box-sizing: border-box;
  }
`

export const IconPlaceholder = ({ disabled, large, small, active }) => {
  if (small && active) {
    return (
      <Placeholder small={small} active={active}>
        <img src={check} alt="check" />
      </Placeholder>
    )
  }
  if (large && active) {
    return (
      <Placeholder active={active} large={large}>
        <img src={checkLarge} alt="check large" />
      </Placeholder>
    )
  }
  return <Placeholder disabled={disabled} small={small} large={large} />
}

const Placeholder = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 999;
  border: 1px solid
    ${({ disabled }) => (disabled ? sc.GRAY_BASE_HEX : sc.GREEN)};
  background: white;
  z-index: 1;
  ${({ large }) =>
    large &&
    css`
      width: 40px;
      height: 40px;
    `}
`

/** Content styles */
const Content = styled(FadeIn)`
  width: calc(100% - 449px);
  position: relative;
  margin-left: 449px;
  border-left: 1px solid ${sc.GRAY_BASE_HEX};
  margin-top: 97px;
  padding-top: 92px;
  @media screen and (max-width: ${config.lgDesktop}) {
    width: calc(100% - 321px);
    margin-left: 321px;
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    width: calc(100% - 350px);
    margin-left: 350px;
  }
  @media screen and (min-width: ${config.highDesktop}) {
    width: calc(100% - 449px);
    margin-left: 449px;
  }
`

const BackgroundPattern = styled.div`
  position: absolute;
  height: ${92 * 3}px;
  width: 50%;
  right: 0;
  top: 0;
  background: url(${pattern}) no-repeat;
  background-size: auto;
  @media screen and (max-width: ${config.lgDesktop}) {
    display: none;
  }
  @media screen and (min-width: 1435px) {
    background-position: center center;
    width: 75%;
    right: -25%;
  }
`

const IntroBlock = styled.div`
  width: 100%;
  padding-left: 0px;
  box-sizing: border-box;
  position: relative;
  top: -51px;
  @media screen and (min-width: ${config.lgDesktop}) {
    padding-left: 92px;
  }
  @media screen and (min-width: ${config.highDesktop}) {
    padding-left: ${sc.COLUMN * 2}px;
  }
`

const IntroBlockInner = styled.div`
  box-sizing: border-box;
  border-left: 1px solid ${sc.GRAY_BASE_HEX};
  position: relative;
  padding-top: 60px;
  @media screen and (max-width: ${config.tablet}) {
    flex: 1;
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    padding-top: 0;
    padding-right: ${sc.COLUMN / 2}px;
    padding-left: ${sc.COLUMN / 2}px;
    padding-bottom: ${sc.COLUMN / 2}px;
    border-left: none;
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    padding-left: ${sc.COLUMN / 2}px;
    padding-right: 92px;
    padding-bottom: ${sc.COLUMN * 1.5}px;
  }
  @media screen and (min-width: ${config.highDesktop}) {
    padding-left: ${sc.COLUMN}px;
    padding-right: ${sc.COLUMN * 3}px;
  }

  ${({ alternate }) =>
    alternate &&
    css`
      border-left: 0;
      border-right: 1px solid ${sc.GRAY_BASE_HEX};
      padding-right: ${sc.COLUMN * 1}px;
      margin-right: ${sc.COLUMN * 1.5}px;
      margin-bottom: -51px;
      @media screen and (max-width: ${config.lgDesktop}) {
        padding-right: 0px;
        border-right: none;
        margin-right: ${sc.COLUMN / 2}px;
      }
      @media screen and (min-width: ${config.lgDesktop}) {
        padding-bottom: 0px;
        margin-right: 92px;
        padding-right: ${sc.COLUMN / 2}px;
      }
      @media screen and (min-width: ${config.highDesktop}) {
        margin-right: ${sc.COLUMN * 2}px;
        padding-right: ${sc.COLUMN}px;
      }
    `}
`

const HeadlineWrapper = styled.div`
  margin-bottom: ${sc.COLUMN / 2}px;

  @media screen and (min-width: ${config.highDesktop}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledHeadline = styled((props) => <Headline {...props} />)`
  position: relative;
  margin-bottom: ${sc.COLUMN / 2}px;

  &::before {
    content: '';
    position: absolute;
    left: -${sc.COLUMN * 2}px;
    height: 100%;
    width: 8px;
    background: ${sc.BLUE_BASE_HEX};
  }

  @media screen and (max-width: ${config.tablet}) {
    margin-bottom: ${sc.COLUMN / 4}px;
    &::before {
      display: none;
    }
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    &::before {
      left: ${-sc.COLUMN / 2}px;
    }
  }

  @media screen and (min-width: ${config.lgDesktop}) {
    &::before {
      left: ${-sc.COLUMN / 2}px;
    }
  }

  @media screen and (min-width: ${config.highDesktop}) {
    &::before {
      left: ${-sc.COLUMN}px;
    }
  }

  ${({ content }) =>
    content &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}

  ${({ alternate }) =>
    alternate &&
    css`
      &::before {
        left: unset;
        right: -${sc.COLUMN / 2}px;
      }
      @media screen and (min-width: ${config.lgDesktop}) {
        &::before {
          left: unset;
          right: -${sc.COLUMN / 2}px;
        }
      }
      @media screen and (min-width: ${config.highDesktop}) {
        &::before {
          right: ${-sc.COLUMN}px;
        }
      }
    `}
`

const ButtonContainer = styled.span`
  display: flex;
  align-items: center;

  ${Button} {
    margin-right: ${sc.COLUMN / 4}px;
  }
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      margin-top: 48px;
      padding-bottom: 48px;
    `}
`

const StyledSlideImage = styled.div`
  background-size: cover;
  background-position: center center;
  padding-top: 56.25% !important;
  position: relative;
  @media screen and (max-width: ${config.tablet}) {
    margin-bottom: 0px;
  }
`

/** Media wrappers */
const StyledSlide = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  ${({ padded }) =>
    padded &&
    css`
      margin-top: 42px;
      margin-bottom: 52px;
      @media screen and (max-width: ${config.lgDesktop}) {
        margin-bottom: ${sc.COLUMN * 2}px;
      }
      @media screen and (max-width: ${config.tablet}) {
        margin-bottom: ${sc.COLUMN / 2}px;
        min-width: 256px;
      }
    `}
  @media screen and (max-width: ${config.tablet}) {
  }
`

const videoStyle = css`
  margin-left: ${sc.COLUMN}px;
  margin-right: ${sc.COLUMN}px;
  margin-top: 92px;
  margin-bottom: 52px;

  @media screen and (max-width: ${config.lgDesktop}) {
    margin-top: ${sc.COLUMN}px;
    margin-bottom: ${sc.COLUMN * 2}px;
    margin-right: ${sc.COLUMN / 2}px;
    margin-left: ${sc.COLUMN / 2}px;
  }
  @media screen and (max-width: ${config.tablet}) {
    margin: ${sc.COLUMN / 2}px;
    margin-bottom: ${sc.COLUMN / 2}px;
    flex: 1;
    max-height: 50%;
  }
`

export class ContentWrapper extends PureComponent {
  state = { isClicked: false }

  handleClick = () =>
    this.setState(({ isClicked }) => ({ isClicked: !isClicked }))

  render() {
    const { isClicked } = this.state
    return (
      <Content>
        <BackgroundPattern />
        <Video
          isInvestingExpanded
          videoStyle={videoStyle}
          src={require('./img/video.mp4')}
          poster={
            <StyledSlide>
              <StyledSlideImage
                style={{
                  backgroundImage: `url(${require('./img/videoposter.jpg')})`,
                }}
              />
            </StyledSlide>
          }
        />
        <IntroBlock>
          <IntroBlockInner>
            <HeadlineWrapper>
              <StyledHeadline as="h2" content>
                MAKING INVISIBLE <br /> PEOPLE VISIBLE AGAIN
              </StyledHeadline>
              <ButtonContainer>
                <Button transparent>WATCHED</Button>
                <IconPlaceholder active large />
              </ButtonContainer>
            </HeadlineWrapper>
            <RichText>
              Short excerpt of the start of the article to give users a bit more
              context excerpt of the start of the article to give users a bit
              more context
            </RichText>
          </IntroBlockInner>
        </IntroBlock>
        <IntroBlock>
          <IntroBlockInner alternate>
            <StyledHeadline as="h2" content alternate>
              <div>
                MAKING INVISIBLE <br /> PEOPLE VISIBLE AGAIN
              </div>
            </StyledHeadline>
            <Breakdown>OUR VISION</Breakdown>
            <br />
            <RichText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              elementum quam sed massa fringilla malesuada. Pellentesque varius
              cursus est, id iaculis ligula auctor eget. Nunc gravida elit id
              laoreet posuere. Integer vitae velit quis risus commodo tristique
              et sit amet mi. Suspendisse eget dolor euismod, sagittis risus a,
              sagittis massa. Quisque tincidunt nulla vestibulum, posuere purus
              elementum, rutrum nulla. Cras ut pharetra quam. Integer semper
              egestas placerat. Integer semper erat nec blandit pharetra. Proin
              vehicula luctus augue, eget semper neque pharetra quis. Integer at
              mauris eu arcu tincidunt laoreet eget in arcu.
              <br />
              <br />
              <ul>
                <li>OPTION 1</li>
                <li>OPTION 2</li>
              </ul>
              <br />
              <br />
              Maecenas quis felis at nisl luctus bibendum. Maecenas iaculis urna
              eget venenatis accumsan. Vivamus at sollicitudin tortor, et
              imperdiet quam. In pellentesque gravida ullamcorper. Nam felis
              sem, dapibus in iaculis vitae, interdum facilisis tellus. Sed
              nulla libero, faucibus vel fermentum eu, interdum in nisl. Mauris
              nec imperdiet dolor, sit amet fringilla mauris. Nunc eu viverra
              nibh.
              <br />
              <br />
              Fusce vestibulum magna elit, sagittis fringilla felis malesuada
              vitae. Integer aliquet finibus mattis. Donec tellus sem, interdum
              at metus non, elementum eleifend neque. Vestibulum in porttitor
              metus. Nam id posuere mi. Fusce id lectus sem. Nullam tempus
              sodales magna non vestibulum.
              <br />
              <br />
              Phasellus gravida lorem rutrum, blandit sem id, dictum mi.
              Interdum et malesuada fames ac ante ipsum primis in faucibus. Cras
              in maximus nulla. Praesent at risus dui. Sed id orci id risus
              fringilla vehicula. Etiam vestibulum mauris vitae dignissim
              fringilla. Quisque vel massa imperdiet, hendrerit elit ut,
              elementum velit.
            </RichText>
            <StyledSlide padded>
              <StyledSlideImage
                style={{
                  backgroundImage: `url(${require('./img/videoposter.jpg')})`,
                }}
              />
            </StyledSlide>
            <RichText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
              elementum quam sed massa fringilla malesuada. Pellentesque varius
              cursus est, id iaculis ligula auctor eget. Nunc gravida elit id
              laoreet posuere. Integer vitae velit quis risus commodo tristique
              et sit amet mi. Suspendisse eget dolor euismod, sagittis risus a,
              sagittis massa. Quisque tincidunt nulla vestibulum, posuere purus
              elementum, rutrum nulla. Cras ut pharetra quam. Integer semper
              egestas placerat. Integer semper erat nec blandit pharetra. Proin
              vehicula luctus augue, eget semper neque pharetra quis. Integer at
              mauris eu arcu tincidunt laoreet eget in arcu.
            </RichText>
            <ButtonContainer center>
              <Button onClick={this.handleClick} transparent={isClicked}>
                {isClicked ? 'ALREADY READ' : 'MARK AS READ'}
              </Button>
              <IconPlaceholder active={isClicked} large />
            </ButtonContainer>
          </IntroBlockInner>
        </IntroBlock>
      </Content>
    )
  }
}

export class InvestingExpanded extends PureComponent {
  render() {
    const { isOpen, handleWatchMode, categoryList } = this.props

    if (!isOpen) return null

    return (
      <Container isOpen={isOpen}>
        <HeaderHo fixed closeModal={handleWatchMode} />
        <ContainerInner>
          <Sidebar
            expanded
            categoryList={categoryList}
            handleWatchMode={handleWatchMode}
          />
          <Block compact>
            <ContentWrapper />
          </Block>
        </ContainerInner>
        <LearnSliderMobile
          expanded
          handleWatchMode={handleWatchMode}
          categoryList={categoryList}
        />
        <FooterLayout />
      </Container>
    )
  }
}

export default InvestingExpanded
