import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'

const SVG = styled.svg`
  transform: scale(${({ active }) => (active ? 1.3 : 1)})
    translateY(${({ active }) => (active ? '10px' : 0)});

  transform-origin: bottom;
  
 
  

  &.rocket * {
    stroke-width: 3.5px;
    stroke-miterlimit: 10;
    fill: none;
    /* stroke: ${({ active }) => (active ? sc.BLUE_BASE_HEX : '#CCCCCC')}; */
    stroke: ${({ active }) => (active ? sc.WHITE_HEX : '#CCCCCC')};
  } 
  &.graph * {
    stroke-width: 4.5px;
    stroke-miterlimit: 10;
    fill: none;
    /* stroke: ${({ active }) => (active ? sc.BLUE_BASE_HEX : '#CCCCCC')}; */
    stroke: ${({ active }) => (active ? sc.WHITE_HEX : '#CCCCCC')};
  }
  &.coins * {
    stroke-width: 3.5px;
    stroke-miterlimit: 10;
    fill: none;
    /* stroke: ${({ active }) => (active ? sc.BLUE_BASE_HEX : '#CCCCCC')}; */
    stroke: ${({ active }) => (active ? sc.WHITE_HEX : '#CCCCCC')};
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      * {
        stroke-dashoffset: 0 !important;
        transition: stroke-dashoffset 2.5s cubic-bezier(0.77, 0, 0.175, 1);
      }
    `}
  ${({ isPlayed }) =>
    isPlayed &&
    css`
      transition: all 0.45s cubic-bezier(0.77, 0, 0.175, 1);
      & > * {
        transition: all 0.45s cubic-bezier(0.77, 0, 0.175, 1);
      }
    `}
`

export class SVGWrapper extends PureComponent {
  state = {
    isPlayed: false,
  }

  svg = React.createRef()

  componentDidMount() {
    // TODO: This is breaking in newer browsers - https://github.com/juliangarnier/anime/issues/155
    // this.svg.current.querySelectorAll('*').forEach(node => {
    //   const newnode = node
    //   const length = newnode.getTotalLength() * 1.15
    //   newnode.style.strokeDasharray = length
    //   newnode.style.strokeDashoffset = length
    // })
    setTimeout(() => this.setState({ isPlayed: true }), 2500)
  }

  render() {
    const { active, isVisible, children, className, viewBox } = this.props
    const { isPlayed } = this.state

    return (
      <SVG
        ref={this.svg}
        isPlayed={isPlayed}
        isVisible={isVisible}
        viewBox={viewBox || '0 0 111 125'}
        active={active}
        style={{ width: 109, height: 122 }}
        className={className}
      >
        {children}
      </SVG>
    )
  }
}

export const GraphSVG = ({ isVisible, active }) => (
  <SVGWrapper isVisible={isVisible} active={active} className="graph">
    <line x1="48.29" y1="109.24" x2="10.54" y2="87.32" />
    <line x1="56.39" y1="8.76" x2="94.88" y2="30.34" />
    <line x1="38.57" y1="68.14" x2="50.58" y2="47.88" />
    <line x1="54.94" y1="47.98" x2="66.81" y2="68.34" />
    <line x1="82.11" y1="77.62" x2="73.03" y2="73.8" />
    <line x1="10.54" y1="82.9" x2="19.93" y2="79.03" />
    <line x1="52.87" y1="10.95" x2="52.87" y2="23.16" />
    <line x1="94.49" y1="82.83" x2="86.05" y2="79.28" />
    <line x1="52.87" y1="39.81" x2="52.87" y2="26.75" />
    <line x1="23.35" y1="77.62" x2="32.62" y2="73.8" />
    <line x1="40.99" y1="72.11" x2="64.65" y2="72.11" />
    <line x1="10.54" y1="30.17" x2="48.72" y2="8.76" />
    <line x1="98.5" y1="36.89" x2="98.5" y2="80.13" />
    <line x1="6.61" y1="36.89" x2="6.61" y2="80.29" />
    <line x1="56.02" y1="109.2" x2="94.72" y2="86.72" />
    <circle cx="52.56" cy="6.61" r="4.36" />
    <circle cx="52.87" cy="44.17" r="4.36" />
    <circle cx="98.5" cy="32.37" r="4.36" />
    <circle cx="6.61" cy="32.37" r="4.36" />
    <circle cx="6.61" cy="84.65" r="4.36" />
    <circle cx="36.72" cy="72.11" r="4.36" />
    <circle cx="69.01" cy="72.11" r="4.36" />
    <circle cx="98.5" cy="84.52" r="4.36" />
    <circle cx="52.17" cy="111.23" r="4.36" />
  </SVGWrapper>
)

export const RocketSVG = ({ isVisible, active }) => (
  <SVGWrapper isVisible={isVisible} active={active} className="rocket">
    <path d="M51.6,22.4c0,2.2,1.8,3.9,3.9,3.9c2.2,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9c0,0,0,0,0,0" />
    <line x1="49.2" y1="100.7" x2="49.2" y2="107.1" />
    <line x1="55.5" y1="100.7" x2="55.5" y2="111.3" />
    <line x1="61.9" y1="100.7" x2="61.9" y2="107.1" />
    <line x1="23.3" y1="47.6" x2="23.3" y2="56.2" />
    <line x1="32" y1="35" x2="32" y2="52.3" />
    <line x1="86.9" y1="47.6" x2="86.9" y2="56.2" />
    <line x1="78.2" y1="35" x2="78.2" y2="52.3" />
    <polyline points="45.1,96.8 32.8,96.8 32.8,78.2 38.9,72.1 " />
    <polyline points="65.9,96.8 78.3,96.8 78.3,78.2 72.1,72.1 " />
    <path d="M12,100.7c-4.2-10.6,3.7-21.3,13.7-21.3" />
    <line x1="23.7" y1="68.5" x2="23.7" y2="79.2" />
    <path d="M2.5,111.3c0,0,0-10.7,10.1-10.7h4.7" />
    <path d="M98.5,100.8c4.2-10.6-3.7-21.3-13.6-21.3" />
    <line x1="86.9" y1="68.6" x2="86.9" y2="79.3" />
    <path d="M108.1,111.5c0,0,0-10.7-10.2-10.7h-4.7" />
    <path d="M65.9,99.3V20.1c0-6.4-3.4-12.3-9-15.4C56.1,4.3,55.6,4,55.6,4S55,4.3,54.2,4.7c-5.7,3.3-9.1,9.5-9.1,16l0,78.6" />
    <line x1="43.3" y1="100.8" x2="67.7" y2="100.8" />
  </SVGWrapper>
)

export const CoinsSVG = ({ isVisible, active }) => (
  <SVGWrapper
    isVisible={isVisible}
    active={active}
    className="coins"
    viewBox="0 0 64 127"
  >
    <ellipse
      cx="28.13"
      cy="15.41"
      rx="8.6"
      ry="17.28"
      transform="translate(-2.66 24.4) rotate(-45)"
    />
    <ellipse cx="25.4" cy="52.25" rx="17.86" ry="8.6" />
    <path
      d="M7.54,52.27V60c0,4.75,8,8.6,17.86,8.6S43.26,64.74,43.26,60V52.27"
      transform="translate(0 -0.01)"
    />
    <path
      d="M37.16,66.12a4.29,4.29,0,0,1,.36,1.73c0,4.75-8,8.6-17.86,8.6S1.75,72.63,1.75,67.88c0-2.56,2.34-4.87,6-6.44"
      transform="translate(0 -0.01)"
    />
    <path
      d="M1.75,67.88v7.73c0,4.75,8,8.6,17.86,8.6s17.86-3.85,17.86-8.6V67.88"
      transform="translate(0 -0.01)"
    />
    <path
      d="M32.3,88.12a34.79,34.79,0,0,1-7.75.85C15.48,89,8,85.71,6.84,81.49"
      transform="translate(0 -0.01)"
    />
    <path
      d="M37.47,74.41C40.53,76,42.41,78,42.41,80.34"
      transform="translate(0 -0.01)"
    />
    <path
      d="M32.56,95.74a41.4,41.4,0,0,1-8,.93c-9.86,0-17.86-3.85-17.86-8.6V81.9"
      transform="translate(0 -0.01)"
    />
    <path
      d="M6.69,87.34v7.78c0,4.74,8,8.59,17.86,8.59a32.66,32.66,0,0,0,9.77-1.4"
      transform="translate(0 -0.01)"
    />
    <path
      d="M6.69,94.43v7.69c0,4.74,8,8.59,17.86,8.59a29.48,29.48,0,0,0,12.92-2.59"
      transform="translate(0 -0.01)"
    />
    <path
      d="M6.69,101.05v7.72c0,4.75,8,8.6,17.86,8.6,7.14,0,13.3-2,16.16-4.93"
      transform="translate(0 -0.01)"
    />
    <path
      d="M15.77,3.35,10.49,8.64C7.24,11.88,10.08,20,16.82,26.73s14.85,9.58,18.1,6.33l5.24-5.28"
      transform="translate(0 -0.01)"
    />
    <path
      d="M58.05,95.22c3.73,7.09,4.12,14,.61,16.34-4,2.6-11.43-1.76-16.66-9.74s-6.25-16.57-2.28-19.17c3.64-2.39,10.22,1.08,15.32,7.83"
      transform="translate(0 -0.01)"
    />
    <path
      d="M40.16,82.35l-6.25,4.1c-3.84,2.51-2.72,11,2.51,19S49,117.86,52.84,115.34l6.25-4.09"
      transform="translate(0 -0.01)"
    />
  </SVGWrapper>
)
