import styled from 'styled-components'
import { css } from 'styled-components/macro'
import media from 'config/media'
import { Link } from 'react-router-dom'
import theme from 'config/theme'

export const CustomH1 = styled.h1`
  font-size: 34px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  ${props =>
    props.extramargin &&
    css`
      margin-top: 2rem;
      margin-bottom: 3rem;
    `}

  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
`

export const BigH3Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 1.28571429em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  position: relative;

  ${media.desktop`
    top: -1px;
  `};
`

export const Customh2 = styled.h3`
  font-size: 1.12rem;
  font-weight: 900;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;

  ${props =>
    props.announcementcustomh2 &&
    css`
      margin-bottom: 3rem;
    `}

  ${media.desktop`
    font-size: 2rem;
   `};
`

export const LandingPageTitle = styled.h2`
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  font-size: 34px !important;

  line-height: 2.31rem;
  letter-spacing: 0.06rem;
  color: ${props =>
    props.dark ? theme.colours.palette.textDark : theme.colours.palette.light};
  margin: ${props => (props.home ? '1rem 0 0' : '1rem 0 1.5rem')};
  order: 2;
  position: relative;
  top: -13px;

  br {
    // display: none;
  }

  ${props =>
    props.announcementheading &&
    css`
      font-size: 22px !important;
    `}

  ${media.tablet`
    font-size: 3rem;
    // line-height: 4rem;
    line-height: 1;
    top: -15px;
  `};

  ${media.desktop`
    font-size: 2.6rem;
    margin: 0 0 2rem 0;
    letter-spacing: 0.165rem;
    line-height: 1;
    order: 1;
    top: 0;
    
    br {
      display: inline-block;
    }
  `};

  ${media.large`
    font-size: 2.68rem;
  `};

  ${media.default`
    font-size: 3.18rem;
  `};

  ${media.widescreen`
    font-size: 4.18rem;
  `};
`

export const LandingPageTitleLink = styled(Link)`
  color: #000;
  display: flex;
  order: 2;
  transition: opacity 0.3s;

  &:after {
    display: none;
  }

  &:hover,
  &:active {
    color: #000;
  }

  ${media.desktop`
    order: 1;

    &:hover,
    &:active {
      color: #000;
      opacity: 0.65;
    }
  `};
`

export const AnnouncementTitleMain = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  font-size: 34px !important;

  line-height: 2.31rem;
  letter-spacing: 0.06rem;
  color: ${props =>
    props.dark ? theme.colours.palette.textDark : theme.colours.palette.light};
  margin: ${props => (props.home ? '1rem 0 0' : '1rem 0 1.5rem')};
  order: 2;
  position: relative;
  top: -13px;

  br {
    // display: none;
  }

  ${props =>
    props.announcementheading &&
    css`
      font-size: 22px !important;
    `}

  ${media.tablet`
    font-size: 3rem;
    // line-height: 4rem;
    line-height: 1;
    top: -15px;
  `};

  ${media.desktop`
    font-size: 2.6rem;
    margin: 0 0 2rem 0;
    letter-spacing: 0.165rem;
    line-height: 1;
    order: 1;
    top: 0;
    
    br {
      display: inline-block;
    }
  `};

  ${media.large`
    font-size: 2.68rem;
  `};

  ${media.default`
    font-size: 3.18rem;
  `};

  ${media.widescreen`
    font-size: 4.18rem;
  `};
`

export const CareersTitleMain = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  font-size: 34px !important;

  line-height: 2.31rem;
  letter-spacing: 0.06rem;
  color: ${props =>
    props.dark ? theme.colours.palette.textDark : theme.colours.palette.light};
  margin: ${props => (props.home ? '1rem 0 0' : '1rem 0 1.5rem')};
  order: 2;
  position: relative;
  top: -13px;

  br {
    // display: none;
  }

  ${props =>
    props.announcementheading &&
    css`
      font-size: 22px !important;
    `}

  ${media.tablet`
    font-size: 3rem;
    // line-height: 4rem;
    line-height: 1;
    top: -15px;
  `};

  ${media.desktop`
    font-size: 2.6rem;
    margin: 0 0 2rem 0;
    letter-spacing: 0.165rem;
    line-height: 1;
    order: 1;
    top: 0;
    
    br {
      display: inline-block;
    }
  `};

  ${media.large`
    font-size: 2.68rem;
  `};

  ${media.default`
    font-size: 3.18rem;
  `};

  ${media.widescreen`
    font-size: 4.18rem;
  `};
`
