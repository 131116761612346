import { createGlobalStyle } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import Breakdown from 'components/ui/breakdown'

export const TypographyCss = createGlobalStyle`
 body {
    font-family: 'Open Sans', 'Helvetica', 'Helvetica Neue', sans-serif;
    line-height: 1.6em;
    font-size: ${sc.BASE_FONT_SIZE}px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.6px;
    color: ${sc.BASE_COLOR_HEX};
  }

  strong {
    font-weight: 600;
  }

  a {
    &:hover {
      opacity: .6;
      ${Breakdown} {
        opacity: .6;
      }
    }
    &:active, &.active {
      opacity: 1;
      ${Breakdown} {
        opacity: 1;
      }
    }
  }

  h2 {
    font-size: 34px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: ${sc.BASE_MARGIN * 2}px;
    ${breakpoint.below('mobile')} {
      font-size: 22px;
    }
  }


  h3 {
    font-size: 22px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: ${sc.BASE_MARGIN * 2}px;
  }

  h4 {
    font-size: 22px;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: 0.8px;
    margin-bottom: ${sc.BASE_MARGIN * 2}px;
  }
`

export default TypographyCss
