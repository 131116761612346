import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AvatarAndName from 'components/ui/comments/AvatarAndName'
import BlockTowerTitle from 'components/ui/block/BlockTowerTitle'
import Breakdown from 'components/ui/breakdown'
import RichText from 'components/ui/richText'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

import Block from 'components/ui/block';
import BlockTower from 'components/ui/block/BlockTower';
import BlockTowerContent from 'components/ui/block/BlockTowerContent';
import Button from 'components/ui/button';

const moreImg = require('./img/more.svg')

const SmallText = styled.div`
  font-size: 14px;
`

export const DateAdded = styled(Breakdown)`
  font-size: 9px;
  color: ${sc.GRAY_SECONDARY_HEX};

  ${breakpoint.below('mobile')} {
    display: none;
  }
`

const ButtonMore = styled.button`
  display: flex;
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  margin-left: 12px;
  border-radius: 50%;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  background: transparent url(${moreImg}) center no-repeat;
  transition: all 0.25s;

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    outline: none;
  }
`

const StyledCommentHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > *:first-child {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0;
  }

  ${breakpoint.below('mobile')} {
    padding-left: ${({ secondLevel }) => (secondLevel ? 32 : 0)}px;
  }
`

const StyledBlockTower = styled(BlockTower)`
  padding-bottom: 80px;

  ${({ secondLevel }) =>
    secondLevel &&
    css`
      padding-top: 0 !important;
      padding-left: 62px;
      padding-bottom: 64px;
    `}

  ${breakpoint.below('tablet')} {
    margin-right: calc(100% / 16 * 1);
    width: calc(100% / 16 * 14);
  }
  ${breakpoint.below('mobile')} {
    margin-right: 0;
    padding-bottom: 64px;
    width: 100%;
  }
`

const StyledBlockTowerContent = styled(BlockTowerContent)`
  max-width: none;
`

export const CommentHeader = ({ secondLevel, picture, name, dateAdded }) => (
  <StyledBlockTowerContent>
    <StyledCommentHeader secondLevel={secondLevel}>
      <AvatarAndName name={name} picture={picture} small={secondLevel} />
      <DateAdded>{dateAdded}</DateAdded>
      <ButtonMore />
    </StyledCommentHeader>
  </StyledBlockTowerContent>
)

const StyledMessageWrapper = styled.div`
  margin-top: ${({ small }) => (small ? 24 : 40)}px;
  color: ${({ error }) => (error ? sc.RED_BASE_HEX : sc.BASE_COLOR_HEX)};
  font-weight: ${({ error }) => (error ? 400 : 300)};

  ${breakpoint.below('mobile')} {
    margin-top: 24px;

    ${SmallText} {
      padding-left: ${({ small }) => (small ? 32 : 0)}px;
    }
  }
`

const StyledCommentFooter = styled.div`
  margin-top: ${({ secondLevel }) => (secondLevel ? 24 : 40)}px;

  ${breakpoint.below('mobile')} {
    margin-top: 24px;
  }
`

const StyledBlock = styled(Block)`
  ${({ secondLevel }) =>
    secondLevel &&
    css`
      padding-left: 62px;
    `}
`

export const CommentFooter = ({ secondLevel, children }) => (
  <BlockTowerContent>
    <StyledCommentFooter secondLevel={secondLevel}>
      {children}
    </StyledCommentFooter>
  </BlockTowerContent>
)

const StyledCommentActions = styled.div`
  .showAll {
    margin-right: 16px;
  }

  ${DateAdded} {
    display: none;
  }

  ${breakpoint.below('mobile')} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ${DateAdded} {
      display: block;
      width: 50%;
    }

    .reply {
      width: 50%;
    }

    .showAll {
      width: 100%;
      margin: 16px 0 0;
      order: 3;
    }
  }
`

export const CommentActions = ({ dateAdded, replies = 0 }) => (
  <StyledCommentActions>
    <DateAdded>{dateAdded}</DateAdded>
    {replies > 0 && (
      <Button transparent className="showAll">
        Show all {replies} replies
      </Button>
    )}
    <Button className="reply">Reply</Button>
  </StyledCommentActions>
)

export const CommentMessage = ({ error, secondLevel, children }) => (
  <StyledMessageWrapper error={error} small={secondLevel}>
    {!secondLevel ? (
      <BlockTowerTitle>
        <BlockTowerContent>
          <RichText>{children}</RichText>
        </BlockTowerContent>
      </BlockTowerTitle>
    ) : (
      <BlockTowerContent>
        <SmallText>{children}</SmallText>
      </BlockTowerContent>
    )}
  </StyledMessageWrapper>
)

export const CommentSeparator = styled(BlockTower)`
  margin-right: 0;
  padding: 0 !important;
  border-bottom: 1px solid #e1e1e1;
  width: calc(100% / 16 * 14);
`

export class CommentStream extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    secondLevel: PropTypes.bool,
  }

  render() {
    const { secondLevel, children, ...blockProps } = this.props

    return (
      <StyledBlock secondLevel={secondLevel} last compact {...blockProps}>
        <StyledBlockTower secondLevel={secondLevel}>
          {React.Children.map(children, child =>
            React.cloneElement(child, { secondLevel }),
          )}
        </StyledBlockTower>
      </StyledBlock>
    )
  }
}

export default CommentStream
