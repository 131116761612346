import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Icon from "components/ui/icon/Icon";
import Button from "components/ui/button/Button";
import * as sc from "constants/style";
import { breakpoint } from "utils";
import $ from "jquery";

const AccordionItemOuter = styled.div`
  position: relative;
  padding-left: ${sc.BASE_MARGIN * 4}px;
  padding-right: ${sc.BASE_MARGIN * 4}px;
  padding-top: ${sc.BASE_MARGIN * 2}px;

  ${breakpoint.below("tablet")} {
    padding-left: ${sc.BASE_MARGIN * 3}px;
    padding-right: ${sc.BASE_MARGIN * 3}px;
  }
  ${breakpoint.below("mobile")} {
    padding-left: ${sc.BASE_MARGIN * 2}px;
    padding-right: ${sc.BASE_MARGIN * 2}px;
  }

  & + & {
    border-top: 1px solid ${sc.GRAY_BASE_HEX};
  }

  ${({ for101s }) =>
    for101s &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}

  ${({ noPaddingHorizontal }) =>
    noPaddingHorizontal &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`;

const IconWrapper = styled.div`
  transform: rotateZ(${(props) => (props.open ? "180deg" : "0deg")});
  transition: transform 0.3s;
`;

const openCss = css``;

const openHelper = ({ open }) => open && openCss;

const AccordionItemTitle = styled.div`
  display: flex;
  font-weight: bolder;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  padding-bottom: ${sc.BASE_MARGIN * 2}px;
  && {
    strong {
      font-weight: 600;
    }
  }
  ${openHelper};
  ${({ for101s }) =>
    for101s &&
    css`
      display: flex;
      align-items: center;
    `}
`;
const AccordionItemTitleText = styled.div`
  ${({ for101s }) =>
    for101s &&
    css`
      // margin: 0 auto;
    `}
`;

const AccordionItemTitleText101Mobile = styled.h2`
  font-size: 16px;
  font-weight: 400;
  text-transform: inherit;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
  margin: 0;
  margin-right: 5px;
`;

const AccordionItemContent = styled.div`
  transition: all 0.3s;
  padding-bottom: ${sc.BASE_MARGIN * 2}px;
`;
const AccordionContentOuter = styled.div`
  overflow-x: hidden;
`;

export class AccordionItem extends PureComponent {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  };

  refContent = null;

  state = {
    open: this.props.open,
  };

  handleContentRef = (node) => {
    const { open } = this.props;
    this.refContent = node;

    if (!open) {
      $(node).hide();
    }
  };

  handleOpen = (e) => {
    const { shouldStopPropagation } = this.props;
    shouldStopPropagation && e.stopPropagation();

    const { open } = this.state;

    if (!open) {
      $(this.refContent).slideDown();
    } else {
      $(this.refContent).slideUp();
    }

    this.setState({ open: !open });
  };

  render() {
    const { title, children, for101s, noPaddingHorizontal, for101sMobile } =
      this.props;
    const { open } = this.state;

    return (
      <AccordionItemOuter
        noPaddingHorizontal={noPaddingHorizontal}
        onClick={this.handleOpen}
        for101s={for101s}
      >
        <AccordionItemTitle open={open} for101s={for101s}>
          {for101sMobile ? (
            <AccordionItemTitleText101Mobile for101s={for101s}>
              {for101s ? (
                <Button>{open === true ? "Read Less" : "Read More"}</Button>
              ) : (
                title
              )}
            </AccordionItemTitleText101Mobile>
          ) : (
            <AccordionItemTitleText for101s={for101s}>
              {for101s ? (
                <Button>{open === true ? "Read Less" : "Read More"}</Button>
              ) : (
                title
              )}
            </AccordionItemTitleText>
          )}

          {/* <AccordionItemTitleText for101s={for101s}>
            {for101s ? (
              <Button>{open === true ? 'Read Less' : 'Read More'}</Button>
            ) : (
              title
            )}
          </AccordionItemTitleText> */}
          {!for101s && (
            <IconWrapper open={open}>
              <Icon name="ArrowSimple" direction="down" open />
            </IconWrapper>
          )}
        </AccordionItemTitle>
        <AccordionContentOuter open={open} ref={this.handleContentRef}>
          <AccordionItemContent>{children}</AccordionItemContent>
        </AccordionContentOuter>
      </AccordionItemOuter>
    );
  }
}

export default AccordionItem;