import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Slick from 'react-slick'

import SlickOptions from 'components/ui/slider/SlickOptions';
import * as sc from 'constants/style'

import StyledDotsContainer from 'components/ui/slider/StyledDotsContainer';
import StyledDots from 'components/ui/slider/StyledDots';

const StyledSlick = styled.div`
  .slick-slide {
    background: white;
  }

  .slick-list {
    padding-bottom: ${sc.COLUMN * 1.5}px;
  }

  .slick-dots {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 40px;

    li div:before {
      background: ${sc.GRAY_BASE_HEX} !important;
    }

    .slick-active div:before {
      background: ${sc.RED_BASE_HEX} !important;
    }
  }

  ${({ noPaddingBottom }) =>
    noPaddingBottom &&
    css`
      .slick-list {
        padding-bottom: 0;
      }
    `}
`

export class LearnSlider extends PureComponent {
  settings = {
    dots: true,
    infinite: this.props.infinite,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: this.props.autoplay,
    autoplaySpeed: SlickOptions.interval,
    speed: SlickOptions.transitionLength,
    cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    arrows: false,
    className: this.props.className,
    appendDots: dots => <StyledDotsContainer>{dots}</StyledDotsContainer>,
    customPaging: () => <StyledDots />,
  }

  render() {
    const { children, noPaddingBottom } = this.props
    return (
      <StyledSlick noPaddingBottom={noPaddingBottom}>
        <Slick {...this.settings}>{children}</Slick>
      </StyledSlick>
    )
  }
}

export default LearnSlider
