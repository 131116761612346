import React from 'react'
import styled from 'styled-components'
import { breakpoint } from 'utils'

import Block from 'components/ui/block';
import BlockTower from 'components/ui/block/BlockTower';
import BlockTowerContent from 'components/ui/block/BlockTowerContent';
import Button from 'components/ui/button';

const StyledButton = styled(Button)`
  margin: 40px auto;
  width: 100%;

  ${breakpoint.below('mobile')} {
    margin: 32px auto;
  }
`

const StyledBlockTower = styled(BlockTower)`
  padding: 0 !important;
`

export const CommentLoadMore = () => (
  <Block last compact>
    <StyledBlockTower compact>
      <BlockTowerContent>
        <StyledButton transparent>Load more comments</StyledButton>
      </BlockTowerContent>
    </StyledBlockTower>
  </Block>
)

export default CommentLoadMore
