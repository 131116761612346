import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import Button from 'components/ui/button'
import * as sc from 'constants/style'

/**
 * CONFIG
 *  Adjust component visual from here
 */
const config = {
  colorDark: 'rgb(41, 112, 225)',
  colorLight: '#9bfcfd',
  colorFooter: sc.BLUE_BASE_HEX,
  strokeWidth: 2,
  padding: 5,
  baseHeight: 50,
}

const BeautyButtonWrapper = styled.div`
  position: relative;
  display: inline-block;

  ${({ nowrap }) =>
    !nowrap &&
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    `}
`

const BeautyButton = styled(Button)`
  position: relative;
`

const ButtonGlare = styled.svg`
  position: absolute;
  left: -${config.padding / 2}px;
  top: -${config.padding / 2}px;
`

const GlareAnimation = keyframes`
  0% { width: 0; }
  50% { width: 200%; }
  75% { opacity: 1; }
  100% { width: 400%; opacity: 0; }
`

const GlareShape = styled.rect`
  animation: ${GlareAnimation} 4s linear infinite;
`

export class GlareButton extends PureComponent {
  static propTypes = {
    nowrap: PropTypes.bool,
  }

  state = {
    isButtonVisible: false,
    width: 295,
  }

  linearGradientId = Math.random()
    .toString(32)
    .substr(2)

  staticId = Math.random()
    .toString(32)
    .substr(2)

  maskId = Math.random()
    .toString(32)
    .substr(2)

  fadeMaskId = Math.random()
    .toString(32)
    .substr(2)

  fadeGradientId = Math.random()
    .toString(32)
    .substr(2)

  buttonId = Math.random()
    .toString(32)
    .substr(2)

  updateWidthInterval = null

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth)
    setTimeout(this.updateWidth)
    // This is unfortunate but the resize event is not properly triggered - manual checking
    this.updateWidthInterval = setInterval(this.updateWidth, 3000)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
    clearInterval(this.updateWidthInterval)
  }

  updateWidth = () => {
    const button = document.getElementById(`button-${this.buttonId}`)
    const boundingRect = button && button.getBoundingClientRect()
    const width = boundingRect
      ? boundingRect.width + (config.padding * 2 + config.strokeWidth * 2)
      : 295

    this.setState({ width })
  }

  handleButtonVisibility = receivedState => {
    const { isButtonVisible } = this.state

    if (!isButtonVisible) {
      setTimeout(() => this.setState({ isButtonVisible: receivedState }))
    }
  }

  render() {
    const { children, nowrap } = this.props
    const { isButtonVisible, width } = this.state

    const svgHeight =
      config.baseHeight + config.padding * 2 - config.strokeWidth * 2

    return (
      <VisibilitySensor onChange={this.handleButtonVisibility}>
        <BeautyButtonWrapper nowrap={nowrap}>
          <ButtonGlare
            xmlns="http://www.w3.org/2000/svg"
            width={width + config.strokeWidth * 2}
            height={svgHeight + config.strokeWidth * 2}
          >
            <defs>
              <linearGradient
                id={`gradient-${this.linearGradientId}`}
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
              >
                <stop offset="0%" stopColor={config.colorDark} />
                <stop offset="40%" stopColor={config.colorDark} />
                <stop offset="70%" stopColor={config.colorLight} />
                <stop offset="100%" stopColor={config.colorDark} />
              </linearGradient>
              <linearGradient
                id={`gradient-${this.fadeGradientId}`}
                x1="0%"
                y1="25%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0" stopColor="white" stopOpacity="1" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
              </linearGradient>
              <mask id={`mask-${this.staticMaskId}`}>
                <rect
                  x="1"
                  y="1"
                  width={width}
                  height={svgHeight - config.strokeWidth * 2}
                  rx={svgHeight / 2}
                  ry={svgHeight / 2}
                  stroke="#FFFFFF"
                  strokeWidth={config.strokeWidth}
                />
              </mask>
              <mask id={`mask-${this.maskId}`}>
                <rect
                  x="1"
                  y="1"
                  width={width}
                  height={svgHeight - config.strokeWidth * 2}
                  rx={svgHeight / 2}
                  ry={svgHeight / 2}
                  stroke="#FFFFFF"
                  strokeWidth={config.strokeWidth + 1}
                />
              </mask>
              <mask id={`mask-${this.fadeMaskId}`}>
                <rect
                  x="1"
                  y="1"
                  width={width}
                  height={svgHeight - config.strokeWidth * 2}
                  rx={svgHeight / 2}
                  ry={svgHeight / 2}
                  fill={`url(#gradient-${this.fadeGradientId})`}
                />
              </mask>
            </defs>
            <rect
              x={config.strokeWidth * -1}
              y={config.strokeWidth * -1}
              width={width + config.strokeWidth * 2}
              height={svgHeight + config.strokeWidth * 2}
              fill={config.colorDark}
              mask={`url(#mask-${this.staticMaskId})`}
            />
            <g mask={`url(#mask-${this.fadeMaskId})`}>
              <GlareShape
                x={width * -0.5}
                y="1"
                width={width * 2.5}
                height="50"
                fill={`url(#gradient-${this.linearGradientId})`}
                mask={`url(#mask-${this.maskId})`}
              />
            </g>
          </ButtonGlare>
          <BeautyButton
            beauty={isButtonVisible}
            disabledBeauty={!isButtonVisible}
            id={`button-${this.buttonId}`}
          >
            {children}
          </BeautyButton>
        </BeautyButtonWrapper>
      </VisibilitySensor>
    )
  }
}

export default GlareButton
