import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import BaseLayout from 'components/layouts/baseLayout/BaseLayout'
import Button from 'components/ui/button'
import FadeIn from 'components/ui/fadeIn'
import * as sc from 'constants/style'
import { config } from 'utils/breakpoint'

import { ContentSection, UppercaseText } from 'components/landing-page/PageText'
import {
  CustomH1,
  Customh2,
  LandingPageTitleLink,
  CareersTitleMain,
  LandingPageTitle,
} from 'components/landing-page/PageTitle'

const { tablet, desktop, highDesktop } = config

const Container = styled.div`
  border-bottom: 1px solid #ededed;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1886px;

  @media screen and (min-width: ${desktop}) {
    flex-direction: row;
  }
`

const ContentInner = styled.div`
  ${CustomH1} {
    font-size: 22px !important;
  }
  @media screen and (min-width: ${desktop}) {
    width: 70%;
    padding: 0;
  }
`

const LeftPanel = styled.div`
  align-self: flex-end;
  border-right: 1px solid rgb(245, 245, 245);

  margin-bottom: -70px;
  padding: 0.7rem 2rem 3rem;
  position: relative;
  width: 100%;
  /* z-index: -10; */

  @media screen and (min-width: ${tablet}) {
    padding: 0.7rem 3rem;
    height: calc(80vh - 98px);
    display: flex;
    justify-content: center;
    margin-bottom: -88px;
  }

  @media screen and (min-width: ${desktop}) {
    bottom: 0;
    height: calc(100vh);
    max-width: 943px;
    max-width: 50%;
    min-width: 50%;
    padding: 0;
    position: sticky;
  }

  // for iphone 4
  @media screen and (min-width: 319px) and (max-width: 350px) and (max-height: 500px) {
    height: calc(120vh - 98px) !important;
  }

  @media screen and (max-width: 479px) and (max-height: 767px) {
    // height: calc(100vh - 98px);
  }

  @media screen and (min-width: 480px) and (max-width: 991px) and (max-height: 500px) {
    height: calc(160vh - 98px);
  }

  @media screen and (max-width: 991px) {
    display: block;
  }
`

const RightPanel = styled.div`
  display: none;
  position: relative;
  width: 100%;
  overflow: scroll;

  ${Button} {
    font-size: 0.75rem;
    margin: 25px 0 80px;
    padding: 0 32px;
    position: relative;
    top: 2px;
    width: 100%;

    &:hover {
      background: ${sc.BLUE_BASE_RELEASED_HEX};
    }

    @media screen and (min-width: ${desktop}) {
      font-size: 0.55rem;
      margin-bottom: 0;
      width: auto;
    }

    @media screen and (min-width: 1200px) {
      font-size: 0.65rem;
    }

    @media screen and (min-width: 1440px) {
      font-size: 0.75rem;
      padding: 0px 2.5rem;
    }

    @media screen and (min-width: ${highDesktop}) {
      font-size: 0.75rem;
    }
  }

  a:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${desktop}) {
    display: block;
    min-width: 50%;
    max-width: 50%;
  }
`

export const carrersJob = [
  // {
  //   title: 'Compliance Officer – RC (M/F/D)',
  //   url: '/careers/compliance-officer',
  // },
]

export class Careers extends PureComponent {
  render() {
    return (
      <BaseLayout notFullHeight>
        <Helmet>
          <title>Careers &#8226; STOKR</title>
        </Helmet>
        <FadeIn>
          <Container>
            <LeftPanel>
              <ContentInner>
                <Customh2> </Customh2>

                {carrersJob.length > 0 ? (
                  <>
                    <CustomH1>WE’RE HIRING!</CustomH1>
                    <ContentSection>
                      {/* <UppercaseText regular nomargin>
                        Location: Luxembourg
                      </UppercaseText> */}
                      {carrersJob.map((job) => (
                        <LandingPageTitleLink to={job.url}>
                          <CareersTitleMain dark>{job.title}</CareersTitleMain>
                        </LandingPageTitleLink>
                      ))}
                    </ContentSection>
                  </>
                ) : (
                  <ContentSection>
                    <UppercaseText regular nomargin>
                      {' '}
                    </UppercaseText>
                    <UppercaseText regular nomargin>
                      FULL HOUSE
                    </UppercaseText>
                    <LandingPageTitle dark>
                      WE CURRENTLY DON'T HAVE ANY OPENINGS
                    </LandingPageTitle>
                  </ContentSection>
                )}
              </ContentInner>
            </LeftPanel>
            <RightPanel />
          </Container>
        </FadeIn>
      </BaseLayout>
    )
  }
}

export default Careers
