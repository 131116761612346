/*  */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Loading from 'components/ui/loading/Loading'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const buttonTextLoadingCss = css`
  visibility: hidden;
`

const buttonTextHelper = ({ loading }) => loading && buttonTextLoadingCss

const ButtonText = styled.span`
  line-height: ${({ isShare }) => (isShare ? '0px' : '1em')};
  margin: 0 auto;
  ${buttonTextHelper};

  svg,
  path {
    fill: ${({ isShare }) => isShare && '#cacaca'};
    transition: all 0.01s;

    &:hover {
      fill: ${({ isShare }) => isShare && sc.RED_BASE_HEX};
    }
  }
`

const StyledLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
// Unused props are being destructured so they're not passed as ...rest
// causing the app to complain
const ButtonComponent = ({
  as,
  transparent,
  isLoginMobile,
  isHero,
  isInvisible,
  inactive,
  submitSuccess,
  ...rest
}) => React.cloneElement(React.createElement(as), rest)

class ButtonClass extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    as: PropTypes.node,
    transparent: PropTypes.bool,
    noborder: PropTypes.bool,
    icononly: PropTypes.bool,
    inverted: PropTypes.bool,
    isShare: PropTypes.bool,
    inactive: PropTypes.bool,
  }

  static defaultProps = {
    as: 'button',
    transparent: false,
  }

  handleClick = e => {
    const { disabled, onClick } = this.props
    return !disabled && onClick && onClick(e)
  }

  render() {
    const { children, text, onClick, size, isShare, ...rest } = this.props
    const { loading } = this.props

    return (
      <ButtonComponent {...rest} onClick={this.handleClick}>
        <ButtonText isShare={isShare} loading={loading}>
          {children || text}
        </ButtonText>
        {loading && (
          <StyledLoading>
            <Loading size={size === 'xl' && 40} />
          </StyledLoading>
        )}
      </ButtonComponent>
    )
  }
}

const baseButtonCss = css`
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 25px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  &:hover,
  &:active {
    opacity: 1;
  }
  -webkit-tap-highlight-color: transparent;
`

const blockButtonCss = css``

const importantButtonCss = css``

const beautyButtonCss = css`
  &,
  &:hover {
    padding-left: ${sc.BASE_MARGIN * 4}px;
    padding-right: ${sc.BASE_MARGIN * 4}px;
    box-shadow: 0 6px 15px 2px #00000027,
      0px -5px 30px 2px rgba(255, 255, 255, 0.2);
    background-color: ${sc.WHITE_HEX};
    color: ${sc.BASE_COLOR_HEX};
    margin: 5px;
    z-index: 100;
    transition: all 0.4s;

    ${breakpoint.below('mobile')} {
      padding-left: ${sc.BASE_MARGIN * 2}px;
      padding-right: ${sc.BASE_MARGIN * 2}px;
    }
  }
`

const disabledBeautyButtonCss = css`
  &,
  &:hover {
    padding-left: ${sc.BASE_MARGIN * 4}px;
    padding-right: ${sc.BASE_MARGIN * 4}px;
    background-color: ${sc.BLUE_BASE_HEX};
    color: #4a90e2;
    margin: 5px;
    z-index: 100;
  }

  &:before {
    content: '';
    position: absolute;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    left: -5px;
    top: -5px;
    z-index: -2;
    border-radius: 25px;
    border: 1px solid #4a90e2;
  }

  ${breakpoint.below('mobile')} {
    padding-left: ${sc.BASE_MARGIN * 2}px;
    padding-right: ${sc.BASE_MARGIN * 2}px;
  }
`

const disabledButtonCss = css`
  &&&& {
    background: transparent;
    color: #e1e1e1;
    border: 1px solid #e1e1e1;
    cursor: not-allowed;
  }
`

const transparentButtonCss = css`
  &&&& {
    background: transparent;
    border: 1px solid ${sc.BLUE_BASE_HEX};
    color: ${sc.BLUE_BASE_HEX};

    &:hover {
      background: ${sc.BLUE_BASE_HEX};
      color: #fff;
    }
  }
`

const textOnlyButtonCss = css`
  &&&& {
    background: transparent;
    border: none;
    color: ${sc.BASE_COLOR_HEX};
  }
`

const invertedButtonCss = css``

const xlSize = css`
  height: 40px;
  padding-left: ${sc.BASE_MARGIN * 2}px;
  padding-right: ${sc.BASE_MARGIN * 2}px;
  width: 100%;
  font-size: 11px;
`

const mdSize = css`
  height: 40px;
  padding-left: ${sc.BASE_MARGIN * 2}px;
  padding-right: ${sc.BASE_MARGIN * 2}px;
  width: 192px;
  font-size: 11px;
`

const smSize = css``

const noborderCss = css`
  &&&& {
    border: 0;
  }
`

const inactiveCss = css`
  opacity: 0.3;
  cursor: initial;
  pointer-events: none;

  &:hover {
    opacity: 0.3;
  }
`

const isHeroCss = css`
  min-width: 273px;
`

const isLoginMobileCss = css`
  margin-bottom: 20px;
  width: 163.94px;
`

const isInvisibleCss = css`
  opacity: 0;
  cursor: unset;

  &:hover {
    opacity: 0;
  }
`

const nobordersButtonHelper = ({ noborder }) => noborder && noborderCss
const buttonSize = ({ size, icononly }) => {
  switch (size) {
    case 'xl':
      return xlSize
    case 'sm':
      return smSize
    case 'md':
      return mdSize
    default:
      return css`
        height: 40px;
        padding-left: ${sc.BASE_MARGIN * 2}px;
        padding-right: ${sc.BASE_MARGIN * 2}px;
        ${icononly &&
          css`
            margin: 0 auto;
            width: 40px;
            line-height: 0;
            text-align: center;
            padding: 0;
          `};
      `
  }
}

const activeTransparentCss = css`
  background: ${sc.RED_BASE_HEX};
  /* background: ${sc.BLUE_BASE_RELEASED_HEX}; */
  color: white;
`

const activeHelper = ({ active }) => active && activeTransparentCss

const buttonColor = ({ color, inverted }) => {
  switch (color) {
    case 'transparent':
      return css`
        background: transparent;
        border: 1px solid ${sc.GRAY_BASE_HEX};
        ${activeHelper};
        &:hover {
          ${activeTransparentCss};
        }
        ${inverted &&
          css`
            border: 1px solid ${sc.WHITE_HEX};
          `};
      `
    case 'white':
      return css`
        background: white;
        color: ${sc.BLUE_BASE_HEX};
        &:hover {
          color: ${sc.BLUE_BASE_HEX};
        }
      `
    case 'blue':
    default:
      return css`
        background: ${sc.BLUE_BASE_HEX};
        color: white;
        &:hover {
          /* background: ${sc.RED_BASE_HEX}; */
          background: ${sc.BLUE_BASE_RELEASED_HEX};
        }
      `
  }
}

const buttonBlock = ({ block }) => block && blockButtonCss
const importantButton = ({ important }) => important && importantButtonCss
const beautyButton = ({ beauty }) => beauty && beautyButtonCss
const disabledBeautyButton = ({ disabledBeauty }) =>
  disabledBeauty && disabledBeautyButtonCss
const disabledButton = ({ disabled }) => disabled && disabledButtonCss
const transparentButton = ({ transparent }) =>
  transparent && transparentButtonCss
const textOnlyButton = ({ textOnly }) => textOnly && textOnlyButtonCss
const invertedButton = ({ inverted }) => inverted && invertedButtonCss
const inativeButton = ({ inactive }) => inactive && inactiveCss
const isHeroButton = ({ isHero }) => isHero && isHeroCss
const isLoginMobileButton = ({ isLoginMobile }) =>
  isLoginMobile && isLoginMobileCss
const isInvisibleButton = ({ isInvisible }) => isInvisible && isInvisibleCss

export const Button = styled(ButtonClass)`
  ${baseButtonCss}
  ${buttonColor}
  ${buttonSize}
  ${beautyButton}
  ${disabledBeautyButton}
  ${buttonBlock}
  ${importantButton}
  ${disabledButton}
  ${transparentButton}
  ${textOnlyButton}
  ${invertedButton}
  ${nobordersButtonHelper}
  ${inativeButton}
  ${isHeroButton}
  ${isLoginMobileButton}
  ${isInvisibleButton}

  ${({ fillRed }) =>
    fillRed &&
    css`
      svg {
        fill: ${sc.RED_BASE_HEX};
      }

      &:hover {
        svg {
          fill: ${sc.WHITE_HEX};
        }
      }
    `}
`

Button.propTypes = {}

export default Button
