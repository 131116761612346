import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import { breakpoint, config } from 'utils'
import * as sc from 'constants/style'
import Slider from 'components/ui/slider'
import BreakdownPanel from 'components/ui/breakdownPanel'

import fetchDataPublic from 'api/fetch-data-public'
import { ProjectStatus, ProjectTypes } from 'constants/enums'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  /* height: calc(100vh - 97px); */
  height: ${({ height }) => `${height - 97}px`};
  min-height: 535px;
  padding-bottom: 47px;
  position: relative;
  ${breakpoint.below('tablet')} {
    display: block;
  }

  @media screen and (max-width: ${config.desktop}) {
    padding-bottom: 0;
    height: unset;
  }

  &::before {
    background: ${sc.RED_BASE_HEX};
    bottom: 0px;
    content: '';
    height: 48px;
    right: 0;
    width: 50%;
    position: absolute;
    z-index: 1;

    @media screen and (max-width: ${config.desktop}) {
      display: none;
    }
  }

  &::after {
    background: ${sc.RED_BASE_RELEASE_HEX};
    bottom: 0px;
    content: '';
    height: 48px;
    right: 0;
    width: 38.2%;
    position: absolute;
    z-index: 2;

    @media screen and (max-width: ${config.desktop}) {
      display: none;
    }
  }
`

const SliderWrapper = styled.div`
  opacity: 0;
  flex: 0 0 50%;
  transition: 0.32s ease-in-out;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `}
`

const StyledSlideImage = styled.div`
  background-size: cover;
  background-position: center;
  cursor: pointer;
`

const StyledSlide = styled.div`
  overflow: hidden;
`

const Content = styled.div`
  align-items: center;
  flex: 0 0 50%;
  height: ${({ height }) => `${height - 97 - 48}px`};
  min-height: calc(535px - 48px);
  padding: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  ${breakpoint.below('lgDesktop')} {
    height: ${({ height }) => `${height - 97 - 48}px`};
    padding-bottom: 0;
  }

  ${breakpoint.below('tablet')} {
    height: auto;
    transition: 0.1s ease-in-out;
    padding: 40px;
    padding-left: 32px;
    padding-right: 32px;
    min-height: 362px;
  }
`
const staticHomeHero = [
  // {
  //   component: (props) => (
  //     <StyledSlideImage
  //       style={{
  //         backgroundImage: `url('https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1668096697/Static/Investor_Thumbnale_2_elentb.png')`,
  //       }}
  //       {...props}
  //     />
  //   ),
  //   slug: 'MEET STOKR',
  //   slideTitle: ['Invest in tokenized securities'],
  //   slideTitleThin: '',
  //   slideContent:
  //     'Access the diverse world of alternative assets by investing in bitcoin mining, real-estate, music royalties and more.',
  //   slideBtnText: 'INVEST',
  //   slideBtnUrl: '/featured-assets',
  // },
  // {
  //   component: (props) => (
  //     <StyledSlideImage
  //       style={{
  //         backgroundImage: `url('https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1668097003/Static/Issuer_Thumbnale_1_yg0w4o.png')`,
  //       }}
  //       {...props}
  //     />
  //   ),
  //   slug: 'MEET STOKR',
  //   slideTitle: 'TOKENIZE ALTERNATIVE ASSETS',
  //   slideContent:
  //     'Tokenize any type of assets and raise capital with our plug-and-play investment structures.',
  //   slideBtnText: 'PRODUCTS',
  //   slideBtnUrl: '/products',
  // },
  {
    component: (props) => (
      <video
        //controls
        src={`https://res.cloudinary.com/stokr/video/upload/f_auto,q_auto:best/v1719923711/STO%20details/Blockstream-mining/STOKR_1212BTC-HQ_qvaysf.mp4`}
        height={'100%'}
        width={'100%'}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          position: 'absolute',
          top: 0,
        }}
        autoPlay
        loop
        muted
        playsInline
      />

      // <StyledSlideImage
      //   style={{
      //     backgroundImage: `url(${gif})`,
      //   }}
      //   {...props}
      // />
    ),
    slug: 'BMN1 payout',
    slideTitle: '1212+ BTC payout',
    slideContent:
      'BMN1 has reached its maturity date, with a payout of over 1212 Bitcoin.',
    slideBtnText: 'LEARN MORE',
    slideBtnUrl: '/blockstream-mining',
  },
]

export class HomeHero extends PureComponent {
  state = {
    isVisible: false,
    currentSlide: 0,
    height: 0,
    projects: staticHomeHero,
  }

  content = React.createRef()

  handleVisibility = () => this.setState({ isVisible: true })

  handleSlideChange = (prevIndex, nextIndex) => {
    this.setState({
      currentSlide: nextIndex,
    })
  }

  setHeight = (height) => this.setState({ height })

  async componentDidMount() {
    const windowHeight = window.innerHeight

    // const fetchedProjects = await fetchDataPublic('project/public/get', {
    //   noMetaData: true,
    // })

    //const generatedProjects = this.generateProjectItem(fetchedProjects.projects)

    this.setState({
      height: windowHeight,
      //projects: generatedProjects,
      currentSlide: 0,
    })
  }

  generateProjectItem = (projects) => {
    var newProject = []

    //display the newer projects first
    var sortedProjects = projects.sort((a, b) => {
      return Number(a.trending_order) - Number(b.trending_order)
    })
    sortedProjects.forEach((project) => {
      if (
        project.isOpen &&
        !project.hasClosed &&
        project.status === ProjectStatus.ACTIVE
      ) {
        //the project is live

        let item = {
          component: (props) => (
            <StyledSlideImage
              style={{
                backgroundImage: `url('${project.coverimage}')`,
              }}
              {...props}
            />
          ),
          slug: 'STO LAUNCH',
          slideContent: project.excerpt,
          slideBtnText: 'INVEST',
          slideBtnUrl: `https://stokr.io/${project.name}`,
          slideTitle: `${project.name.replace(/-/g, ' ')} IS RAISING ON STOKR`,
        }
        if (project.type === ProjectTypes.COMPANY) {
          //for GCH we have a migration sale from their previous offering, which we need to count in
          if (
            project.sales?.length > 2 &&
            !(
              project.name === 'global-cannabis-holdings' &&
              project.sales?.length <= 3
            )
          ) {
            item.slideTitle = `${project.name.replace(
              /-/g,
              ' ',
            )} NEW ROUND IS OPEN ON STOKR`
          } else {
            item.slideTitle = `${project.name.replace(
              /-/g,
              ' ',
            )} IS RAISING ON STOKR`
          }
        } else if (project.type === ProjectTypes.TRANCHES) {
          if (project.sales?.length > 1) {
            item.slideTitle = `${project.name.replace(
              /-/g,
              ' ',
            )} NEW TRANCHE IS OPEN ON STOKR`
          } else {
            item.slideTitle = `${project.name.replace(
              /-/g,
              ' ',
            )} IS OPEN ON STOKR`
          }
        } else if (project.type === ProjectTypes.FUND) {
          item.slug = 'STOKR CAPITAL'
          item.slideTitle =
            'ACCESS INSTITUTIONAL GRADE YIELDS WITH DIGITAL SECURITIES'
          if (project.name === 'microstrategy') {
            item.slideTitle =
              'THE FIRST TOKENIZED PUBLIC STOCK ACCESSIBLE ON STOKR'
            item.slug = 'CMSTR'
            item.slideBtnText = 'full details'
          }
        }

        newProject.push(item)
      }
    })

    //add two static hero blocks after the dynamic slides
    newProject.push(staticHomeHero[0], staticHomeHero[1], staticHomeHero[2])

    return newProject
  }

  getSliderStyle = () => {
    const { height } = this.state

    return css`
      .slick-dots {
        bottom: 0;
        display: inline-flex;
        width: auto;
        margin: 0;
        padding: 12px 16px;

        @media screen and (min-width: ${config.desktop}) {
          padding: 16px;
        }
      }

      .slick-slider {
        flex: 1 1 50%;
        /* height: 568px; */
        /* height: calc(100vh - 97px); */
        height: ${height - 97}px;
        min-height: 535px;

        ${breakpoint.below('tablet')} {
          height: 362px;
        }
        ${breakpoint.below('mobile')} {
          min-height: auto;
        }
      }

      .slick-list {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }

      button {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  }

  render() {
    const { isVisible, currentSlide, height, projects } = this.state

    return (
      <VisibilitySensor onChange={this.handleVisibility}>
        <Wrapper height={height}>
          <SliderWrapper isVisible={isVisible}>
            <Slider
              beforeChange={this.handleSlideChange}
              customStyle={this.getSliderStyle()}
              //autoplay
              infinite
            >
              {projects &&
                projects.map((item, index) => (
                  <StyledSlide key={index}>
                    {item.component({
                      videoOnOpen: this.handleVideoOpen,
                      videoOnClose: this.handleVideoClose,
                    })}
                  </StyledSlide>
                ))}
            </Slider>
          </SliderWrapper>
          <Content height={height} ref={this.content}>
            {projects && <BreakdownPanel slide={projects[currentSlide]} />}
          </Content>
        </Wrapper>
      </VisibilitySensor>
    )
  }
}

export default HomeHero
