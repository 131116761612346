import React from 'react'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'

const openHelper = ({ open }) =>
  open &&
  css`
    .one {
      transform: rotate(45deg);
      top: 50%;
      margin-top: -1px;
      width: 19px;
    }

    .two {
      right: 100%;
      opacity: 0;
    }

    .three {
      transform: rotate(-45deg);
      top: 50%;
      margin-top: -1px;
      width: 19px;
    }
  `

export const Wrapper = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    background: ${sc.BASE_COLOR_HEX};
    height: 2px;
    width: 16px;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .one {
    top: 0;
  }

  .two {
    top: 50%;
    right: 0;
    margin-top: -1px;
  }

  .three {
    bottom: 0;
  }

  ${openHelper}
`

export const BurgerToggle = ({ open, onClick }) => (
  <Wrapper open={open} onClick={onClick}>
    <span className="one" />
    <span className="two" />
    <span className="three" />
  </Wrapper>
)

export default BurgerToggle
