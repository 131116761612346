import styled from 'styled-components/macro'
import { Label as InputLabel } from '../Input/Input.styles'

export const Wrapper = styled.div`
  position: relative;
  display: block;
  margin-top: ${({ marginTop }) => marginTop && '7px'};
`

export const Label = styled(InputLabel)`
  position: relative;
  top: 0;
  transform: none;
  font-size: 10px;
  margin-bottom: 5px;
`

export const Input = styled.div`
  position: relative;

  & > input {
    z-index: -1;
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    width: 1px;
    height: 0;
    opacity: 0;
    border: 0;

    & + label {
      position: relative;
      display: inline-block;
      max-width: 100%;
      padding-left: 28px;
      font-family: 'Open Sans';
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      letter-spacing: 0.4px;
      cursor: pointer;

      p {
        margin: 0;
      }

      ${(props) => props.error && `color: ${props.theme.cWarning}`};

      p {
        font-size: 12px;
      }

      &:before,
      &:after {
        display: block;
        position: absolute;
      }

      &:before {
        content: '';
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid #9b9b9b;
        box-shadow: none;
        transition: border-color 0.2s;

        ${(props) =>
          props.large &&
          `
          width: 20px;
          height: 20px;
          border-radius: 4px;
        `}
      }

      &:after {
        content: '\\f3ff';
        font-family: 'Ionicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        font-size: 22px;
        line-height: 10px;
        text-align: center;
        color: transparent;

        ${(props) =>
          props.large &&
          `
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          font-size: 34px;
          line-height: 20px;
        `};
      }
    }

    &:focus + label,
    &:hover + label {
      &:before {
        border-color: ${(props) => props.theme.cPrimary};
      }
    }

    &:checked + label {
      &:after {
        color: ${(props) => props.theme.cPrimary};
      }
    }
  }
`
