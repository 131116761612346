import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { config } from 'utils'
import * as sc from 'constants/style'
import Cookies from 'js-cookie'
import Headline from 'components/ui/headline'
import Breakdown from 'components/ui/breakdown'
import Icon from 'components/ui/icon'
import IconPlaceholder from 'components/101/IconPlaceholder'
import AccordionCheckMark from 'components/ui/accordionCheckMark'
import Tooltip from 'components/ui/tooltip'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  width: 449px;
  background: white;
  height: 100%;
  @media screen and (max-width: ${config.lgDesktop}) {
    width: 321px;
  }
  @media screen and (max-width: ${config.tablet}) {
    display: none;
  }
  ${({ expanded }) =>
    expanded &&
    css`
      min-height: calc(100vh - 97px);

      position: absolute;
      /* top: 97px; */
      left: 0;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #f0f0f0;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: ${sc.RED_BASE_HEX};
        border-radius: 4px;
      }
      @media screen and (min-width: ${config.lgDesktop}) {
        width: 350px;
      }
      @media screen and (min-width: ${config.highDesktop}) {
        width: 449px;
      }
    `}
`

const SidebarHeadline = styled.div`
  padding: 0 ${sc.COLUMN}px;
  padding-top: 92px;
  padding-bottom: 72px;
  border-bottom: 1px solid ${sc.GRAY_BASE_HEX};
  background: white;
  ${Breakdown} {
    font-size: 11px;
    line-height: 1;
  }
  ${Headline} {
    margin-bottom: 0px;
    margin-top: 40px;
    line-height: 0.8;

    @media screen and (max-width: 1020px) {
      line-height: 1.18;
    }

    @media screen and (max-width: 768px) {
      margin-top: 36px;
    }
  }

  @media screen and (max-width: 1019px) {
    padding-bottom: 88px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 56px;
    padding-bottom: 68px;
  }
`

const ReturnLink = styled(Link)`
  color: ${sc.BLUE_BASE_HEX} !important;
  cursor: pointer;
  letter-spacing: 2.1px;
  font-size: 12px;
  position: relative;
  top: 24px;

  svg {
    width: 8px;
    transform: rotate(90deg);
    margin-right: 5px;
  }
`

const ListItem = styled.li`
  padding-left: ${sc.COLUMN}px;
  padding-right: ${sc.COLUMN}px;
  padding-top: ${sc.COLUMN / 3}px;
  padding-bottom: ${sc.COLUMN / 3}px;
  font-size: 12px;
  letter-spacing: 2.1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: 300;
  span {
    z-index: 1;
  }
`

const List = styled.ul`
  margin: 0;
  list-style: none;
  padding-left: 0;
  padding: 21.333px 0;

  ${({ isUserLogged }) =>
    isUserLogged &&
    css`
      ${ListItem}:after {
        content: '';
        position: absolute;
        height: calc(100% - 24px);
        border-left: 1px
          ${({ completed }) =>
            completed ? `solid ${sc.GREEN}` : `dotted ${sc.GRAY_BASE_HEX}`};
        right: calc(${sc.COLUMN}px + 12px);
        top: calc(50% + 12px);
        transform: translateX(50%);
        z-index: 1;
      }
      ${ListItem}:last-of-type {
        &:after {
          display: none;
        }
      }
    `}
`

const ArticleTitle = styled.span`
  font-weight: ${({ isActive }) => isActive && 'bold'};
  margin-right: 10px;
  letter-spacing: 1.2px;
`

const MenuLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  pointer-events: ${({ isActive }) => isActive && 'none'};
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const Sidebar = (props) => {
  const {
    categoryList,
    activeOption,
    handleActiveOption,
    handleWatchMode,
    expanded,
    isInside101,
    style,
    backButtonText,
    backButtonURL,
    backButtonState,
    isOpen,
  } = props

  const navigate = useNavigate()

  // TODO: this should NOT be set to false
  let isUserLogged = Cookies.get('STOKR_ACCESS_TOKEN')
  isUserLogged = false

  return (
    <Container expanded={expanded} style={style}>
      <SidebarHeadline>
        <Breakdown is101>101s</Breakdown>
        <Headline as="p" sidebar101heading>
          Learn More
        </Headline>
        {handleWatchMode && (
          // <ReturnLink onClick={handleWatchMode}>
          <ReturnLink to={backButtonURL} state={backButtonState}>
            <Icon name="ArrowSimple" direction="left" />
            {backButtonText.toUpperCase()}
          </ReturnLink>
        )}
      </SidebarHeadline>
      {categoryList.map(
        (
          {
            title,
            isSecurityTokens,
            id,
            noSidebarItems,
            disabled,
            completed,
            items,
            noArrow,
          },
          categoryIndex,
        ) => (
          <div
            key={id}
            onClick={() => {
              if (isInside101 && isSecurityTokens) {
                navigate('/', {
                  state: {
                    returningFrom101s: true,
                  },
                })
              } else {
                handleActiveOption && handleActiveOption(categoryIndex)
              }
            }}
          >
            <AccordionCheckMark
              title={title}
              RenderIcon={isUserLogged && IconPlaceholder}
              noArrow={noArrow}
              disabled={disabled}
              completed={completed}
              active={activeOption === id}
              key={categoryIndex}
              open={isOpen}
            >
              {items && !noSidebarItems && (
                <List completed={completed} isUserLogged={isUserLogged}>
                  {items.map(
                    (
                      {
                        itemTitle,
                        itemTitleLarge,
                        tooltipContent,
                        itemDisabled,
                        progress,
                        itemActive,
                        url,
                        id,
                      },
                      index,
                    ) => (
                      <ListItem key={index} progress={progress}>
                        {/* <MenuLink to={url} isActive={id === activeOption}> */}
                        <MenuLink
                          to={`/101/${url}`}
                          isActive={id === activeOption}
                        >
                          <ArticleTitle isActive={id === activeOption}>
                            {itemTitle}
                          </ArticleTitle>
                          {isUserLogged && (
                            <Tooltip
                              hover
                              content={tooltipContent}
                              type="inverted"
                              offsets={{ right: 100 }}
                              position="right"
                            >
                              <IconPlaceholder
                                disabled={itemDisabled}
                                small
                                active={itemActive}
                              />
                            </Tooltip>
                          )}
                        </MenuLink>
                      </ListItem>
                    ),
                  )}
                </List>
              )}
            </AccordionCheckMark>
          </div>
        ),
      )}
      {/* {!expanded &&
          categoryList.map(({ title, completed, progress }, index) => (
            <SidebarItem
              key={index}
              active={activeOption === index}
              onClick={() => handleActiveOption(index)}
              notClickable
            >
              <ItemTitleText>
                <span>
                  <IconWrapper>
                    <Icon name="ArrowSimple" direction="right" open />
                  </IconWrapper>
                  {title}
                </span>
                {isUserLogged && (
                  <>
                    {completed && <IconPlaceholder active small />}
                    {progress && <IconPlaceholder small />}
                  </>
                )}
              </ItemTitleText>
            </SidebarItem>
          ))} */}
    </Container>
  )
}

export default Sidebar
