/*  */
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import { breakpoint, config } from 'utils'
import Breakdown from 'components/ui/breakdown'
import Headline from 'components/ui/headline'
import RichText from 'components/ui/richText'
import HowItWorksSlider from 'components/ui/howItWorksSlider'
import * as sc from 'constants/style'
import { GraphSVG, RocketSVG, CoinsSVG } from './img/svg'
import { HeadlineMetaWrapper } from 'components/ui/howItWorksHomePageBlock/HowItWorksHomePageBlock'
import { ComponentWrapper } from '@stokr/components-library/dist/components/ComponentWrapper/ComponentWrapper.styles'

const Panel = styled.section`
  flex: 1;
  /* background: ${({ active }) => active && sc.BLUE_BASE_HEX}; */
  background: ${sc.WHITE_HEX};
  padding-left: 64px;
  padding-right: 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* padding-bottom: 80px; */
  padding-bottom: 140px;
  padding-top: 60px;
  cursor: pointer;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '50px')});
  /* transition: all 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86); */
  /* transition: ${({ active }) =>
    active ? 'all 0.3s ease-in-out' : 'none'}; */
  transition: all 0.3s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition-delay: ${({ index }) => `0.${index + 1}`}s;
  /* height: ${({ active }) => (active ? '528px' : '446px')}; */
  // height: 508px;
  height: 537px;
  /* background: lightcoral; */

  ${Breakdown} {
    margin-top: 60px;
    margin-bottom: 44px;
    max-height: 24px;
  }

  &:nth-of-type(1) {
    &::before {
      border-right: 1px solid #c0c0c01c;
      content: '';
      height: 48px;
      right: -1px;
      position: absolute;
      top: -48px;
      width: 33.33333vw;
    }
  }

  &:nth-of-type(2) {
    // border-left: 1px solid #c0c0c01c;
    // border-right: 1px solid #c0c0c01c;
  }

  @media screen and (max-width: ${config.tablet}) {
    background: ${sc.BLUE_BASE_HEX};
    padding-top: 136px;
    padding-left: 32px;
    padding-right: 32px;
    opacity: 1;
    height: unset;
    transform: translatey(0);
    ${Breakdown} {
      margin-top: unset;
      position: absolute;
      height: unset;
      top: 60px;
    }
  }

  @media screen and (max-width: 768px) {
    background: ${sc.BLUE_BASE_HEX};
    color: ${sc.WHITE_HEX};
  }
`

const Artwork = styled.div`
  width: 100%;
  min-height: 184px;
  max-height: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 52px; */
  position: relative;
  ${breakpoint.below('tablet')} {
    margin-bottom: 0;
  }

  svg {
    * {
      stroke: ${({ active }) => active && `${sc.BLUE_BASE_HEX} !important`};

      @media screen and (max-width: ${config.tablet}) {
        stroke: ${sc.WHITE_HEX} !important;
      }
    }
  }
`

const PanelMeta = styled.div`
  min-height: 200px;
  max-height: 200px;
`

const PanelHeader = styled(Headline)`
  min-height: 32px;
  margin-bottom: 45px;
  h4 {
    text-align: center;
    margin-top: 0;
  }
  ${breakpoint.below('tablet')} {
    margin-bottom: 28px;
  }
`

const PanelInfo = styled.div`
  width: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translateY(${({ active }) => (active ? '-3px' : 0)});
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
  div {
    text-align: center;
  }
`

const BreakdownStyled = styled(Breakdown)`
  /* color: ${({ active }) => active && '#202020'}; */
  transition: all 0.3s ease-in-out;

  ${({ isAlignLeft }) =>
    isAlignLeft &&
    css`
      align-self: flex-start;
      width: 100%;
      top: -7px !important;

      @media screen and (max-width: 768px) {
        display: none;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      @media screen and (min-width: 769px) {
        display: none;
      }
    `}

    ${({ wrap }) =>
    wrap &&
    css`
      text-wrap: wrap;
      padding: 0 32px;
      text-align: center;
    `}
`

const ContainerDesktop = styled.span`
  //border-bottom: 1px solid ${sc.GRAY_BASE_HEX};
  width: 100%;
  //min-height: 611px;
  // min-height: 557px;
  min-height: 537px;
  display: flex;
  /* overflow: hidden; */
  position: relative;

  @media screen and (max-width: ${config.tablet}) {
    display: none;
  }

  &::before {
    background: ${sc.BLUE_BASE_RELEASED_HEX};
    //bottom: 0;
    bottom: -9%;
    content: '';
    //height: 164px;
    height: 50px;
    position: absolute;
    width: 100%;
    z-index: -2;
  }

  &::after {
    background: ${sc.BLUE_BASE_HEX};
    //bottom: 0;
    bottom: -9%;
    content: '';
    //height: 164px;
    height: 50px;
    position: absolute;
    right: 0;
    width: 61.8%;
    z-index: -1;
  }

  ${Panel} {
    padding-bottom: 0;
    padding-top: 0;
  }

  ${Breakdown} {
    margin-top: 0;
    position: absolute;
    top: 50px;
  }

  ${Artwork} {
    position: absolute;
    /* top: 120px; */
    top: 60px;
    margin-top: 1.4rem;
  }

  ${PanelMeta} {
    padding: 0 10%;
    position: absolute;
    /* top: 320px; */
    top: 250px;
    margin-top: 1.4rem;
  }

  ${Headline} {
    color: #202020;
  }
`

const ContainerMobile = styled.span`
  width: 100%;
  min-height: 588px;
  display: none;
  margin-bottom: 48px;

  @media screen and (max-width: ${config.tablet}) {
    display: flex;
    margin-bottom: 0;
  }
`

const items = [
  ({
    onClick,
    onMouseEnter,
    onMouseLeave,
    currentHovered,
    index,
    children,
    isVisible,
  }) => (
    <Panel
      isVisible={isVisible}
      index={index}
      onClick={() => onClick('sme-fundraising')}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
      active={currentHovered === index}
    >
      {children}
      <BreakdownStyled isAlignLeft active={currentHovered === index}>
        {/* HOW STOKR WORKS */}
      </BreakdownStyled>
      <Artwork active={currentHovered === index}>
        <RocketSVG isVisible={isVisible} active={currentHovered === index} />
      </Artwork>
      <PanelMeta>
        <PanelHeader>
          <Headline as="h4" active={currentHovered === index}>
            SME <br />
            Fundraising
          </Headline>
        </PanelHeader>
        <PanelInfo active={currentHovered === index}>
          <RichText active={currentHovered === index} isWhite>
            A complete fund raising solution for start-ups and SMEs.
          </RichText>
        </PanelInfo>
      </PanelMeta>
    </Panel>
  ),
  ({
    onClick,
    onMouseEnter,
    onMouseLeave,
    currentHovered,
    index,
    isVisible,
    children,
  }) => (
    <Panel
      isVisible={isVisible}
      onClick={() => onClick('asset-tokenization')}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
      index={index}
      active={currentHovered === index}
    >
      {children}
      <BreakdownStyled isMobile active={currentHovered === index}>
        {/* Swipe and click to discover our products */}
      </BreakdownStyled>
      <Artwork active={currentHovered === index}>
        <GraphSVG isVisible={isVisible} active={currentHovered === index} />
      </Artwork>
      <PanelMeta active>
        <PanelHeader>
          <Headline as="h4" active={currentHovered === index}>
            ASSET <br />
            TOKENIZATION
          </Headline>
        </PanelHeader>
        <PanelInfo active={currentHovered === index}>
          <RichText active={currentHovered === index} isWhite>
            Tokenize any assets and raise capital through institutional grade
            blockchain solutions.
          </RichText>
        </PanelInfo>
      </PanelMeta>
    </Panel>
  ),
  ({
    onClick,
    onMouseEnter,
    onMouseLeave,
    currentHovered,
    index,
    children,
    isVisible,
  }) => (
    <Panel
      isVisible={isVisible}
      onClick={() => onClick('fund-tokenization')}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
      index={index}
      active={currentHovered === index}
    >
      {children}
      <Artwork active={currentHovered === index}>
        <CoinsSVG isVisible={isVisible} active={currentHovered === index} />
      </Artwork>
      <PanelMeta>
        <PanelHeader>
          <Headline as="h4" active={currentHovered === index}>
            FUND <br /> TOKENIZATION
          </Headline>
        </PanelHeader>
        <PanelInfo active={currentHovered === index}>
          <RichText active={currentHovered === index} isWhite>
            Tokenize your investment fund units and get access to a new world of
            investors.
          </RichText>
        </PanelInfo>
      </PanelMeta>
    </Panel>
  ),
]

export class HowItWorks extends PureComponent {
  state = {
    currentHovered: 1,
    clicked: false,
    isVisible: false,
  }

  handleVisibility = (receivedState) => {
    const { isVisible } = this.state

    if (!isVisible) {
      setTimeout(() => this.setState({ isVisible: receivedState }))
    }
  }

  handleMouseEnter = (panel) =>
    !this.state.clicked && this.setState({ currentHovered: panel })

  handleMouseLeave = () =>
    !this.state.clicked && this.setState({ currentHovered: 1 })

  //onClick = panel => this.setState({ clicked: true, currentHovered: panel })

  onClick = (url) => {
    window.location.href = `/products/${url}`
  }

  render() {
    const { currentHovered } = this.state
    const { isVisible } = this.state
    return (
      <VisibilitySensor
        onChange={this.handleVisibility}
        partialVisibility
        minTopValue={300}
      >
        <React.Fragment>
          <ContainerDesktop>
            {items.map((ItemPanel, index) => (
              <ItemPanel
                key={index}
                index={index}
                isVisible={isVisible}
                currentHovered={currentHovered}
                onClick={this.onClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
              />
            ))}
          </ContainerDesktop>
          <ContainerMobile>
            <HowItWorksSlider
              items={[...items.slice(1)]}
              onClick={this.onClick}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              isVisible={isVisible}
            >
              {/* <BreakdownStyled active wrap>
                Swipe and click to discover our products
              </BreakdownStyled> */}
            </HowItWorksSlider>
          </ContainerMobile>
        </React.Fragment>
      </VisibilitySensor>
    )
  }
}

export default HowItWorks
