import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import * as sc from 'constants/style'

const progressBg = require('./img/emptyProgress.svg')

const widthHelper = ({ width = 0 }) => `${width}%`
const heightHelper = ({ height = 8 }) => `${height}px`

const StyledWrapper = styled.div`
  display: flex;
  height: 8px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  background: ${sc.BLUE_BASE_RELEASED_HEX};
  ${({ progressStyle }) => progressStyle}

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.3;
    `}
`

const StyledPrivateInvest = styled.div`
  background: transparent;
  flex: 0 0 ${widthHelper};
  height: ${heightHelper};
  border-radius: 12px 0 0 12px;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px 0 0 12px;
    background-image: linear-gradient(to left, #fff, #fff);
    transition: ${({ animation,showProgress }) => (animation && showProgress ? 'transform 2.5s 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)' : 'none')};
    transform-origin: left top;
    transform: scaleX(${({ animation,showProgress }) => (animation || showProgress ? 1 : 0)});
  }
`

const StyledSuccess = styled.div`
  flex: 0 0 ${widthHelper};
  height: ${heightHelper};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 12px 12px 0;
    background-image: linear-gradient(to left, #a9ef9c, #69bd53);
    transition: ${({ animation,showProgress }) => (animation && showProgress ? 'transform 2.5s 1.25s cubic-bezier(0.645, 0.045, 0.355, 1)' : 'none')};
    transform-origin: left top;
    transform: scaleX(${({ animation, showProgress }) => (animation || showProgress ? 1 : 0)});
  }
`

const StyledEmpty = styled.div`
  flex: 0 0 ${widthHelper};
`

const StyledDisabled = styled.div`
  background: url(${progressBg}) repeat bottom;
  flex: 0 0 ${widthHelper};
  height: ${heightHelper};
`

export class Progress extends PureComponent {
  static propTypes = {
    privateInvestment: PropTypes.number,
    success: PropTypes.number,
    empty: PropTypes.number,
    disabled: PropTypes.number,
  }

  state = {
    //animation: false,
    //showProgress:false
  }


  handleVisibilityChange = isVisible => {

    console.log("visibleProgress")

    //re-render animation in the given interval if the block is currently visible
    if(this.props.visible){

      //on first render set to true to start animation
      if (!this.state.animation) {
          this.setState({ animation: true, showProgress:true })

      }

      //on every 3s change animation(false) but show progress bar until next animation change occurs(true)
      this.reAnimateProgress=setInterval(() => {
      
         this.setState({ animation: !this.state.animation, showProgress:true })

      }, 6000);

      
      this.reAnimateProgress2=setInterval(() => {
  
        this.setState({showProgress:!this.state.showProgress})

     }, 11900);



      
    }
    else{
      clearInterval(this.reAnimateProgress);
      clearInterval(this.reAnimateProgress2);

    }

    // if (!animation) {
    //   setTimeout(() => this.setState({ animation: isVisible }))
    // }
  
  
  }
 

  render() {
    const {
      privateInvestment,
      success,
      empty,
      disabled,
      progressStyle,
      inactive,
      animation, showProgress
    } = this.props
    //const { animation, showProgress } = this.state

    //console.log("Animation: ", animation)
    //console.log("showProgress: ", showProgress)



    return (
     //  <VisibilitySensor onChange={this.handleVisibilityChange} >
      
        <StyledWrapper progressStyle={progressStyle} inactive={inactive}>
          <StyledPrivateInvest
            width={privateInvestment || ''}
            animation={animation} showProgress={showProgress}
          />
          <StyledSuccess width={success || ''} animation={animation} showProgress={showProgress} />
          <StyledEmpty width={empty || ''} />
          <StyledDisabled width={disabled || ''} />
        </StyledWrapper>
        
      // </VisibilitySensor>
    )
  }
}

export default Progress
