import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import { Portal } from 'react-portal'
import { CSSTransition } from 'react-transition-group'

const positionHelper = ({ position, offsets = {} }) => {
  switch (position) {
    case 'top':
      return css`
        top: ${offsets.top}px;
        left: ${offsets.left - sc.BASE_MARGIN}px;
        transform: translate(-40%, -160%);

        // ${breakpoint.below('tablet')} {
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        // }
      `
    case 'bottom':
    case 'right':
      return css`
        top: ${offsets.top}px;
        left: ${offsets.left + offsets.width + sc.BASE_MARGIN}px;

        transform: translate(0%, -50%);
        ${breakpoint.below('tablet')} {
          right: 50%;
          transform: translate(-50%, -50%);
        }
      `
    case 'left':
    default:
      return css`
        top: ${offsets.top}px;
        left: ${offsets.left - sc.BASE_MARGIN}px;
        transform: translate(-100%, -50%);

        ${breakpoint.below('tablet')} {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `
  }
}

const borderHelper = ({ position }) => {
  switch (position) {
    case 'top':
    case 'bottom':
    case 'right':
    case 'left':
    default:
      return css`
        right: 0;
        border-radius: 0 8px 8px 0;
      `
  }
}

const arrowColorHelper = (type) => {
  switch (type) {
    case 'inverted':
      return sc.BLUE_BASE_RELEASED_HEX
    case 'default':
    default:
      return sc.WHITE_HEX
  }
}

const positionArrowHelper = ({ position, type }) => {
  switch (position) {
    case 'top':
      return css`
        bottom: 100%;
        left: 50%;
        border-bottom-color: ${arrowColorHelper(type)};
        border-width: 10px;
        margin-left: -10px;
        transform: rotate(180deg);
        top: 100%;
      `
    case 'bottom':
      return css`
        top: 100%;
        left: 50%;
        border-top-color: ${arrowColorHelper(type)};
        border-width: 10px;
        margin-left: -10px;
      `
    case 'right':
      return css`
        right: 100%;
        top: 50%;
        border-right-color: ${arrowColorHelper(type)};
        border-width: 10px;
        margin-top: -10px;
      `
    case 'left':
    default:
      return css`
        left: 100%;
        top: 50%;
        border-left-color: ${arrowColorHelper(type)};
        border-width: 10px;
        margin-top: -10px;
      `
  }
}

const cursorHelper = ({ cursor }) => cursor

const TooltipContent = styled.div`
  display: inline-block;
  background: ${sc.WHITE_HEX};
  position: absolute;
  color: ${sc.BASE_COLOR_HEX};
  border-radius: 8px;
  padding: ${sc.BASE_MARGIN * 2}px;
  z-index: 9999;
  font-size: ${sc.BASE_FONT_SIZE}px;
  min-width: 340px;
  max-width: 380px;
  width: 100%;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
  /* transition: opacity ${({ animationLen }) => animationLen}ms; */

  ${breakpoint.below('tablet')} {
    min-width: 240px;
    max-width: 340px;
  }

  &.tooltip-enter {
    opacity: 0.01;
  }
  &.tooltip-enter-active {
    opacity: 1;
    transition: opacity ${({ animationLen }) => animationLen}ms;
  }
  &.tooltip-exit {
    opacity: 1;
    transition: opacity ${({ animationLen }) => animationLen}ms;
  }
  &.tooltip-exit-active {
    opacity: 0.01;
  }

  &:after {
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    ${arrowColorHelper};
  }

  ${positionHelper};
  &:after {
    ${positionArrowHelper};
  }

  // ${breakpoint.below('tablet')} {
  //   position: fixed;
  //   top: 50% !important;
  //   left: 50% !important;

  //   &:after {
  //     display: none;
  //   }
  // }
`
const TooltipChildren = styled.div`
  cursor: ${cursorHelper};
  z-index: 2;
`

const TooltipBorder = styled.div`
  position: absolute;
  top: 0;
  background: ${sc.BLUE_BASE_HEX};
  width: 8px;
  height: 100%;

  ${borderHelper};
`

export class Tooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    position: PropTypes.string,
    cursor: PropTypes.string,
    border: PropTypes.bool,
    className: PropTypes.string,
    as: PropTypes.any,
    type: PropTypes.string,
    open: PropTypes.bool,
    contentStyle: PropTypes.any,
    hover: PropTypes.bool,
  }

  static defaultProps = {
    position: 'left',
    cursor: 'inherit',
  }

  tooltipTarget = React.createRef(null)

  animationLen = 180

  state = {
    open: this.props.open,
    toggledByClick: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    window.addEventListener('resize', this.hideTooltip)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    window.removeEventListener('resize', this.hideTooltip)
  }

  handleClickOutside = (event) => {
    if (
      this.tooltipTarget.current &&
      !this.tooltipTarget.current.contains(event.target)
    ) {
      this.toggleTooltip(false, { toggledByClick: true })
    }
  }

  toggleTooltip = (open = false, options = {}) => {
    const { toggledByClick } = this.state
    const { hover } = this.props

    if (hover) {
      this.setState({ open })
    } else {
      if (options.toggledByClick) {
        this.setState({ open, toggledByClick: options.toggledByClick })
      }

      if (!toggledByClick) {
        this.setState({ open })
      }
    }
  }

  hideTooltip = () => this.setState({ open: false })

  render() {
    const {
      children,
      content,
      position,
      cursor,
      type,
      as,
      className,
      border,
      contentStyle,
    } = this.props
    const { open } = this.state
    const bodyRect = document.body.getBoundingClientRect()
    const currentRect =
      (this.tooltipTarget.current &&
        this.tooltipTarget.current.getBoundingClientRect()) ||
      {}
    const tooltipTargetHeight =
      (this.tooltipTarget.current && this.tooltipTarget.current.clientHeight) ||
      0
    const offsets = {
      top: currentRect.top - bodyRect.top + tooltipTargetHeight / 2,
      left: currentRect.left + 16,
      right: currentRect.right,
      x: currentRect.x,
      width: currentRect.width,
    }

    return (
      <React.Fragment>
        <TooltipChildren
          as={as}
          className={className}
          onMouseEnter={() => this.toggleTooltip(true)}
          onClick={() => this.toggleTooltip(true, { toggledByClick: true })}
          onMouseLeave={() => this.toggleTooltip(false)}
          ref={this.tooltipTarget}
          cursor={cursor}
        >
          {children}
        </TooltipChildren>
        {content && (
          <Portal>
            <CSSTransition
              in={open}
              timeout={this.animationLen}
              classNames="tooltip"
              unmountOnExit
            >
              <TooltipContent
                animationLen={this.animationLen}
                position={position}
                type={type}
                offsets={offsets}
                style={contentStyle}
                data-cy="tooltip"
              >
                {border && <TooltipBorder position={position} />}
                {/* <Tween
                  ease="Sine.easeInOut"
                  duration={0.2}
                  from={{ y: '3px', autoAlpha: 0.7 }}
                >
                </Tween> */}
                <div>{content}</div>
              </TooltipContent>
            </CSSTransition>
          </Portal>
        )}
      </React.Fragment>
    )
  }
}

export default Tooltip
