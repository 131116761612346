import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

import Container from 'components/ui/container'
import BlockTowerContent from 'components/ui/block/BlockTowerContent'

const lastHelper = ({ last }) =>
  !last &&
  css`
    border-bottom: 1px solid ${sc.GRAY_BASE_HEX};
  `

const withColumnsHelper = ({ withColumns }) =>
  withColumns &&
  css`
    display: flex;
    > div {
      flex: 0 0 50%;
    }
    ${breakpoint.below('tablet')} {
      display: block;
    }
  `

const BlockOuter = styled.div`
  ${lastHelper};
  ${withColumnsHelper};
  ${({ customCss }) => customCss};

  border-bottom: ${({ noBorder }) => noBorder && 'none'};

  @media screen and (max-width: 991px) {
    border-bottom: ${({ noBorderBottomMobile }) =>
      noBorderBottomMobile && 'none'};
  }
`

const withSeparatorHelper = ({ separator }) => {
  if (typeof separator !== 'undefined') {
    return typeof separator === 'boolean'
      ? css`
          border-right: 1px solid ${sc.GRAY_BASE_HEX};
        `
      : css`
          border-${separator}: 1px solid ${sc.GRAY_BASE_HEX};
        `
  }
  return ''
}
export const BlockColumn = styled.div`
  ${withSeparatorHelper};
  background: ${sc.WHITE_HEX};
`

export const BlockTowerImg = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  width: 100%;
  border-left: 1px solid ${sc.GRAY_BASE_HEX};

  ${breakpoint.below('tablet')} {
    padding-top: ${sc.BASE_MARGIN * 2}px;
    padding-bottom: ${sc.BASE_MARGIN * 2}px;
    max-width: 100%;
    border-left: 0;
  }
`

export const BlockTowerContentImg = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: ${sc.BASE_MARGIN * 2}px;
  padding-right: ${sc.BASE_MARGIN * 2}px;
  max-width: ${sc.MAX_WIDTH / 2}px;

  ${breakpoint.below('tablet')} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const BlockTowerTitle = styled.div`
  position: relative;

  ${BlockTowerContent} {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    margin-top: 0;
  }

  &::before {
    content: '';
    left: 0;
    position: absolute;
    height: 100%;
    width: 8px;
    background: ${sc.BLUE_BASE_RELEASED_HEX};
    display: ${({ noBorder }) => noBorder && 'none'};

    ${breakpoint.below('desktop')} {
      left: 91.5%;
      margin-left: -100%;
    }
    ${breakpoint.below('mobile')} {
      left: 0;
      margin-left: 0;
    }

    ${({ leftBarColor }) =>
      leftBarColor === 'ventures' &&
      css`
        background: #0050ca;
      `}

    ${({ leftBarColor }) =>
      leftBarColor === 'investors' &&
      css`
        background: #ee220d;
      `}
  }
`

export class Block extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    compact: PropTypes.bool,
    last: PropTypes.bool,
    withColumns: PropTypes.bool,
    customCss: PropTypes.any,
    id: PropTypes.string,
    noBorder: PropTypes.bool,
    isMobile: PropTypes.bool,
    noContainerPadding: PropTypes.bool,
  }

  render() {
    const {
      children,
      compact,
      last,
      withColumns,
      id,
      customCss,
      noBorder,
      isMobile,
      noBorderBottomMobile,
      noPaddingHorizontal,
      fullContainerWidth,
    } = this.props

    return (
      <Container
        noPaddingHorizontal={noPaddingHorizontal}
        compact={compact}
        id={id}
        customCss={customCss}
        isMobile={isMobile}
        noContainerPadding
        fullContainerWidth={fullContainerWidth}
      >
        <BlockOuter
          withColumns={withColumns}
          last={last}
          compact={compact}
          customCss={customCss}
          noBorder={noBorder}
          noBorderBottomMobile={noBorderBottomMobile}
        >
          {children}
        </BlockOuter>
      </Container>
    )
  }
}

export default Block
