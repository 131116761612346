import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slick from 'react-slick'
import SlickOptions from 'components/ui/slider/SlickOptions'
import * as sc from 'constants/style'

import SliderArrow from 'components/ui/slider/SliderArrow'
import StyledDotsContainer from 'components/ui/slider/StyledDotsContainer'
import StyledDots from 'components/ui/slider/StyledDots'

const StyledSlick = styled.div`
  .slick-track,
  .slick-list,
  .slick-track div {
    height: 100%;
  }

  div {
    outline: none;
  }

  .slick-list {
    background: ${({ hasMotionDesign }) =>
      hasMotionDesign ? sc.RED_BASE_RELEASE_HEX : 'white'};
  }
  .slick-slide {
    position: relative;
    z-index: 4;

    &::before {
      content: '';
      display: ${({ hasMotionDesign }) => (hasMotionDesign ? 'block' : 'none')};
      background: ${sc.RED_BASE_HEX};
      position: absolute;
      left: -200px;
      width: 200px;
      top: 0;
      bottom: 0;
      transform-origin: right center;
      transition: transform 0.8s 0.2s ease-in;
      z-index: -20;
    }
  }
  .slick-slide.slick-current ~ .slick-slide {
    z-index: 1;
  }
  .slick-slide.slick-current {
    z-index: 2;

    & + .slick-slide + .slick-slide::before {
      opacity: 0; /* Hack to prevent overlapping */
    }

    &::before {
      transform: scaleX(0);
    }
  }

  ${({ customStyle }) => customStyle}
`

export class Slider extends PureComponent {
  static propTypes = {
    autoplay: PropTypes.bool,
    arrows: PropTypes.bool,
    hasMotionDesign: PropTypes.bool,
  }

  static defaultProps = {
    arrows: true,
  }

  state = {
    isTouchInitiated: false,
  }

  slickRef = React.createRef()

  nodeId = `slick-slider-${Math.random().toString(32).substr(2)}`

  initialTouchPos = null

  touchPos = null

  sliderSettings = {
    dots: true,
    infinite: this.props.infinite,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: this.props.autoplay,
    autoplaySpeed: SlickOptions.interval,
    speed: SlickOptions.transitionLength,
    cssEase: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
    arrows: true,
    className: this.props.className,
    nextArrow: (
      <SliderArrow
        arrowStyle={this.props.arrowStyle}
        position={{ left: '64px' }}
      />
    ),
    prevArrow: (
      <SliderArrow
        arrowStyle={this.props.arrowStyle}
        left
        position={{ left: 0 }}
      />
    ),
    appendDots: (dots) => <StyledDotsContainer>{dots}</StyledDotsContainer>,
    customPaging: () => <StyledDots />,
  }

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart)
    document.addEventListener('touchend', this.handleTouchEnd)
  }

  componentDidUpdate() {
    const { autoplay } = this.props

    if (autoplay) {
      const { slickPlay } = this.slickRef
      slickPlay && slickPlay()
    } else {
      const { slickPause } = this.slickRef
      slickPause && slickPause()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchStart)
    document.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleTouchStart = (event) => {
    this.initialTouchPos = event.touches[0].clientX

    if (this.slickRef.current && this.slickRef.current.contains(event.target)) {
      window.addEventListener('touchmove', this.handleTouchMove, {
        passive: false,
      }) // Need to disable passive for Chrome 54+
      this.setState({ isTouchInitiated: true })
    }
  }

  handleTouchMove = (event) => {
    const touchPos = event.touches[0].clientX
    this.touchPos = touchPos

    if (Math.abs(touchPos - this.initialTouchPos) > 5 && event.cancelable) {
      event.preventDefault()
    }
  }

  handleTouchEnd = () => {
    window.removeEventListener('touchmove', this.handleTouchMove)
    this.setState({ isTouchInitiated: false })
  }

  sliderMotionDesign = (index, nextIndex) => {
    const { beforeChange } = this.props
    const { isTouchInitiated } = this.state

    if (!isTouchInitiated) {
      const slides = document
        .getElementById(this.nodeId)
        .getElementsByClassName('slick-slide')
      const nextSlide = slides[nextIndex]
      const translateAmount = '40%'

      nextSlide.style.transition = 'none'
      nextSlide.style.transform = 'translate3d(0, 0, 0)'

      if (nextIndex > index) {
        nextSlide.style.transform = `translate3d(${translateAmount}, 0, 0)`
      } else if (nextIndex < index) {
        nextSlide.style.transform = `translate3d(-${translateAmount}, 0, 0)`
      }

      setTimeout(() => {
        nextSlide.style.transition =
          'transform 850ms 120ms cubic-bezier(0.455, 0.03, 0.515, 0.955)'
      }, 50)
      setTimeout(() => {
        nextSlide.style.transform = 'translate3d(0, 0, 0)'
      }, 51)
    }

    if (beforeChange) {
      // Allow external beforeChange
      beforeChange(index, nextIndex)
    }
  }

  render() {
    const {
      customStyle,
      children,
      beforeChange,
      afterChange,
      arrows,
      hasMotionDesign,
    } = this.props

    const { isTouchInitiated } = this.state

    return (
      <StyledSlick
        ref={this.slickRef}
        id={this.nodeId}
        customStyle={customStyle}
        hasMotionDesign={hasMotionDesign && !isTouchInitiated}
      >
        <Slick
          {...this.sliderSettings}
          beforeChange={
            hasMotionDesign ? this.sliderMotionDesign : beforeChange
          }
          afterChange={afterChange}
          arrows={arrows}
        >
          {children}
        </Slick>
      </StyledSlick>
    )
  }
}

export default Slider
