import React, { memo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import SocialUrls from 'components/ui/socialUrls'
import SocialUrlsPT from 'components/ui/socialUrls/SocialUrlsPT'
import avatarPlaceholder from 'images/avatar-placeholder.png'
import { breakpoint } from 'utils'
import theme from 'components/signup/styles/theme'
import pattern from 'images/blue-pattern.svg'
import Button from '../button'
import plus from 'images/Plus.svg'
import { Video } from 'components/ui'

const InfoBlock = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  background: ${theme.cPrimary};
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;

  &::before {
    content: '';
    background: url(${pattern});
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    width: 330px;

    ${breakpoint.above('tablet')} {
      height: 90%;
    }
  }

  ${(props) =>
    props.active &&
    `
    height: calc(100% - 34px);
    opacity:1;
  `}
`

const Photo = styled.img`
  width: 100%;
  object-fit: cover;
  height: calc(100% - 34px);

  ${({ teamPicture }) =>
    teamPicture &&
    css`
      ${breakpoint.below('mobile')} {
        object-fit: contain;
        height: unset;
      }
    `}
`

const UserName = styled.div`
  line-height: 1.5;
  letter-spacing: 0.6px;
  color: rgb(32, 32, 32);
  font-weight: 400;
  font-size: 16px;
  padding-top: 16px;
  margin-top: -6px;

  ${breakpoint.below('mobile')} {
    font-size: 12px;
  }

  ${(props) =>
    props.video &&
    `
    margin-top: -18px;
    padding-top: 0;

    ${breakpoint.below('mobile')} {
       margin-top: 0px;
    padding-top: 16px;
  }
  `}
`

const MemberWrap = styled.div`
  position: relative;
  height: 100%;

  &:hover {
    ${(props) =>
      !props.teamPicture &&
      `
      ${breakpoint.above('tablet')}{

        cursor: pointer;
        ${InfoBlock} {
          height: calc(100% - 34px);
          opacity:1;
        }
      }
    `}
  }
`
const TextBlock = styled.div`
  height: 100%;
  padding: 24px;
  padding-top: 40px;
  color: white;
  position: relative;
`
const HeaderText = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.6px;

  ${breakpoint.below('mobile')} {
    font-size: 16px;
  }
`
const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.6px;
  padding-top: 16px;

  ${breakpoint.below('mobile')} {
    font-size: 12px;
  }
`
const Social = styled.div`
  height: 40px;
  bottom: 24px;
  position: absolute;
  text-decoration: none;

  ${SocialUrls} {
    svg {
      fill: ${theme.cPrimary};
    }
    a:link,
    a:active,
    a:visited {
      text-decoration: none;
      color: ${theme.cPrimary};
    }
  }

  ${(props) =>
    props.mobile &&
    `
    margin-left:48px;
  `}
`
const MobileInfoButton = styled.div`
  position: absolute;
  left: 24px;
  bottom: 58px;

  ${breakpoint.above('tablet')} {
    display: none;
  }

  ${(props) =>
    props.teamPicture &&
    `
    display:none;
  `}
`
const PlusIcon = styled.img`
  transition: transform 0.6s ease-in-out
    ${(props) =>
      props.active &&
      `
      transform:rotate(135deg);
    `};
`

export const TeamMemberPreview = memo(({ member }) => {
  const [activeItem, setactiveItem] = React.useState(false)

  const {
    photoUrl,
    userName,
    position,
    teamPicture,
    description,
    type,
    videoThumbnailUrl,
  } = member

  let avatar = photoUrl === 'error' ? avatarPlaceholder : photoUrl
  const userNameDisplay = teamPicture ? userName : `${userName} - ${position}`
  if (!avatar) {
    avatar = avatarPlaceholder
  }

  return (
    <MemberWrap teamPicture={teamPicture}>
      {teamPicture && type === 'video' ? (
        <Video
          src={photoUrl}
          //onOpen={() => setisVideoPlaying(true)}
          //onClose={() => setisVideoPlaying(false)}
          fullHeight
          poster={
            <Photo
              src={videoThumbnailUrl}
              alt={userName}
              teamPicture={teamPicture}
            />
          }
        />
      ) : (
        <Photo src={avatar} alt={userName} teamPicture={teamPicture} />
      )}
      <UserName video={type === 'video'}>{userNameDisplay}</UserName>
      <InfoBlock active={activeItem}>
        <TextBlock>
          {description && (
            <>
              <HeaderText>Info</HeaderText>
              <Description>{description}</Description>
            </>
          )}
          <Social mobile={activeItem}>
            <SocialUrls color="white" {...member} smallMargin />
          </Social>
        </TextBlock>
      </InfoBlock>

      <MobileInfoButton teamPicture={teamPicture}>
        <Button
          icononly
          color="white"
          onBlur={(e) => {
            setTimeout(() => {
              setactiveItem(false)
            }, 100)
          }}
          onClick={(e) => setactiveItem(!activeItem)}
        >
          <PlusIcon active={activeItem} src={plus} alt="more info" />
        </Button>
      </MobileInfoButton>
    </MemberWrap>
  )
})

TeamMemberPreview.propTypes = {
  photoUrl: PropTypes.string,
  userName: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  lite: PropTypes.bool,
  ...SocialUrlsPT,
}

export default TeamMemberPreview
