import Button from 'components/ui/button'
import * as sc from 'constants/style'
import { config } from 'utils/breakpoint'
import styled, { css } from 'styled-components/macro'
import media from 'config/media'
import theme from 'config/theme'

import {
  ContentSection,
  UppercaseText,
  ContentInnerLink,
} from 'components/landing-page/PageText'
import { ListItem } from 'components/landing-page/List'
import { LandingPageTitle } from 'components/landing-page/PageTitle'

const { tablet, desktop, highDesktop } = config

export const Container = styled.div`
  border-bottom: 1px solid #ededed;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1886px;

  @media screen and (min-width: ${desktop}) {
    flex-direction: row;
  }
`

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;

  ${ContentSection} {
    order: 2;

    @media screen and (min-width: ${tablet}) {
      order: 1;
    }

    @media screen and (min-width: ${tablet}) {
      order: 1;
    }
  }

  ${ContentInnerLink} {
    @media screen and (min-width: ${desktop}) {
      order: 2;
    }
  }

  ${LandingPageTitle} {
    margin-top: 3.8rem;
${props =>
  props.pressRelease &&
  `
font-size:22px !important;`}
    @media screen and (min-width: ${desktop}) {
      margin-top: 1.45rem;
      margin-bottom: 1.55rem;
    }
  }

  ${UppercaseText} {
    top: 2rem;

    &:first-of-type {
      top: 2.2rem;

      @media screen and (min-width: ${tablet}) {
        top: 2.3rem;
      }

      @media screen and (min-width: ${desktop}) {
        top: 1.75rem;
        margin-bottom: 0;
      }
    }

    &:nth-of-type(2) {
      @media screen and (min-width: ${tablet}) {
        top: 2.05rem;
      }

      @media screen and (min-width: ${desktop}) {
        top: 1.5rem;
      }
    }
  }

  @media screen and (min-width: ${desktop}) {
    width: 70%;
    padding: 0;
  }
`

export const LeftPanel = styled.div`
  align-self: flex-end;
  border-right: 1px solid rgb(245, 245, 245);
  height: calc(80vh - 98px);
  margin-bottom: -70px;
  padding: 1.9rem 2rem 3rem;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${tablet}) {
    padding: 1.9rem 3rem;
    display: flex;
    justify-content: center;
    margin-bottom: -88px;
  }

  @media screen and (min-width: ${desktop}) {
    bottom: 0;
    height: calc(100vh);
    max-width: 943px;
    max-width: 50%;
    min-width: 50%;
    padding: 0;
    padding-top: 2rem;
    position: sticky;
  }

  @media screen and (min-width: 767px) and (max-width: 991px) and (min-height: 767px) {
    display: block;
    height: calc(60vh - 98px);
  }

  @media screen and (max-width: 991px) {
    display: block;
    height: auto;
    margin-bottom: 0;
  }
`

export const RightPanel = styled.div`
  // margin-bottom: -63px;
  padding: 0 2rem;
  position: relative;
  width: 100%;
  overflow: scroll;
  top: -49px;

  a,
  a:active,
  a:focus,
  a:visited {
    color: #4183c4;
  }

  a:hover {
    color: #1e70bf;
  }

  ${Button} {
    color: white !important;
    font-size: 0.75rem;
    margin: 25px 0 80px;
    padding: 0 32px;
    position: relative;
    top: -25px;
    width: 100%;

    &:hover,
    &:active,
    &:visited {
      background: ${sc.BLUE_BASE_RELEASED_HEX};
      color: white !important;
    }

    @media screen and (min-width: ${desktop}) {
      font-size: 0.55rem;
      margin-bottom: 0;
      width: auto;
    }

    @media screen and (min-width: 1200px) {
      font-size: 0.65rem;
    }

    @media screen and (min-width: 1440px) {
      font-size: 0.75rem;
      padding: 0px 2.5rem;
    }

    @media screen and (min-width: ${highDesktop}) {
      font-size: 0.75rem;
    }

    @media screen and (min-width: 320px) and (max-width: 479px) and (max-height: 850px) {
      margin-bottom: 0;
    }

    @media screen and (min-width: 480px) and (max-width: 767px) and (max-height: 850px) {
      margin-bottom: 0;
    }
  }

  ${ContentSection} {
    @media screen and (min-width: ${desktop}) {
      width: 70%;
    }
  }

  ${ListItem} {
    @media screen and (min-width: ${desktop}) {
      top: -1px;
    }
  }

  a:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${tablet}) {
    padding: 0 3rem;
    top: -47px;
  }

  @media screen and (min-width: ${desktop}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 50%;
    max-width: 50%;
    padding: 0;
    top: 12px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media screen and (min-width: 320px) and (max-width: 479px) and (max-height: 650px) {
    // margin-top: 8rem;
  }

  @media screen and (min-width: 320px) and (max-width: 479px) and (min-height: 651px) and (max-height: 750px) {
    margin-top: 3rem;
  }

  @media screen and (min-width: 480px) and (max-width: 767px) and (max-height: 650px) {
    // margin-top: 20rem;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) and (max-height: 650px) {
    // margin-top: 15rem;
  }
`

export const LeftArrow = styled.img`
  margin-right: 1rem;
  position: relative;
  top: -1px;
`

export const JobPostTitle = styled.h1`
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  font-size: 34px !important;

  line-height: 2.31rem;
  letter-spacing: 0.06rem;
  color: ${props =>
    props.dark ? theme.colours.palette.textDark : theme.colours.palette.light};
  margin: ${props => (props.home ? '1rem 0 0' : '3.5rem 0 1.5rem')};
  order: 2;
  position: relative;
  top: -13px;

  br {
    // display: none;
  }

  ${props =>
    props.announcementheading &&
    css`
      font-size: 22px !important;
    `}

  ${media.tablet`
    font-size: 3rem;
    // line-height: 4rem;
    line-height: 1;
    top: -15px;
  `};

  ${media.desktop`
    font-size: 2.6rem;
    margin: 2rem 0 1.5rem 0;
    letter-spacing: 0.165rem;
    line-height: 1;
    order: 1;
    top: 0;
    
    br {
      display: inline-block;
    }
  `};

  ${media.large`
    font-size: 2.68rem;
  `};

  ${media.default`
    font-size: 3.18rem;
  `};

  ${media.widescreen`
    font-size: 4.18rem;
  `};
`
export const AnnouncementTitle = styled.h1`
  display: block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2rem;
  font-size: 22px !important;

  line-height: 2.31rem;
  letter-spacing: 0.06rem;
  color: ${props =>
    props.dark ? theme.colours.palette.textDark : theme.colours.palette.light};
  margin: ${props => (props.home ? '1rem 0 0' : '1rem 0 1.5rem')};
  order: 2;
  position: relative;
  top: -13px;

  br {
    // display: none;
  }

  ${props =>
    props.announcementheading &&
    css`
      font-size: 22px !important;
    `}

  ${media.tablet`
    font-size: 3rem;
    // line-height: 4rem;
    line-height: 1;
    top: -15px;
  `};

  ${media.desktop`
    font-size: 2.6rem;
    margin: 1.45rem 0 1.55rem 0;
    letter-spacing: 0.165rem;
    line-height: 1;
    order: 1;
    top: 0;
    
    br {
      display: inline-block;
    }
  `};

  ${media.large`
    font-size: 2.68rem;
  `};

  ${media.default`
    font-size: 3.18rem;
  `};

  ${media.widescreen`
    font-size: 4.18rem;
  `};

  @media screen and (max-width: 991px) {
    margin-top: 3.8rem;
  }
`
