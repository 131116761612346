import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const LayoutWrapper = styled.div`
  display: flex;
  min-height: ${({ notFullHeight }) => (notFullHeight ? 'unset' : '100vh')};
  flex-direction: column;
  position: relative;
  max-width: ${sc.MAX_WIDTH}px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${({ stokePostLayout }) =>
    stokePostLayout &&
    css`
      border-bottom: 1px solid #ededed;
    `}

  ${breakpoint.above('highDesktop')} {
    border-right: 1px solid ${sc.GRAY_BASE_HEX};
    border-left: 1px solid ${sc.GRAY_BASE_HEX};
  }

  ${({ minimal }) =>
    minimal &&
    css`
      min-height: 0;
    `}
  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none !important;
    `}
`

export default LayoutWrapper
