import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sc from 'constants/style'
import CookieBar from 'components/ui/cookieBar'
import CookieBarMobile from 'components/ui/cookieBarMobile'
import LayoutWrapper from 'components/layouts/baseLayout/LayoutWrapper'
import { breakpoint } from 'utils'

import BaseCss from 'style/base'
import TypographyCss from 'style/typography'
//import { HeaderHo } from '../../ho'
import { FooterLayout, HeaderHo } from '@stokr/components-library'
import { carrersJob } from 'pages/careers'
import Banner from 'components/banner/Banner'
import fetchDataPublic from 'api/fetch-data-public'
import parse from 'html-react-parser'

const MainWrapper = styled.div`
  flex: 1 1 auto;
`
const HeaderWrapper = styled.div`
  ${breakpoint.below('tablet')} {
    position: sticky;
    top: 0;
    z-index: 900;
    background: ${sc.WHITE_HEX};
    border-bottom: 1px solid ${sc.GRAY_BASE_HEX};
  }
`

const FooterWrapper = styled.div``

const CookieBarContainer = styled.div`
  .cookie-bar {
    padding: 0px 150px !important;

    @media screen and (max-width: 1120px) {
      padding: 0px 110px !important;
    }

    @media screen and (max-width: 1050px) {
      padding: 0px 80px !important;
    }

    @media screen and (max-width: 1000px) {
      padding: 0px 50px !important;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .cookie-bar-mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      padding: 10px 30px !important;
    }

    @media screen and (max-width: 450px) {
      display: block;
      padding: 0px 30px !important;
    }
  }

  .cookie-button-mobile {
    position: absolute !important;
    right: 15px !important;
    top: 15px !important;
  }
`

export class BaseLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }
  state = {
    activeMenu: null,
    banner: null,
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.fetchBanner()
  }
  handleMenuOutsideClick = () => {
    //onOutsideMenuClick hide the menu if shown
    this.setState({ activeMenu: false })
  }

  activateMenu = () => {
    this.setState({ activeMenu: null })
  }

  fetchBanner = async () => {
    try {
      const bannerContent = await fetchDataPublic('project-content/get', {
        filter: { type: 'banner', projectName: 'STOKR' },
      })

      if (bannerContent?.list) {
        this.setState((prev) => ({
          ...prev,
          banner: bannerContent.list[0]?.content,
        }))
      }
    } catch (error) {
      console.log('🚀 ~ error', error)
    }
  }

  render() {
    const { banner } = this.state
    const { notFullHeight, children, stokePostLayout, noFooter } = this.props
    return (
      <React.Fragment>
        <Helmet>
          <title></title>
        </Helmet>
        <BaseCss />
        <TypographyCss />

        <LayoutWrapper
          notFullHeight={notFullHeight}
          stokePostLayout={stokePostLayout}
        >
          {banner?.isBannerVisible && (
            <Banner
              message={parse(banner.bannerText)}
              fontSize={'11px'}
              textColor={'black'}
            ></Banner>
          )}
          <HeaderWrapper>
            <HeaderHo
              activeMenu={this.state.activeMenu}
              activateMenu={this.activateMenu}
              useRelativePathForMenu
              {...this.props}
            />
          </HeaderWrapper>
          <MainWrapper onClick={this.handleMenuOutsideClick}>
            {children}
          </MainWrapper>
        </LayoutWrapper>
        {!noFooter && <FooterLayout jobOpeningsNumber={carrersJob.length} />}
        <CookieBarContainer>
          <CookieBar />
          <CookieBarMobile />
        </CookieBarContainer>
      </React.Fragment>
    )
  }
}

export default BaseLayout
