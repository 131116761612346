/*  */
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'utils'
import Breakdown from 'components/ui/breakdown'
import * as sc from 'constants/style'
import Headline from 'components/ui/headline'
import RichText from 'components/ui/richText'
import Button from 'components/ui/button'
import FadeIn from 'components/ui/fadeIn'

const FadeInStyled = styled((props) => <FadeIn {...props} />)`
  width: 100%;
  ${Breakdown} {
    line-height: 1;
  }
`

const Inner = styled.div`
  padding: 0 12.5%;
  width: 100%;
  height: auto;
  @media screen and (max-width: 1280px) {
    padding: 0 8%;
  }
  ${breakpoint.below('mobile')} {
    padding: 0;
  }
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          transform: translate3d(0, 0px, 0);
          transition: 0.5s cubic-bezier(0.77, 0, 0.175, 1);
        `
      : css`
          opacity: 0;
          transform: translate3d(0, 4px, 0);
          transition: 0.2s cubic-bezier(0.77, 0, 0.175, 1);
        `}
`

const HeadlineWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 44px;
  ${breakpoint.below('tablet')} {
    margin-top: 25px;
    margin-bottom: 30px;
  }
  ${breakpoint.below('mobile')} {
    margin-top: 36px;
    margin-bottom: 30px;
  }
`

const RichTextWrapper = styled.div`
  margin-bottom: 52px;
  font-weight: 300;
  ${breakpoint.below('tablet')} {
    margin-bottom: 49px;
  }
  ${breakpoint.below('mobile')} {
    margin-bottom: 37px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;

  button {
    margin-right: 16px;

    &:hover {
      color: ${sc.WHITE_HEX};
    }
  }

  a:hover {
    opacity: 1;
  }

  a:visited {
    color: ${sc.WHITE_HEX};
  }

  @media screen and (max-width: 1280px) {
    //flex-direction: column;

    button,
    a {
      margin-right: 0;
    }

    button:not(:last-of-type),
    a:not(:last-of-type) {
      /* margin-bottom: 20px; */
    }
  }

  @media screen and (max-width: 1124px) {
    /* bottom: 20px; */
  }

  @media screen and (max-width: 991px) {
    bottom: 0;
  }

  @media screen and (max-width: 881px) {
    /* bottom: 20px; */
  }

  @media screen and (max-width: 767px) {
    bottom: 0;
  }
`

export class BreakdownPanel extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: true,
      slide: {},
    }
    this.content = React.createRef()
  }

  componentDidMount() {
    this.setState({ slide: this.props.slide })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.slide !== this.props.slide) {
      this.setVisibility()
    }
  }

  setVisibility = () => {
    this.setState({ isVisible: false })
    setTimeout(() => {
      this.setState({ isVisible: true, slide: this.props.slide })
    }, 500)
  }

  render() {
    const { isVisible, slide } = this.state

    return (
      <FadeInStyled>
        <Inner isVisible={isVisible} ref={this.content}>
          <FadeIn delay={40}>
            <Breakdown>{slide.slug}</Breakdown>
          </FadeIn>
          <HeadlineWrapper>
            {Array.isArray(slide.slideTitle) ? (
              slide.slideTitle.map((sentence) => (
                <Headline as="h2" key={sentence} noMargin>
                  {sentence}
                </Headline>
              ))
            ) : (
              <Headline as="h2">{slide.slideTitle}</Headline>
            )}
            {slide.slideTitleThin && (
              <Headline as="h2" isThin>
                {slide.slideTitleThin}
              </Headline>
            )}
            {/* </Headline> */}
          </HeadlineWrapper>
          <RichTextWrapper>
            <RichText>{slide.slideContent}</RichText>
          </RichTextWrapper>
          <ButtonsContainer>
            <Button
              isHero
              onClick={() => {
                slide.openInNewTab
                  ? window.open(slide.slideBtnUrl, '_BLANK')
                  : (window.location.href = slide.slideBtnUrl)
              }}
            >
              {slide.slideBtnText}
            </Button>
          </ButtonsContainer>
        </Inner>
      </FadeInStyled>
    )
  }
}

export default BreakdownPanel
