import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/ui/button'
import Info from 'components/ui/info'
import EmojiButton from 'components/ui/comments/EmojiButton'
import { breakpoint } from 'utils'
import { ContentInfo, MessageWrapper, MessageContent } from './CommentCreateNew'

/**
 * Enums and configs
 */
const config = {
  maxLength: 280,
}

const StyledMessageWrapper = styled(MessageWrapper)`
  display: block;
`

const MessageContainer = styled.div``

const MessageContentWrapper = styled.div`
  padding-top: 3px;
`

const MessageFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 32px;

  > *:first-child {
    margin-left: 0;
    margin-right: auto;
  }

  ${breakpoint.below('mobile')} {
    padding: 0 24px;
    flex-wrap: wrap;
  }
`

const StyledCharacterCount = styled.div`
  display: flex;
  align-items: center;
  color: #c6c2c2;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 1.65px;
  text-transform: uppercase;
  padding: 16px 32px 22px;
  width: 100%;
  position: relative;

  img {
    margin-right: 7px;
  }

  ${breakpoint.below('mobile')} {
    &::after {
      content: '';
      display: block;
      width: calc(100% + 24px + 24px);
      position: absolute;
      left: 0;
      top: 100%;
      margin: 0 -24px;
      height: 1px;
      background: #e1e1e1;
    }
  }

  ${breakpoint.below('mobile')} {
    padding: 8px 0 24px;
    margin: 0;
  }
`

const CharacterCount = ({ showEmoji, length, maxLength }) => (
  <StyledCharacterCount>
    {showEmoji && <EmojiButton />}
    {length}/{maxLength} characters
  </StyledCharacterCount>
)

const FooterButtons = styled.div`
  display: flex;

  ${breakpoint.below('mobile')} {
    width: 100%;
    justify-content: center;
    margin: 30px auto;
  }
`

export class CommentReplyBlock extends PureComponent {
  static propTypes = {
    showEmoji: PropTypes.bool,
  }

  static defaultProps = {
    showEmoji: true,
  }

  state = {
    content: '',
  }

  onContentChange = event =>
    this.setState({
      content: event.target.value.substr(0, config.maxLength), // Limit length
    })

  render() {
    const { showEmoji } = this.props
    const { content } = this.state

    return (
      <StyledMessageWrapper>
        <MessageContainer>
          <MessageContentWrapper>
            <MessageContent
              value={content}
              onChange={this.onContentChange}
              placeholder="Write a reply to the comment"
              maxLength={config.maxLength}
            />
            <MessageFooter>
              <CharacterCount
                length={content.length}
                maxLength={config.maxLength}
                showEmoji={showEmoji}
              />
              <FooterButtons>
                <Button textOnly>Cancel</Button>
                <Button>Reply</Button>
              </FooterButtons>
            </MessageFooter>
            <ContentInfo>
              <Info content="Lorem ipsum dolor sit amet consectetur edipiscing elit" />
            </ContentInfo>
          </MessageContentWrapper>
        </MessageContainer>
      </StyledMessageWrapper>
    )
  }
}
