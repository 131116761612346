import React from 'react'
import { Link } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

const barStyle = {
  alignItems: 'center',
  fontSize: '0.8rem',
  fontWeight: 300,
  textAlign: 'center',
}

const buttonStyle = {
  background: 'transparent',
  color: 'white',
  cursor: 'pointer',
  fontSize: '1.2rem',
  fontWeight: 700,
  lineHeight: 1,
  margin: 0,
  position: 'relative',
  padding: 0,
  right: '20px',
}

const d = new Date()
const exdays = 365
d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
const expires = 'expires=' + d.toUTCString() + ';'
const cookieValue = 'termsaccepted=true; ' + expires

export const CookieBar = () => (
  <CookieConsent
    location="bottom"
    buttonText="&times;"
    cookieName="termsaccepted"
    style={barStyle}
    buttonStyle={buttonStyle}
    expires={365}
    cookieValue={cookieValue}
    contentClasses="cookie-bar"
    buttonClasses="cookie-button"
  >
    Welcome to STOKR. By clicking on a link, image or other content on our website, you accept the <Link to="/legal"><span style={{ color: '#84fb6f' }}>general terms</span></Link> of use of our website and the placement of cookies. If you would like to learn how to disable cookies, please read our <Link to="/cookie-policy"><span style={{ color: '#84fb6f' }}>cookie policy</span></Link>.
  </CookieConsent>
)

export default CookieBar
