import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import BlockTowerContent from 'components/ui/block/BlockTowerContent';

const BlockTowerTitle = styled.div`
  position: relative;

  ${BlockTowerContent} {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    margin-top: 0;
  }

  &::before {
    content: '';
    left: 0;
    position: absolute;
    height: 100%;
    width: 8px;
    background: ${sc.BLUE_BASE_RELEASED_HEX};
    display: ${({ noBorder }) => noBorder && 'none'};

    ${breakpoint.below('desktop')} {
      left: 91.5%;
      margin-left: -100%;
    }
    ${breakpoint.below('mobile')} {
      left: 0;
      margin-left: 0;
    }

    ${({ leftBarColor }) =>
    leftBarColor === 'ventures' &&
      css`
        background: #0050ca;
      `}

    ${({ leftBarColor }) =>
    leftBarColor === 'investors' &&
      css`
        background: #ee220d;
      `}
  }
`

export default BlockTowerTitle
