/*  */
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from 'config/media'
import theme from 'config/theme'

export const ContentText = styled.p`
  font-size: 0.81rem;
  letter-spacing: normal;
  line-height: 1.6rem;
  position: relative;
  padding-bottom: 10px;

  /* color: ${props =>
    props.dark
      ? theme.colours.palette.textDark
      : theme.colours.palette.light}; */

  color: #202020;
  font-weight: ${props => {
    if (props.bold) {
      return '800'
    }
    if (props.light) {
      return '200'
    }
    if (props.regular) {
      return '400'
    }
    return '200'
  }};
  ${media.tablet`
      font-size: 0.75rem;
      line-height: 1.5rem;
    `};

  ${media.desktop`
      font-size: 0.75rem;
      line-height: 1.5rem;
    `};

  ${media.large`
      font-size: 0.75rem;
      line-height: 1.5rem;
    `};

  ${media.default`
      font-size: 0.75rem;
      line-height: 1.5rem;
    `};

    font-size: 16px !important;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5 !important;
    letter-spacing: 0.6px;

    a {
      word-break: break-all !important;
    }
`

export const ContentSection = styled.div`
  // margin-bottom: 3rem;
  margin-bottom: 1rem;
`

export const UppercaseText = styled.p`
  position: relative;
  margin: 0;
  font-size: ${props => {
    if (props.hideDesktop) {
      return '0.62rem;'
    }
    return '0.75rem;'
  }};
  width: 100%;
  line-height: 1.31rem;
  letter-spacing: 0.11rem;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: ${props => {
    if (props.nomargin) {
      return '0'
    }
    return '1rem'
  }};

  font-weight: ${props => {
    if (props.bold) {
      return '800'
    }
    if (props.light) {
      return '200'
    }
    if (props.regular) {
      return '400'
    }
    if (props.demi) {
      return '600'
    }
    return '600'
  }};

  order: 2;

  ${media.tablet`
    font-size: .8rem;
  `};

  ${media.desktop`
    order: 1 !important;
    display: ${
      props => {
        if (props.hideDesktop) {
          /* -line */
          return 'none' /* -line */
        } /* -line */
        return 'inline-block' /* -line */
      } /* -line */
    }; /* -line */
  `};

  &:first-of-type {
    top: -2px;

    ${media.desktop`
      top: -3px;
    `};
  }

  &:nth-of-type(2) {
    top: -5px;

    ${media.tablet`
      top: -6px;
    `};

    ${media.desktop`
      top: -7px;
    `};
  }
`

export const ContentInnerLink = styled(Link)`
  &&& {
    color: #000;
    padding: 0;
    margin: 0 3rem 0 0;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    span {
      white-space: nowrap;
      font-weight: 700;
      font-size: 0.75rem;
      letter-spacing: 1px;
    }

    &::after {
      display: none;
    }
    & i {
      margin-left: 0.5rem;
    }
    & img {
      max-width: 1rem;
    }
  }
`
