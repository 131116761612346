import { ProjectStatus, ProjectTypes, ProjectStates } from 'constants/enums'

export const generateProjectState = (project) => {
  const { status, isOpen, hasClosed, type } = project

  //"on hold" status is the same for all projects
  if (status === ProjectStatus.ON_HOLD) {
    return ProjectStates.ON_HOLD
  }

  //if its type tranches we just check the tranche state
  if (type === ProjectTypes.TRANCHES) {
    return getTrancheState(project)
  } else {
    if (isOpen) {
      return type === ProjectTypes.FUND
        ? ProjectStates.OPEN
        : ProjectStates.LIVE
    } else if (hasClosed) {
      //const investmentTargetReached = project.totalSold >= project.totalSoftCap
      return ProjectStates.CLOSED
    } else return ProjectStates.UPCOMING
  }
}

export const getTrancheState = (project) => {
  var currentTranche = project.sales?.find((x) => x.isVariable)

  if (project.isRedeemable && project.sales) {
    currentTranche = project.sales?.find((sale) => sale.isRedemption)
  }

  const today = Date.now()

  if (!currentTranche) return ProjectStates.UPCOMING

  const { isRedemption, maturityDate, startDate, endDate, description } =
    currentTranche
  const maturityTime = new Date(maturityDate).getTime()
  const startTime = new Date(startDate).getTime()
  const endTime = new Date(endDate).getTime()

  if (isRedemption) {
    if (!maturityDate || today < maturityTime) {
      return `${description} ${ProjectStates.UPCOMING}`
    } else {
      return description
    }
  } else {
    if (!startDate || today < startTime) {
      return `${description} ${ProjectStates.UPCOMING}`
    } else if (!endDate || today < endTime) {
      return `${description} ${ProjectStates.LIVE}`
    } else if (today >= endTime) {
      return `${description} ${ProjectStates.CLOSED}`
    } else {
      console.error('Unexpected state for tranche:', currentTranche)
      return `${description} ${ProjectStates.UPCOMING}`
    }
  }
}
