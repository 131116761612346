import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Slick from 'react-slick'

import SlickOptions from 'components/ui/slider/SlickOptions';
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

import SliderArrow from 'components/ui/slider/SliderArrow';
import StyledDotsContainer from 'components/ui/slider/StyledDotsContainer';
import StyledDots from 'components/ui/slider/StyledDots';

const StyledSlick = styled.div`
  position: relative;

  /* margin: 4rem 0; */
  margin: ${({ isParkinGo }) => (isParkinGo ? '0' : '4rem 0')};

  ${breakpoint.below('tablet')} {
    margin: 3rem 0;
  }
  ${breakpoint.below('mobile')} {
    margin: 2rem 0;
  }

  .slick-track,
  .slick-list,
  .slick-track div {
    height: 100%;
  }

  .slick-slide {
    transition: opacity 0.5s ease-in;
    opacity: 0.75;
    padding: 0 24px;
    box-sizing: border-box;

    &.slick-active {
      opacity: 1;
      transition: opacity 0.2s ease;
    }

    ${breakpoint.below('desktop')} {
      padding: 0;
    }
  }
  .slick-active ~ .slick-slide {
    opacity: 0.75;
  }

  .slick-dots {
    right: 110px;
    bottom: 64px;
    width: ${({ isParkinGo }) => isParkinGo && 'auto'};

    ${breakpoint.below('mobile')} {
      right: 0;
      bottom: 50px;
      padding: ${({ isParkinGo }) => isParkinGo && '12px 16px;'};
    }
  }

  div {
    outline: none;
  }

  .slick-button-prev {
    top: 0;
    right: 436px;

    ${breakpoint.below('tablet')} {
      right: calc(40% - 64px);
    }
    ${breakpoint.below('mobile')} {
      right: 64px;
    }
  }

  .slick-button-next {
    right: 372px;
    top: 0;

    ${breakpoint.below('tablet')} {
      right: calc(40% - 64px - 64px);
    }
    ${breakpoint.below('mobile')} {
      right: 0;
    }
  }

  /* Background under buttons */
  &::before {
    background: linear-gradient(
      to bottom,
      ${sc.GRAY_BASE_HEX} 0%,
      ${sc.WHITE_HEX} 100%
    );
    width: 500px;
    height: 500px;
    max-height: 100%;
    right: 0;
    content: '';
    position: absolute;
    z-index: -1;

    ${breakpoint.below('tablet')} {
      width: 40%;
    }
    ${breakpoint.below('mobile')} {
      left: -200px;
      width: 1000px;
      width: ${({ isParkinGo }) => isParkinGo && 'unset'};
    }
  }

  /* Blue background on the left */
  &::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
    z-index: -1;
    background: rgb(0, 75, 183);

    ${breakpoint.below('tablet')} {
      width: 150px;
    }
    ${breakpoint.below('mobile')} {
      width: 70px;
      height: 54.75px;
      bottom: 0;
      top: auto;
    }
  }
  ${props => props.customStyle}
`

export class SliderAlt extends PureComponent {
  static defaultProps = {
    responsive: [],
  }

  slickRef = React.createRef()

  initialTouchPos = null

  touchPos = null

  sliderSettings = {
    centerMode: true,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: SlickOptions.interval,
    speed: SlickOptions.transitionLength,
    centerPadding: '60px',
    cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    arrows: true,
    nextArrow: (
      <SliderArrow
        arrowStyle={this.props.arrowStyle}
        position={this.props.positionNext}
      />
    ),
    prevArrow: (
      <SliderArrow
        arrowStyle={this.props.arrowStyle}
        left
        position={this.props.positionPrev}
      />
    ),
    appendDots: dots => (
      <StyledDotsContainer dotsStyle={this.props.dotsStyle}>
        {dots}
      </StyledDotsContainer>
    ),
    customPaging: () => <StyledDots />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: 0,
        },
      },
      ...this.props.responsive,
    ],
  }

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart)
    document.addEventListener('touchend', this.handleTouchEnd)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.slickRef.slickGoTo(0)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchStart)
    document.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleTouchStart = event => {
    this.initialTouchPos = event.touches[0].clientX

    if (this.slickRef.current && this.slickRef.current.contains(event.target)) {
      window.addEventListener('touchmove', this.handleTouchMove, {
        passive: false,
      }) // Need to disable passive for Chrome 54+
    }
  }

  handleTouchMove = event => {
    const touchPos = event.touches[0].clientX
    this.touchPos = touchPos

    if (Math.abs(touchPos - this.initialTouchPos) > 5) {
      event.preventDefault()
    }
  }

  handleTouchEnd = () => {
    window.removeEventListener('touchmove', this.handleTouchMove)
  }

  render() {
    const {
      customStyle,
      children,
      arrowStyle,
      beforeChange,
      customSettings,
      isParkinGo,
    } = this.props

    const slides = React.Children.map(
      children,
      (child, index) =>
        // -next-line implicit-arrow-linebreak
        React.cloneElement(child, {
          onClick: () => {
            if (this.slickRef) {
              this.slickRef.slickGoTo(index)
            }
          },
        }),
      // -next-line function-paren-newline
    )
    return (
      <StyledSlick
        customStyle={customStyle}
        ref={this.slickRef}
        isParkinGo={isParkinGo}
      >
        <Slick
          beforeChange={beforeChange}
          arrowStyle={arrowStyle}
          ref={slickRef => {
            this.slickRef = slickRef
          }}
          {...{ ...this.sliderSettings, ...customSettings }}
        >
          {slides}
        </Slick>
      </StyledSlick>
    )
  }
}

export default SliderAlt
