import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import EmojiButton from 'components/ui/comments/EmojiButton';
import AvatarAndName from 'components/ui/comments/AvatarAndName';

import BackgroundPattern from 'components/ui/backgroundPattern';
import Button from 'components/ui/button';
import Info from 'components/ui/info';

/**
 * Enums and configs
 */
const config = {
  maxLength: 280,
}

/**
 * Styles
 */
const StyledBackgroundPattern = styled(BackgroundPattern)`
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background: white;
    z-index: -1;

    ${breakpoint.below('tablet')} {
      width: 35%;
    }

    ${breakpoint.below('mobile')} {
      display: none;
    }
  }

  &::after {
    z-index: -2;
  }
`

const SidebarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SidebarSubmit = styled(Button)`
  margin: 0 auto;

  ${breakpoint.below('mobile')} {
    padding: 0 38px;
    margin-left: 4px;
    margin-right: 4px;
  }
`

const MessageContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-top: 2px;
`

const Section = styled.div`
  margin: 0 -65px;
  width: calc(100% + 65px + 65px);

  ${breakpoint.below('mobile')} {
    margin: 0 -32px;
    width: calc(100% + 32px + 32px);
  }
`

const Container = styled.div`
  padding: 64px 65px 96px;

  ${breakpoint.below('mobile')} {
    padding: 32px 32px 56px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    border: solid transparent;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 0 300px 450px 0;
    border-color: transparent ${sc.WHITE_HEX} transparent transparent;
    transform: scaleX(-1);
    z-index: -1;

    ${breakpoint.below('tablet')} {
      left: 35%;
    }
    ${breakpoint.below('mobile')} {
      left: 0;
    }
  }
`

export const MessageWrapper = styled.div`
  display: flex;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  position: relative;

  ${breakpoint.below('mobile')} {
    display: block;
  }
`

const MessageContainer = styled.div`
  flex: 1 0 75%;
  display: flex;
  flex-direction: column;

  ${breakpoint.below('tablet')} {
    flex: 1 0 68%;
  }
`

export const MessageContent = styled.textarea`
  display: block;
  flex: 1 0 auto;
  width: calc(100% - 4px); /* Scrollbar adjustment */
  padding: 25px 73px 10px 32px;
  border: none;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 1.4;
  font-weight: 300;
  color: ${sc.BASE_COLOR_HEX};
  resize: none;
  min-height: 90px;

  ${breakpoint.below('tablet')} {
    height: 170px;
    padding-right: 64px;
  }
  ${breakpoint.below('mobile')} {
    padding-right: 48px;
    padding-left: 24px;
    font-size: 14px;
    letter-spacing: 0.53px;
    line-height: 20px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: ${sc.RED_BASE_HEX};
    border-radius: 4px;
  }
`

const StyledCharacterCount = styled.div`
  display: flex;
  align-items: center;
  color: #c6c2c2;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 1.65px;
  text-transform: uppercase;
  padding: 16px 32px 22px;
`

export const CharacterCount = ({ showEmoji, length, maxLength }) => (
  <StyledCharacterCount>
    {showEmoji && <EmojiButton />}
    {length}/{maxLength} characters
  </StyledCharacterCount>
)

const MessageSidebar = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1 25%;
  max-width: 190px;
  border-left: 1px solid #e1e1e1;
  padding: 32px 19px;
  text-align: center;

  ${breakpoint.below('tablet')} {
    flex: 1 1 32%;
  }

  ${breakpoint.below('mobile')} {
    border-left: none;
    border-top: 1px solid #e1e1e1;
    width: 100%;
    max-width: none;
  }
`

export const ContentInfo = styled.div`
  position: absolute;
  right: 32px;
  top: 28px;
`

/**
 * Component declaration
 */
export class CommentCreateNew extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    picture: PropTypes.string,
    showEmoji: PropTypes.bool,
  }

  static defaultProps = {
    showEmoji: true,
  }

  state = {
    content:
      'Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are growing right along. An illustration, image possible. Originality cannot help flowing from the pencil of the good artist.',
  }

  onContentChange = event =>
    this.setState({
      content: event.target.value.substr(0, config.maxLength), // Limit length
    })

  render() {
    const { name, picture, showEmoji } = this.props
    const { content } = this.state

    return (
      <Section>
        <StyledBackgroundPattern>
          <Container>
            <AvatarAndName picture={picture} name={name} />
            <MessageWrapper>
              <MessageContainer>
                <MessageContentWrapper>
                  <MessageContent
                    value={content}
                    onChange={this.onContentChange}
                    placeholder="Write a comment"
                    maxLength={config.maxLength}
                  />
                  <CharacterCount
                    length={content.length}
                    maxLength={config.maxLength}
                    showEmoji={showEmoji}
                  />
                  <ContentInfo>
                    <Info content="Lorem ipsum dolor sit amet consectetur edipiscing elit" />
                  </ContentInfo>
                </MessageContentWrapper>
              </MessageContainer>
              <MessageSidebar>
                <SidebarContainer>
                  <SidebarSubmit>Post</SidebarSubmit>
                </SidebarContainer>
              </MessageSidebar>
            </MessageWrapper>
          </Container>
        </StyledBackgroundPattern>
      </Section>
    )
  }
}
