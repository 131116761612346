import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const checkImg = require('./img/check.svg')

const StyledWrapper = styled.div`
  display: inline-block;
`

const StyledInput = styled.input`
  display: none;
`

const StyledLabel = styled.label`
  display: block;
  position: relative;
  font-size: 9px;
  line-height: 16px;
  padding-left: 24px; /* 16px size + 8px padding */
  cursor: pointer;
  user-select: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    transition: all 0.25s;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    top: 4px;
    width: 8px;
    height: 8px;
    background: white url(${checkImg}) center no-repeat;
    transition: all 0.25s;
    opacity: ${({ value }) => (value ? 1 : 0)};
  }
`

export class Checkbox extends PureComponent {
  static propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
  }

  render() {
    const { value, onChange, label } = this.props

    return (
      <StyledWrapper>
        <StyledLabel value={value} onChange={onChange}>
          <StyledInput type="checkbox" />
          {label}
        </StyledLabel>
      </StyledWrapper>
    )
  }
}

export default Checkbox
