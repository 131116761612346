import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const BlockTower = styled.div`
  margin-left: calc(100% / 16 * 2);
  margin-right: calc(100% / 16 * 3);
  width: calc(100% / 16 * 11);
  border-left: 1px solid ${sc.GRAY_BASE_HEX};
  padding-top: ${sc.BASE_MARGIN * 6}px;
  padding-bottom: ${sc.BASE_MARGIN * 6}px;

  ${breakpoint.below('desktop')} {
    margin-right: calc(100% / 16 * 2);
    margin-left: calc(100% / 16 * 1);
    padding-left: calc(100% / 16 * 1);
    width: calc(100% / 16 * 13);

    ${({ hiw }) =>
      hiw === true &&
      css`
        margin-left: 64px;
        margin-right: 64px;
      `}
  }

  ${breakpoint.below('mobile')} {
    padding: ${sc.BASE_MARGIN * 3.5}px 0 ${sc.BASE_MARGIN * 4.5}px;
    margin: 0;
    border-left: none;
    width: 100%;
  }

  @media screen and (min-width: 1700px) {
    margin: 0 auto;
  }
`

export default BlockTower
