/*  */
import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'components/ui/button'
import Icon from 'components/ui/icon'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import linkSVG from 'images/link.svg'
import { LinkIcon } from 'components/ui/icons'
import SocialUrlsPT from 'components/ui/socialUrls/SocialUrlsPT'

const Outer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: ${sc.WHITE_HEX};
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;

  ${({ inFooter }) =>
    inFooter &&
    css`
      /* width: 112px; */
      width: 224px;
    `}

  ${({ isParkinGo }) =>
    isParkinGo &&
    css`
      @media screen and (max-width: 1019px) {
        flex-wrap: nowrap;
      }

      @media screen and (max-width: 550px) {
        flex-wrap: wrap;
      }
    `}

  ${breakpoint.below('tablet')} {
    width: auto;
  }

  &:empty {
    display: none;
  }

  > div {
    margin-right: ${sc.BASE_MARGIN}px;
    margin-bottom: ${sc.BASE_MARGIN}px;

    ${props =>
      props.smallMargin &&
      `
      margin-right:8px;
    `}

    ${({ isParkinGo }) =>
      isParkinGo &&
      css`
        margin-bottom: 8px;
        margin-top: 8px;
      `}

    ${breakpoint.below('mobile')} {
      /* margin-right: ${sc.BASE_MARGIN / 4}px; */
    }

    /* @media screen and (max-width: 991px) {
      margin-left: 8px;
      margin-right: 8px;
    } */
    }
  }

  ${({ small }) =>
    small &&
    css`
      ${Button} {
        width: 32px;
        height: 32px;
      }
    `}
`

const SocialUrlsCM = ({
  inFooter,
  small,
  linkedIn,
  instagram,
  twitter,
  telegram,
  facebook,
  youtube,
  reddit,
  //medium,
  website,
  color,
  noborder,
  className,
  isParkinGo,
  fillRed,
  smallMargin,
}) => (
  <Outer
    isParkinGo={isParkinGo}
    inFooter={inFooter}
    small={small}
    className={className}
    smallMargin={smallMargin}
  >
    {' '}
    {/* -line */}
    {linkedIn && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={linkedIn}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="LinkedIn" />
        </Button>
      </div>
    )}
    {instagram && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="Instagram" />
        </Button>
      </div>
    )}
    {twitter && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={twitter}
          target="_blank"
          rel="noopener noreferrer"
          fillRed={fillRed}
        >
          <Icon name="Twitter" />
        </Button>
      </div>
    )}
    {facebook && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="Facebook" />
        </Button>
      </div>
    )}
    {telegram && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={telegram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="Telegram" />
        </Button>
      </div>
    )}
    {/* {medium && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={medium}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="Medium" />
        </Button>
      </div>
    )} */}
    {youtube && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={youtube}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="Youtube" />
        </Button>
      </div>
    )}
    {reddit && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={reddit}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="Reddit" />
        </Button>
      </div>
    )}
    {website && (
      <div>
        <Button
          icononly
          noborder={noborder}
          color={color}
          as="a"
          href={website}
          target="_blank"
          rel="noopener noreferrer"
          fillRed
        >
          <LinkIcon src={linkSVG} alt="External link" />
        </Button>
      </div>
    )}
  </Outer>
)

SocialUrlsCM.propTypes = {
  ...SocialUrlsPT,
}

SocialUrlsCM.defaultProp = {
  noborder: true,
}

export const SocialUrls = styled(SocialUrlsCM)``

export default SocialUrls
