import styled, { css } from 'styled-components'
import * as sc from 'constants/style'

const alignHelper = ({ align = 'left' }) => css`
  text-align: ${align};
`

const firstHelper = ({ first }) =>
  first &&
  css`
    margin-top: 0;
  `

export const Headline = styled.h1`
  ${firstHelper};
  ${alignHelper};
  color: ${({ active }) => active && sc.WHITE_HEX};
  margin: ${({ noMargin }) => noMargin && 0};
  transition: text-align 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  font-weight: ${({ isThin }) => isThin && '300'};
  margin-top: ${({ isThin }) => isThin && '9px'};

  font-size: 34px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  ${({ stoThumbnailHeading }) =>
    stoThumbnailHeading &&
    css`
      font-size: 22px;
      letter-spacing: 0.8px;
    `}

  ${({ inactive }) =>
    inactive &&
    css`
      filter: blur(4px);
      opacity: 0.3;
    `}

  ${({ sidebar101heading }) =>
    sidebar101heading &&
    css`
      // line-height: 1.27 !important;
      // font-size: 18px;
      // letter-spacing: 1.4px !important;
    `}

      ${({ stokrCapitalHeading }) =>
        stokrCapitalHeading &&
        css`
          margin: 12px 0 24px 0;
        `}

  @media screen and (min-width: 481px) and (max-width: 1100px) {
    font-size: ${({ stokepostheading }) => stokepostheading && '24px'};
  }

  @media screen and (min-width: 351px) and (max-width: 480px) {
    font-size: ${({ stokepostheading }) => stokepostheading && '18px'};
  }

  @media screen and (max-width: 350px) {
    font-size: ${({ stokepostheading }) => stokepostheading && '14px'};
  }

  @media screen and (max-width: 767px) {
    font-size: 22px;

    ${({ stokrCapitalHeading }) =>
      stokrCapitalHeading &&
      css`
        margin: 16px 0 24px 0;
      `}
  }
`

export default Headline
