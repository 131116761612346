import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Breakdown from 'components/ui/breakdown'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import { TimelineHeadline } from './commons'

const borderWhite = require('./img/oval_white.svg')
const borderRed = require('./img/oval_red.svg')

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 383px;
  height: 383px;
  margin: 0 auto;
  padding: 32px;
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${borderRed});
  text-align: center;
  border-radius: 50%;

  ${breakpoint.below('mobile')} {
    width: 288px;
    height: 288px;
  }
`

const DaysAgo = styled(Breakdown)`
  margin-bottom: 24px;
  font-weight: 700;

  ${breakpoint.below('mobile')} {
    margin-bottom: 16px;
  }
`

const CapReached = styled.div`
  display: inline-block;
  padding: 3px 12px;
  background: ${sc.RED_BASE_HEX};
  margin: 0 auto;
  color: white;
  font-weight: 700;
`

const TimelineSubtitle = styled.div`
  display: block;
  padding: 0 22px;

  ${breakpoint.below('mobile')} {
    font-size: 14px;
    padding: 0 32px;
    line-height: 20px;
  }
`

const Headline = styled(TimelineHeadline)`
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1.24px;
  line-height: 32px;
  font-size: 28px;

  ${breakpoint.below('mobile')} {
    margin: 16px auto;
    font-size: 22px;
    line-height: 24px;
  }
`

const Section = styled.div`
  display: block;
  width: calc(100% + 65px + 65px);
  margin: 0 -65px;
  padding: 65px 0;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    width: 1px;
    background: #f0f0f0;
  }
  &::before {
    top: 0;
    height: 34px;
  }
  &::after {
    bottom: 0;
    height: 52px;
  }

  ${({ hardCap }) =>
    hardCap &&
    css`
      background-color: ${sc.RED_BASE_HEX};

      &::before,
      &::after {
        background: ${sc.RED_BASE_RELEASE_HEX};
      }
      ${Container} {
        background-image: url(${borderWhite});
      }
      ${DaysAgo} {
        color: white;
      }
      ${CapReached} {
        background: white;
        color: ${sc.BASE_COLOR_HEX};
      }
      ${Headline} {
        color: white;
      }
      ${TimelineSubtitle} {
        color: white;
      }
    `}
`

export class TimelineCapReached extends PureComponent {
  static propTypes = {
    hardCap: PropTypes.bool,
    daysAgo: PropTypes.number,
    subtitle: PropTypes.node,
    title: PropTypes.node,
  }

  static defaultProps = {
    daysAgo: 0,
  }

  render() {
    const { hardCap, daysAgo, title, subtitle } = this.props

    return (
      <Section hardCap={hardCap}>
        <Container>
          <DaysAgo>{daysAgo} days ago</DaysAgo>
          <CapReached>
            <Breakdown>{hardCap ? 'Hard cap' : 'Soft cap'} reached</Breakdown>
          </CapReached>
          <Headline>{title}</Headline>
          <TimelineSubtitle>{subtitle}</TimelineSubtitle>
        </Container>
      </Section>
    )
  }
}

export default TimelineCapReached
