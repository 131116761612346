import React from 'react'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'

const Name = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${sc.BASE_MARGIN}px;

  ${({ small }) =>
    small &&
    css`
      font-size: 14px;
    `}
`

const Picture = styled.img`
  display: inline-block;
  width: 48px;
  max-height: 48px;
  border-radius: 50%;
  margin-right: 14px;

  ${({ small }) =>
    small &&
    css`
      width: 32px;
      max-height: 32px;
    `}
`

const AvatarAndName = ({ small, picture, name }) => (
  <Name small={small}>
    {picture && <Picture small={small} src={picture} alt={name} />}
    {name}
  </Name>
)

export default AvatarAndName;
