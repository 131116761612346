import styled from 'styled-components'
import theme from 'config/theme'

export const List = styled.ul`
  list-style-type: disclosure-closed;
  list-style: none;
  margin: 0;
  padding: 0;

  ${(props) =>
    props.subList &&
    `
    &:first-child {
      margin-top:1rem;
    }
    ${ListItem}:before {
      border:1px solid #0050ca;
      background:transparent;
    }
  `}
`

export const ListItem = styled.li`
  padding: 0 0 0 2rem;
  color: ${theme.colours.palette.textDark};
  font-size: 0.75rem;
  letter-spacing: normal;
  line-height: 1.31rem;
  font-weight: 200;
  margin-bottom: 1rem;
  position: relative;
  /* top: 1px; */

  font-size: 16px !important;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5 !important;
  letter-spacing: 0.6px;

  a {
    word-break: break-all !important;
  }

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #0050ca;
    border-radius: 100px;
    left: 0;
    top: 0px;
    margin-top: 0.35rem;

    @media screen and (min-width: 1440px) {
      top: -1px;
    }
  }
`

List.Item = ListItem
