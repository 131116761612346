import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { breakpoint } from 'utils'
import * as sc from 'constants/style'
import Slider from 'components/ui/slider'

const Container = styled.div`
  width: 100%;
  min-height: 632px;
  box-sizing: border-box;
  /* border-bottom: 8px solid ${sc.BLUE_BASE_HEX}; */

  ${breakpoint.below('mobile')} {
    border-bottom: none;
  }
`

const sliderStyle = css`
  .slick-slide {
    background: white;
  }

  .slick-slide::before,
  .slick-slide::after {
    background: white;
  }

  .slick-slider {
    ${breakpoint.below('tablet')} {
      min-height: 632px;
    }
  }
  .slick-list {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .slick-dots {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 40px;
    bottom: 0;
    display: flex;
    justify-content: center;

    /* li div:before {
      background: ${sc.GRAY_BASE_HEX} !important;
    } */

    /* .slick-active div:before {
      background: ${sc.BLUE_BASE_HEX} !important;
    } */

    li {
      div {
        &::before {
          background: rgba(0, 0, 0, 0.1);
          border: solid 1px ${sc.WHITE_HEX};
        }
      }
    }

    li.slick-active {
      div {
        &::before {
          background: ${sc.WHITE_HEX};
          opacity: 1;
        }
      }
    }
  }
`

export class HowItWorksSlider extends PureComponent {
  state = { currentSlide: 0 }

  handleSlideChange = (index) => {
    this.setState({
      currentSlide: index,
    })
  }

  render() {
    const { items, onClick, onMouseEnter, onMouseLeave, children } = this.props
    const { currentSlide } = this.state
    // get the middle index
    const middle = Math.floor((items.length - 1) / 2)
    const nextItems = [...items]
    // remove it from the array
    nextItems.splice(middle, 1)
    // and create a new array with the middle item at the first index
    const rearrangedItems = [items[middle], ...nextItems]
    return (
      <Container>
        <Slider
          customStyle={sliderStyle}
          afterChange={this.handleSlideChange}
          arrows={false}
          autoplay
          infinite
        >
          {rearrangedItems.map((Item, index) => (
            <Item
              isMobile
              key={index}
              index={index}
              currentHovered={currentSlide}
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              isVisible={currentSlide === index}
            >
              {children}
            </Item>
          ))}
        </Slider>
      </Container>
    )
  }
}

export default HowItWorksSlider
