import styled from 'styled-components'
import * as sc from 'constants/style'

const StyledDots = styled.div`
  && {
    padding: ${sc.BASE_MARGIN / 2}px;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 8px !important;
      height: 8px !important;
      background: ${sc.GRAY_BASE_HEX};
      border-radius: 50%;
      transition: background-color 0.3s, opacity 0.3s;
    }

    &:hover::before,
    &:focus::before {
      opacity: 0.6;
    }

    .slick-active &::before {
      background: ${sc.RED_BASE_HEX};
    }
  }
`

export default StyledDots
