import React, { PureComponent } from 'react'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import { breakpoint } from 'utils'
import Breakdown from 'components/ui/breakdown'
import Headline from 'components/ui/headline'

const Container = styled.div`
  width: 100%;
  min-height: 488px;
  display: flex;
  ${breakpoint.below('mobile')} {
    display: block;
  }
  overflow: hidden;
`

const Panel = styled.div`
  flex: 0 0 50%;
  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '50px')});
  transition: all 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0.5')};
  transition-delay: ${({ delay }) => delay}s;

  ${breakpoint.below('tablet')} {
    min-height: 464px;
    padding-bottom: 48px;
  }
`

const MetaContainer = styled.div`
  margin-top: 304px;
  box-sizing: border-box;
  ${Breakdown} {
    margin-bottom: 24px;
  }
  ${Headline} {
    margin-bottom: 28px;
  }

  img {
    height: 8px;
    margin-right: 5px;
  }
`

export class Theme extends PureComponent {
  state = {
    isVisible: false,
  }

  handleVisibility = receivedState => {
    const { isVisible } = this.state

    if (!isVisible) {
      setTimeout(() => this.setState({ isVisible: receivedState }))
    }
  }

  render() {
    const { isVisible } = this.state

    return (
      <VisibilitySensor onChange={this.handleVisibility} partialVisibility>
        <Container>
          <Panel src={require('./img/1.png')} isVisible={isVisible} delay={0.2}>
            <MetaContainer>
              <Breakdown>FEATURED THEME</Breakdown>
              <Headline as="h2">
                AUTHENTIC <br /> PRODUCTS
              </Headline>
              <Breakdown>
                <strong>
                  <img src={require('./img/chevron.png')} alt="chevron" /> GET
                  INVESTOR SAVY
                </strong>
              </Breakdown>
            </MetaContainer>
          </Panel>
          <Panel src={require('./img/2.png')} isVisible={isVisible} delay={0.4}>
            <MetaContainer>
              <Breakdown>FEATURED THEME</Breakdown>
              <Headline as="h2">
                THE ROBOT <br /> REVOLUTION
              </Headline>
              <Breakdown>
                <strong>
                  <img src={require('./img/chevron.png')} alt="chevron" /> ALL
                  ABOUT ROBOTICS
                </strong>
              </Breakdown>
            </MetaContainer>
          </Panel>
        </Container>
      </VisibilitySensor>
    )
  }
}
export default Theme
