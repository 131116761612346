import React from 'react'
import styled from 'styled-components'

const LoaderOuter = styled.div`
  @keyframes lds-rolling {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @-webkit-keyframes lds-rolling {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}

  transform: translate(${({ size }) =>
    `-${size / 2}px, -${size / 2}px`}) scale(1) translate(${({ size }) =>
  `${size / 2}px, ${size / 2}px`});
  position: relative;

  div,
  div:after {
    position: absolute;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border: ${({ width }) => `${width}px`} solid ${({ color }) => color};
    border-top-color: transparent;
    border-radius: 50%;
  }
  div {
    -webkit-animation: lds-rolling 1s linear infinite;
    animation: lds-rolling 1s linear infinite;
    top: ${({ size }) => `${size / 2}px`};
    left: ${({ size }) => `${size / 2}px`};
  }
  div:after {
    transform: rotate(90deg);
  }
`

export const Loading = ({ size = 16, color, width = 4, ...props }) => (
  <LoaderOuter size={size} color={color} width={width} {...props}>
    <div />
  </LoaderOuter>
)

export default Loading
