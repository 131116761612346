import { css } from 'styled-components/macro'

import IoniconsWOFF2 from './Ionicons/Ionicons.woff2'
import IoniconsWOFF from './Ionicons/Ionicons.woff'
import IoniconsTTF from './Ionicons/Ionicons.ttf'

import './Ionicons/ionicons.min.css'

const fontFaces = css`
  @font-face {
    font-family: 'Ionicons';
    src: url(${IoniconsWOFF2}) format('woff2'),
      url(${IoniconsWOFF}) format('woff'),
      url(${IoniconsTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`

export default fontFaces
