import React from 'react'
import * as Icons from 'components/ui/icons'

export const Icon = ({ name = 'IconNotFound', ...p }) => {
  const Ico = Icons[name]
  if (typeof Ico === 'undefined') {
    // -next-line
    console.error(`Icon name ${name} not found`)
  }

  return Ico ? <Ico {...p} /> : null
}

export default Icon
