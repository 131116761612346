import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sc from 'constants/style'
import Link from 'components/ui/link'
import Icon from 'components/ui/icon'

const Url = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  letter-spacing: 2px;
  text-transform: uppercase;
`

const IconWrapper = styled.div`
  color: ${sc.RED_BASE_HEX};
  padding-right: ${sc.BASE_MARGIN / 2}px;
`

export class OutUrl extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    url: PropTypes.string.isRequired,
  }

  render() {
    const { url, children, ...otherProps } = this.props
    return (
      <Url to={url} {...otherProps}>
        <IconWrapper>
          <Icon name="ArrowSimple" direction="right" />
        </IconWrapper>
        {children}
      </Url>
    )
  }
}

export default OutUrl
