import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { config } from 'utils'
// import dashify from 'dashify'
import Headline from 'components/ui/headline'
import Video from 'components/ui/video'
import RichText from 'components/ui/richText'
import Button from 'components/ui/button'
import SliderAlt from 'components/ui/sliderAlt'
import IconPlaceholder from 'components/101/IconPlaceholder'
import AccordionItem from 'components/ui/accordion/AccordionItem'

const Section = styled.section`
  transition: transform 0.2s ease-in-out;
  @media screen and (min-width: ${config.tablet}) {
    transform: ${({ currentIndex, index }) => {
      if (currentIndex > index) {
        return `translate3d(${sc.COLUMN * 3}px, 0, 0)`
      }
      if (currentIndex < index) {
        return `translate3d(-${sc.COLUMN * 3}px, 0, 0)`
      }
      return ''
    }};
  }
  ${Button} {
    margin-top: 42px;
  }
`

const StyledText = styled(RichText)`
  font-size: 16px;
  font-weight: 300;
`

const StyledHeadline = styled(Headline)`
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;

  ${({ large }) =>
    large &&
    css`
      font-size: 34px;
      font-weight: 800;
    `}

  /* ONLY FOR WHILE THUMBNAILS ARE NOT PRESENT */
  margin-top: 23px;
  margin-bottom: 24px;

  @media screen and (max-width: 1020px) {
    margin-top: 7px;
  }
  /* ------------------- */
`

const videoStyle = css`
  z-index: 9999999;
  @media screen and (min-width: ${config.tablet}) {
    margin-bottom: ${sc.COLUMN / 2}px;
  }
`

const StyledSlide = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
`

const StyledSlideImage = styled.div`
  background-size: cover;
  background-position: center center;
  padding-top: 56.25% !important;
  position: relative;
  cursor: initial;
`

const ButtonContainer = styled.span`
  display: flex !important;
  align-items: center;

  ${Button} {
    margin-right: ${sc.COLUMN / 4}px;
    margin-top: 0;
  }
  &&& {
    ${IconPlaceholder} {
      margin-top: 42px !important;
      height: 40px !important;
    }
  }
`

const Subheading101 = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 24px 0;
  text-transform: inherit;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;

  ${({ caps }) =>
    caps &&
    css`
      text-transform: uppercase;
    `}
`

const Subheading101h4 = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 24px 0;
  text-transform: inherit;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;
`

const SliderItem = ({
  index,
  currentIndex,
  // handleWatchMode,
  categoryName,
  itemTitle,
  itemTitleLarge,
  url,
  ctaText,
  text,
  itemActive,
  video,
  videoPoster,
  stayOnHomepage,
}) => (
  <Section index={index} currentIndex={currentIndex}>
    {stayOnHomepage === true ? (
      <span>
        {itemTitleLarge && (
          <StyledHeadline large as="h2">
            {itemTitleLarge}
          </StyledHeadline>
        )}

        <AccordionItem title={'READ MORE'} for101s></AccordionItem>
      </span>
    ) : (
      <span>
        {video && (
          <Video
            noPlayButton
            videoStyle={videoStyle}
            src={video}
            poster={
              <StyledSlide>
                <StyledSlideImage
                  style={{
                    backgroundImage: `url(${videoPoster})`,
                  }}
                />
              </StyledSlide>
            }
          />
        )}

        {itemTitleLarge && (
          <StyledHeadline large as="h4">
            {itemTitleLarge}
          </StyledHeadline>
        )}
        {itemTitle && <StyledHeadline as="h4">{itemTitle}</StyledHeadline>}
        <StyledText>{text}</StyledText>
        {url && (
          <Link to={`/101/${url}`}>
            <ButtonContainer center={itemActive}>
              <Button transparent={itemActive} style={{ marginTop: 42 }}>
                {ctaText ? ctaText : 'START LEARNING'}
              </Button>
              {itemActive && <IconPlaceholder active large />}
            </ButtonContainer>
          </Link>
        )}
      </span>
    )}
  </Section>
)

const sliderStyle = css`
  /* width === 100% - width of sidebar*/
  width: calc(100% - 449px);
  margin: 0 !important;
  overflow: hidden;
  border-left: 1px solid ${sc.GRAY_BASE_HEX};

  .slick-slider {
    margin-top: 61px;
    left: -${sc.COLUMN}px;
    margin-bottom: 48px;
    padding-bottom: 40px;
    width: calc(100% + ${sc.COLUMN}px);
    height: calc(100% - 109px);
    box-sizing: border-box;
  }
  &&&.slick-list {
    padding: 0;
  }
  &::after,
  &::before {
    display: none;
  }

  .slick-button-next,
  .slick-button-prev {
    top: unset;
    height: 48px;
    bottom: -48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-button-prev {
    right: ${sc.COLUMN}px;
  }
  .slick-button-next {
    right: 0px;
  }

  .slick-slide {
    position: relative;
    padding: 0 ${sc.COLUMN * 2}px;
    opacity: 1 !important;
    section {
      outline: none;
    }
  }

  .slick-slide:not(.slick-active) {
    ${StyledSlideImage}::before, ${StyledSlideImage}::after {
      opacity: 1;
    }
  }
  .slick-dots {
    /* bottom: -48px; */
    /* right: ${sc.COLUMN * 2}px; */
    bottom: -41px;
    right: 143px;
    width: unset;

    li div::before {
      background: ${sc.GRAY_BASE_HEX};
    }
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    width: calc(100% - 321px + ${sc.COLUMN}px);
  }
`

export class LearnSliderDesktop extends PureComponent {
  state = {
    currentIndex: 0,
  }

  customSettings = {
    centerPadding: 0,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeOption !== this.props.activeOption) {
      setTimeout(() => this.setState({ currentIndex: 0 }), 0)
    }
  }

  handleBeforeChange = (_, index) => {
    this.setState({ currentIndex: index })
  }

  render() {
    const { currentIndex } = this.state
    const { handleWatchMode, activeOption } = this.props
    return (
      <SliderAlt
        customSettings={this.customSettings}
        customStyle={sliderStyle}
        beforeChange={this.handleBeforeChange}
      >
        {activeOption &&
          activeOption.items &&
          activeOption.items.map(
            (
              {
                itemTitle,
                itemTitleLarge,
                url,
                ctaText,
                text,
                itemActive,
                video,
                videoPoster,
                stayOnHomepage,
              },
              index,
            ) => {
              return (
                index === 0 && (
                  <SliderItem
                    key={index}
                    index={index}
                    categoryName={activeOption.title}
                    itemTitle={itemTitle}
                    itemTitleLarge={itemTitleLarge}
                    url={url}
                    ctaText={ctaText}
                    text={text}
                    itemActive={itemActive}
                    video={video}
                    videoPoster={videoPoster}
                    currentIndex={currentIndex}
                    handleWatchMode={handleWatchMode}
                    stayOnHomepage={stayOnHomepage}
                  />
                )
              )
            },
          )}
      </SliderAlt>
    )
  }
}

export default LearnSliderDesktop
