import React from 'react'
import styled, { css } from 'styled-components'
import { config } from 'utils'
import InvestingExpanded from 'components/ui/investingExpanded'
import LearnSliderDesktop from 'components/ui/learnSliderDesktop'
import LearnSliderMobile from 'components/ui/learnSliderMobile'
import Sidebar from 'components/ui/sidebar'
import categoryList from 'components/ui/learnMoreAboutInvesting/categoryListMobile'

const Container = styled.div`
  display: flex;
  ${({ desktop }) =>
    desktop &&
    css`
      @media screen and (max-width: ${config.tablet}) {
        display: none;
      }
    `}
`

// For desktop
export const categoryListCarousel = [
  {
    title: 'Asset tokenization',
    // disabled: true,
    items: [
      {
        itemTitleLarge: 'What is asset tokenization?',
        url: 'asset-tokenization/what-is-asset-tokenization',
        id: 'what-is-asset-tokenization',
        // ctaText: 'read more',
        text: (
          <>
            Asset tokenization is the process of converting the legal rights
            associated with real world assets (stocks, bonds, revenue) into
            digital tokens using Distributed Ledger Technology (DLT). These
            tokens, often referred to as security tokens or digital securities,
            are fundamentally different from cryptocurrencies such as Bitcoin
            (BTC) or Ethereum (ETH). Unlike these cryptocurrencies, security
            tokens are backed by tangible real world assets, offering an
            underlying security and investment potential for finance
            professionals, investors, family offices, and wealth managers
            seeking stable and reliable digital assets.
            <br />
            <br />
            Digital securities enable the issuance, trading, and settlement on
            DLT platforms. Smart contracts embedded within these digital tokens
            can automate voting and corporate actions, enhancing efficiency and
            security. This digital transformation allows financial instruments
            to be represented on DLT, enabling investors to manage their digital
            securities directly through self-custodied DLT wallets, such as{' '}
            <a
              href="https://www.ledger.com/"
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              Ledger
            </a>{' '}
            or the{' '}
            <a
              href="https://sideswap.io/"
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              SideSwap
            </a>{' '}
            wallet, or through custodial solutions like{' '}
            <a
              href="https://www.fireblocks.com/"
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              Fireblocks
            </a>{' '}
            . <br /> <br />
            The benefits of asset tokenization extend to fund managers and
            management companies by providing straightforward access to investor
            data, eliminating the need for complex third-party services.
            Tokenization also offers insights into the ultimate holders of
            digital securities, aiding in a better understanding of investor
            behaviours, preferences, and expectations.
          </>
        ),
      },
    ],
  },
  {
    title: 'GETTING ON THE BLOCKCHAIN',
    // disabled: true,
    items: [
      {
        itemTitle: 'Getting on the blockchain',
        url: 'getting-on-the-blockchain/introduction-to-wallets-and-keys',
        text: `In this module, we’ll cover everything you need to know to start interacting with the Liquid Network and how to access cryptocurrency-based payments. We’ll also go over some recommended practices you should follow to keep you on the safe side.`,
        // tooltipContent: 'Wherever you go there you are',
        // itemDisabled: true,
        video: require('./img/video.mp4'),
        videoPoster:
          'https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1573037689/Static/getting-on-the-blockchain.jpg',
        id: 'blockchain-v1-introduction-to-wallets-and-keys',
      },
    ],
  },
]

export class LearnMoreAboutInvesting extends React.Component {
  state = {
    activeOption: 0,
    watchMode: false,
  }

  handleActiveOption = (index) => {
    this.setState({ activeOption: index })
  }

  handleWatchMode = () =>
    this.setState(({ watchMode }) => ({ watchMode: !watchMode }))

  render() {
    const { watchMode, activeOption } = this.state

    return (
      <div>
        <LearnSliderMobile
          categoryList={categoryList}
          activeOption={categoryList[activeOption]}
          handleWatchMode={this.handleWatchMode}
        />
        <InvestingExpanded
          isOpen={watchMode}
          categoryList={categoryList}
          handleWatchMode={this.handleWatchMode}
        />
        <Container desktop>
          <Sidebar
            categoryList={categoryList}
            activeOption={activeOption}
            isOpen={true}
            handleActiveOption={this.handleActiveOption}
          />
          <LearnSliderDesktop
            categoryList={categoryListCarousel}
            activeOption={categoryListCarousel[activeOption]}
            handleWatchMode={this.handleWatchMode}
          />
        </Container>
      </div>
    )
  }
}

export default LearnMoreAboutInvesting
