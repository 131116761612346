import styled from 'styled-components'

export const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${props =>
    props.borderBottom &&
    `
    border-bottom: 1px solid #e1e1e1;
  `}
`
