import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import * as sc from 'constants/style'
import Button from 'components/ui/button'
import Icon from 'components/ui/icon'

const duration = 700

const Outer = styled.div`
  display: inline-block;
  height: 40px;
  position: relative;
  z-index: 0;
  width: 50%;
  text-align: right;

  ${({ isStokePostShare }) =>
    isStokePostShare &&
    css`
      position: absolute;
      right: 0;
    `}
`

const rightCss = css`
  right: auto;
  left: 0;
  padding-left: 20px;
  padding-right: 0;
  border-radius: 5px 25px 25px 5px;
  border-left: 0;
  border-right: 1px solid ${sc.GRAY_BASE_HEX};
`

const openHelper = ({ right }) => right && rightCss
const buttonsWrapperRightCss = css`
  right: auto;
  left: 20px;
`

const buttonsWrapperHelper = ({ right }) => right && buttonsWrapperRightCss

const ButtonsWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  width: ${({ open }) => (open ? '300px' : 0)};
  height: 50px;
  right: 20px;
  transition: all ${duration}ms ease-out;
  ${buttonsWrapperHelper};
`

const Buttons = styled.div`
  display: flex;
  position: absolute;
  width: auto;
  white-space: nowrap;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding-right: 20px;
  border-right: 0;
  z-index: -2;
  overflow: hidden;
  transition-property: left, right, opacity;
  transition-duration: 0.3s;
  ${openHelper}

  ${Button} {
    margin-right: 1px;
    border-radius: 0;
    transition-property: color, background !important;
    position: relative;
    z-index: 1;

    &:not(:first-child) {
      border-left: 1px solid #cfcfcf !important;
    }

    &:hover {
      color: ${sc.RED_BASE_HEX} !important;
      background: transparent !important;
    }

    &:focus,
    &:active {
      color: ${sc.WHITE_HEX} !important;
      border-left-color: transparent;

      &::before {
        opacity: 1;
      }
      &::after {
        border-radius: 50%;
        background: ${sc.RED_BASE_HEX} !important;
      }

      + ${Button}::before {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      top: 1px;
      bottom: 1px;
      border-left: 1px solid white;
      border-left-color: ${({ inverted }) => (inverted ? '#004bb7' : 'white')};
      z-index: 1;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      border-radius: 30%;
      transition: all 0.3s;
    }
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &::after {
    position: absolute;
    content: '';
    border: 1px solid #cfcfcf;
    border-right: none;
    border-radius: 25px 0 0 25px;
    background: white;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    z-index: -1;
    ${({ inverted }) =>
      inverted &&
      `
      background: ${sc.BLUE_BASE_RELEASED_HEX};
  `};
  }

  ${Button} {
    color: #cacaca;
  }
`

const activeCss = css`
  color: ${sc.WHITE_HEX};
  background: ${sc.RED_BASE_HEX};
`

const activeHelper = ({ active }) => active && activeCss

const ShareButtonStyled = styled(Button)`
  z-index: 10;
  color: ${({ startcolor }) => startcolor || sc.RED_BASE_HEX};
  background: ${({ forceTransparency }) =>
    forceTransparency ? 'transparent' : 'white'};
  border-color: ${({ forceTransparency }) =>
    forceTransparency ? 'white' : '#cfcfcf'};
  ${activeHelper};

  span {
    position: relative !important;
    left: 2px;
  }

  > div {
    line-height: 0 !important;
    padding-left: 2px !important; /* Correcting svg misalignment */
  }

  &:hover {
    background: ${sc.RED_BASE_HEX};
  }
`

const SharingAnchor = styled.a`
  &:hover {
    opacity: 1;
  }

  ${({ middleItem }) =>
  middleItem &&
  `
    border-right: 1px solid #cfcfcf;
    border-left: 1px solid #cfcfcf;
  `};
`

export class ShareButton extends PureComponent {
  state = {
    open: false,
  }

  node = React.createRef()

  static propTypes = {
    inverted: PropTypes.bool,
    startcolor: PropTypes.string,
    right: PropTypes.bool,
    forceTransparency: PropTypes.bool,
    isStokePostShare: PropTypes.bool,
  }

  static defaultProps = {
    startcolor: sc.RED_BASE_HEX,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleOpen = () => this.setState(({ open }) => ({ open: !open }))

  handleClickOutside = event => {
    const { current } = this.node
    if (current && !current.contains(event.target)) {
      this.setState({
        open: false,
      })
    }
  }

  render() {
    const {
      forceTransparency,
      inverted,
      startcolor,
      right,
      isStokePostShare,
      fullUrl,
    } = this.props
    const { open } = this.state

    const sharingMedia = {
      twitter: "https://twitter.com/intent/tweet?url="+fullUrl+"/",
      facebook: "https://www.facebook.com/sharer/sharer.php?u="+fullUrl+"/",
      linkedin: "https://www.linkedin.com/shareArticle?mini=true&url="+fullUrl+"&title=&summary=&source=",
    }

    return (
      <Outer ref={this.node} isStokePostShare={isStokePostShare}>
        <ShareButtonStyled
          onClick={this.handleOpen}
          color="transparent"
          inverted={inverted}
          active={open}
          startcolor={startcolor}
          forceTransparency={forceTransparency}
          icononly
        >
          <Icon name="Share" />
        </ShareButtonStyled>
        <ButtonsWrapper open={open} right={right} inverted={inverted}>
          <Buttons
            right={right}
            inverted={inverted}
            onClick={() => setTimeout(this.handleOpen, 550)}
          >
            <SharingAnchor href={sharingMedia.facebook} target="_blank">
              <Button icononly isShare noborder color="transparent">
                <Icon name="Facebook" />
              </Button>
            </SharingAnchor>
            <SharingAnchor href={sharingMedia.linkedin} target="_blank" middleItem>
              <Button icononly isShare noborder color="transparent">
                <Icon name="LinkedIn" />
              </Button>
            </SharingAnchor>
            <SharingAnchor href={sharingMedia.twitter} target="_blank">
              <Button icononly isShare noborder color="transparent">
                <Icon name="Twitter" />
              </Button>
            </SharingAnchor>
          </Buttons>
        </ButtonsWrapper>
      </Outer>
    )
  }
}

export default ShareButton
