import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import OutUrl from 'components/ui/outUrl'

const Outer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 0 0 30%;
    padding-right: ${sc.BASE_MARGIN}px;
    padding-bottom: ${sc.BASE_MARGIN}px;
  }
  ${breakpoint.below('mobile')} {
    display: block;
  }
`

export class UrlsList extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
  }

  render() {
    const { items, ...otherProps } = this.props

    return (
      <Outer {...otherProps}>
        {items.map(({ url, title, ...op }, index) => (
          <OutUrl {...op} url={url} key={`url-${index}`}>
            {title}
          </OutUrl>
        ))}
      </Outer>
    )
  }
}

export default UrlsList
