/*  */
import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import * as sc from 'constants/style'
import { config, breakpoint } from 'utils'

import Breakdown from 'components/ui/breakdown'
import Headline from 'components/ui/headline'
import FadeIn from 'components/ui/fadeIn'
import Video from 'components/ui/video'
import CardsSlider from 'components/ui/cardsSlider'

// fetch data
import fetchDataPublic from 'api/fetch-data-public'

import SliderArrow from 'components/ui/slider/SliderArrow'
import StyledDotsContainer from 'components/ui/slider/StyledDotsContainer'
import StyledDots from 'components/ui/slider/StyledDots'

import readingtimesvg from 'images/clock_icon.svg'
import patternSquare from 'images/pattern-square.svg'
import ReactVisibilitySensor from 'react-visibility-sensor'

const arrowHeight = 48

const IntroBlock = styled.div`
  width: 100%;

  box-sizing: border-box;
  position: relative;

  @media screen and (max-width: ${config.tablet}) {
    padding-left: 0;
  }
`

const IntroBlockInner = styled.div`
  box-sizing: border-box;
  position: relative;
  top: ${arrowHeight}px;

  @media screen and (max-width: ${config.mobile}) {
    padding-top: 8px;
  }
  @media screen and (min-width: ${config.tablet}) {
    padding-top: 46px;
    padding-left: ${sc.COLUMN}px;
    padding-right: ${sc.COLUMN * 2}px;
    padding-bottom: 88px;
  }
  @media screen and (max-width: ${config.tablet}) {
    border-left: none;
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 68px;
  }

  @media screen and (min-width: ${config.lgDesktop}) {
    padding-top: 46px;
    padding-left: 64px;
    padding-bottom: 72px;
  }
`

const StyledHeadline = styled((props) => <Headline {...props} />)`
  position: relative;
  margin-bottom: 32px;
  line-height: 0.8;
  margin-block-start: 40px;
  margin-block-end: 0;

  @media screen and (max-width: ${config.mobile}) {
    line-height: 24px;
  }
  @media screen and (min-width: ${config.tablet}) {
    &::before {
      left: ${-sc.COLUMN}px;
    }
  }
  @media screen and (max-width: ${config.tablet}) {
    margin-block-start: 36px;
    &::before {
      left: ${-sc.COLUMN / 2}px;
    }
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    &::before {
      left: ${-sc.COLUMN * 2}px;
    }
  }
`

const StyledBreakdown = styled((props) => <Breakdown {...props} />)`
  margin-bottom: 28px;
  line-height: 1;
`

/** Slide */
const StyledSlideImage = styled.div`
  background-size: cover;
  background-position: center;
  padding-top: 56.25% !important;
  margin-top: ${arrowHeight}px;
  margin-bottom: 51px;
  position: relative;
`

/** Custom css */
const arrowStyle = css`
  height: ${arrowHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const dotsStyle = css`
  display: flex;

  @media screen and (min-width: ${config.tablet}) {
    right: 92px !important;
    bottom: 0px !important;
  }
  @media screen and (max-width: ${config.tablet}) {
    bottom: 0px !important;
    justify-content: center;
    right: 0px !important;
    width: 100%;
  }

  li {
    div {
      &::before {
        background: rgba(0, 0, 0, 0.1);
        border: solid 1px ${sc.WHITE_HEX};
      }
    }
  }

  li.slick-active {
    div {
      &::before {
        background: ${sc.WHITE_HEX};
        opacity: 1;
      }
    }
  }
`

const sliderStyle = css`
  .slick-dots {
    display: inline-flex;
    width: auto;
    padding: 8px 16px;

    @media screen and (min-width: ${config.tablet}) {
      padding: 9px 16px;
    }
  }

  margin: 0 !important;

  &::before {
    width: ${sc.COLUMN * 6}px;
  }

  .slick-slide {
    position: relative;
    padding: 0 ${sc.COLUMN}px;
    opacity: 1 !important;

    @media screen and (min-width: 768px) {
      padding: 0 16px !important;
    }
  }

  .slick-slide:not(.slick-active) {
    ${StyledSlideImage}::before, ${StyledSlideImage}::after {
      opacity: 1;
    }
  }

  &::after {
    background: ${sc.BLUE_BASE_HEX};
    height: 116px;
    width: 100%;
    bottom: 0;
    top: unset;
    left: 0;
  }

  @media screen and (max-width: ${config.tablet}) {
    &::before {
      width: 100%;
      left: 0;
    }

    .slick-slide {
      padding: 0;
    }
  }

  @media screen and (min-width: ${config.tablet}) {
    .slick-slide {
      padding: 0 ${sc.COLUMN / 2}px;
    }
    margin: 0;

    &::before {
      width: 33.33%;
      left: unset;
      right: 0;
    }
  }

  @media screen and (min-width: ${config.lgDesktop}) {
    &::before {
      width: 33.33%;
    }
  }
`

/** Wrapper for the slider */
const SliderWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  position: relative;
`

const StyledSlick = styled.div`
  position: relative;

  margin: 4rem 0;

  ${breakpoint.below('tablet')} {
    margin: 3rem 0;
  }
  ${breakpoint.below('mobile')} {
    margin: 2rem 0;
  }

  .slick-track,
  .slick-list,
  .slick-track div {
    height: 100%;
  }

  .slick-slide {
    transition: opacity 0.5s ease-in;
    opacity: 0.75;
    padding: 0 24px;
    box-sizing: border-box;

    &.slick-active {
      opacity: 1;
      transition: opacity 0.2s ease;
    }

    ${breakpoint.below('desktop')} {
      padding: 0;
    }
  }
  .slick-active ~ .slick-slide {
    opacity: 0.75;
  }

  .slick-track {
    padding: 48px 0;

    @media screen and (min-width: ${config.tablet}) {
      padding: 48px 0;
    }

    section {
      margin: 0;
    }
  }

  .slick-dots {
    right: 110px;
    bottom: 64px;
    height: 48px;

    ${breakpoint.below('mobile')} {
      right: 0;
      bottom: 50px;
    }
  }

  div {
    outline: none;
  }

  .slick-button-prev {
    right: 436px;
    top: 0;

    ${breakpoint.below('tablet')} {
      right: calc(40% - 64px);
    }
    ${breakpoint.below('mobile')} {
      right: 64px;
    }
  }

  .slick-button-next {
    right: 372px;
    top: 0;

    ${breakpoint.below('tablet')} {
      right: calc(40% - 64px - 64px);
    }
    ${breakpoint.below('mobile')} {
      right: 0;
    }
  }

  /* Background under buttons */
  &::before {
    background: linear-gradient(
      to bottom,
      ${sc.GRAY_BASE_HEX} 0%,
      ${sc.WHITE_HEX} 100%
    );
    background: url(${patternSquare});
    width: 500px;
    height: 500px;
    max-height: 100%;
    right: 0;
    content: '';
    position: absolute;
    z-index: -1;

    ${breakpoint.below('tablet')} {
      width: 40%;
    }

    @media screen and (max-width: 600px) {
      left: -200px;
      width: 1000px;
      height: 440px !important;
      bottom: 0;
    }
  }

  /* Blue background on the left */
  &::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
    z-index: -1;
    background: rgb(0, 75, 183);

    ${breakpoint.below('tablet')} {
      width: 150px;
    }
    ${breakpoint.below('mobile')} {
      width: 70px;
      height: 54.75px;
      bottom: 0;
      top: auto;
    }
  }
  ${(props) => props.customStyle}
`

const LeftPattern = styled.div`
  background: url(${patternSquare}) no-repeat;
  bottom: 0;
  display: none;
  position: absolute;
  height: calc(100% - 48px);
  width: 350px;
  z-index: -1;

  @media screen and (min-width: ${config.tablet}) {
    display: block;
  }
`

/** Portrait */
const CardOutter = styled.section`
  z-index: 2;
  margin-top: ${arrowHeight}px;
  margin-bottom: 51px;

  @media screen and (max-width: ${config.mobile}) {
    padding: 0 8px !important;
  }

  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
`

const CardTop = styled.section`
  min-height: ${sc.COLUMN * 5}px;
  min-width: ${sc.COLUMN * 5}px;
  max-width: ${sc.COLUMN * 5}px;
  background: ${({ src }) =>
    src ? `url(${src}) no-repeat center center` : 'none'};
  background-size: cover;

  @media screen and (max-width: ${config.tablet}) {
    min-height: 256px;
    min-width: 256px;
    max-width: 256px;
  }

  ${({ withThumbnailText }) =>
    withThumbnailText &&
    `
      align-items: center;
      display: flex; 
      justify-content: center;
  `}

  ${({ red }) =>
    red &&
    `
    background: ${sc.RED_BASE_HEX} !important;
  `}
`

const CardBottom = styled.section`
  border-bottom: 1px solid #ededed !important;
  border-left: 1px solid #ededed !important;
  border-right: 1px solid #ededed !important;
  min-width: ${sc.COLUMN * 5}px;
  max-width: ${sc.COLUMN * 5}px;
  height: 122px;
  background: #fff !important;
  padding: 15px 20px 17px 20px;

  @media screen and (max-width: ${config.tablet}) {
    min-width: 256px;
    max-width: 256px;
    height: 144px;
  }

  * {
    margin: 0;
  }
`

const ThumbnailText = styled.h3`
  color: ${sc.WHITE_HEX};
  font-size: 32px;
  margin: 0;
`

const CardTitle = styled.h3`
  font-size: 18px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`

const CardSubtitle = styled.h4`
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 10px;
  text-transform: uppercase;
  width: fit-content;
  float: left;
  position: absolute;
  top: 91%;
`
const CardTime = styled.h4`
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 10px;
  text-transform: initial;
  width: fit-content;
  float: right;
  position: absolute;
  top: 91%;
  left: 73%;

  img {
    width: 12px;
    height: 12px;
    float: left;
    margin-right: 7px;
    margin-top: 1px;
  }

  @media screen and (max-width: 767px) {
    left: 67%;
  }
`

const cardsSliderStyle = css`
  ${sliderStyle};
  overflow: hidden;
  &::before {
    width: 100%;
    height: 500px;
    left: 0;
  }

  .slick-button-prev,
  .slick-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    .slick-button-prev {
      left: calc(50% + ${sc.COLUMN}px - 64px);
      display: none;
    }
    .slick-button-next {
      left: calc(50% + ${sc.COLUMN * 1}px);
      display: none;
    }
  }

  @media screen and (min-width: 601px) {
    &::before {
      left: calc(50% + ${sc.COLUMN * 2.5}px);
    }
    .slick-button-prev {
      left: calc(50% + ${sc.COLUMN * 2.5}px);
    }
    .slick-button-next {
      left: calc(50% + ${sc.COLUMN * 3.5}px);
    }
  }

  @media screen and (min-width: 769px) {
    &::before {
      left: calc(50% + ${sc.COLUMN * 3}px);
    }
    .slick-button-prev {
      left: calc(50% + ${sc.COLUMN * 3}px);
    }
    .slick-button-next {
      left: calc(50% + ${sc.COLUMN * 4}px);
    }
  }

  @media screen and (min-width: ${config.lgDesktop}) {
    &::before {
      left: calc(50% + ${sc.COLUMN * 3}px);
    }
    .slick-button-prev {
      left: calc(50% + ${sc.COLUMN * 3}px);
    }

    .slick-button-next {
      left: calc(50% + ${sc.COLUMN * 4}px);
    }
    .slick-slide {
      padding: 0 ${sc.COLUMN / 4}px;
    }
  }

  @media screen and (min-width: 1550px) {
    &::before {
      left: calc(50% + ${sc.COLUMN * 8.5}px);
    }
    .slick-button-prev {
      left: calc(50% + ${sc.COLUMN * 8.5}px);
    }
    .slick-button-next {
      left: calc(50% + ${sc.COLUMN * 9.5}px);
    }
  }
`

const postLink = {
  opacity: 1,
}

/** Settings */
const responsive = [
  {
    breakpoint: 768,
    settings: {
      centerPadding: '32px',
    },
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: 0,
    },
  },
]

const cardsSettings = {
  centerMode: true,
  dots: true,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '60px',
  cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  arrows: true,
  nextArrow: <SliderArrow arrowStyle={arrowStyle} />,
  prevArrow: <SliderArrow arrowStyle={arrowStyle} left />,
  appendDots: (dots) => (
    <StyledDotsContainer dotsStyle={dotsStyle}>{dots}</StyledDotsContainer>
  ),
  customPaging: () => <StyledDots />,
  responsive,
}

export class GalleryVideoBlock extends PureComponent {
  state = {
    sliderRef: undefined,
    arrowsXPosition: undefined,
    slides: [],
  }

  fetchStokePosts = async () => {
    try {
      const fetchStokePost = await fetchDataPublic('stoke-post/get', {
        filter: {
          status: 'Active',
          displayOnHomepage: 'true',
        },
      })
      const { list } = fetchStokePost

      const slides = createSlides(list)
      this.setState({ slides })
    } catch (error) {
      console.log('Error fetching STOKE POSTS: ', error)
    }
  }

  async componentDidMount() {
    this.fetchStokePosts()
  }

  componentDidUpdate() {
    if (this.state.sliderRef) {
      const slickSlider = this.state.sliderRef
      const slickList = slickSlider.children[1]
      const slickTrack = slickList.children[0]

      for (const div of slickTrack.children) {
        if (div.attributes[0].nodeValue === '1') {
          this.updateArrowsXPosition(div.getBoundingClientRect().left)
        }
      }
    }
  }

  updateArrowsXPosition = (position) => {
    this.setState({
      arrowsXPosition: position,
    })
  }

  getSliderRef = (ref) => {
    this.setState({
      sliderRef: ref,
    })
  }

  render() {
    const { arrowsXPosition, slides } = this.state

    return (
      <React.Fragment>
        {slides.length === 0 ? (
          <div />
        ) : (
          <>
            <FadeIn delay={80}>
              <IntroBlock>
                <IntroBlockInner>
                  <StyledBreakdown>STOKE POST</StyledBreakdown>
                  <StyledHeadline as="h2">FEATURED MEDIA</StyledHeadline>
                </IntroBlockInner>
              </IntroBlock>
            </FadeIn>
            <FadeIn delay={80}>
              <SliderWrapper>
                <span />
                <StyledSlick
                  customStyle={cardsSliderStyle}
                  arrowsXPosition={arrowsXPosition}
                >
                  <LeftPattern />
                  <CardsSlider
                    getSliderRef={this.getSliderRef}
                    sliderSettings={cardsSettings}
                    sliderData={slides}
                  />
                </StyledSlick>
              </SliderWrapper>
            </FadeIn>
          </>
        )}
      </React.Fragment>
    )
  }
}

const createSlides = (posts) => {
  const slides = []

  // STOKE POST articles
  posts
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
    .forEach(({ url, image, thumbnail_title, category, readingtime }) => {
      slides.push(() => (
        <ReactVisibilitySensor partialVisibility>
          {({ isVisible }) => {
            return (
              <Link style={postLink} to={`/stoke-post/${url}`}>
                <CardOutter>
                  <CardTop src={isVisible ? image : null} />
                  <CardBottom>
                    <CardTitle>{thumbnail_title}</CardTitle>
                    <CardSubtitle>{category}</CardSubtitle>
                    <CardTime>
                      <img
                        src={readingtimesvg}
                        alt="reading time"
                        loading="lazy"
                      />
                      {readingtime}min.
                    </CardTime>
                  </CardBottom>
                </CardOutter>
              </Link>
            )
          }}
        </ReactVisibilitySensor>
      ))
    })

  // STOKE POST main page
  slides.push(() => (
    <Link style={postLink} to="/stoke-post/">
      <CardOutter>
        <CardTop withThumbnailText red>
          <ThumbnailText>STOKE POST</ThumbnailText>
        </CardTop>
        <CardBottom>
          <CardTitle>VIEW ALL</CardTitle>
        </CardBottom>
      </CardOutter>
    </Link>
  ))

  // Vision video
  slides.push((props) => (
    <Video
      src={require('./img/stokr-vision.mp4')}
      onOpen={props.videoOnOpen}
      onClose={props.videoOnClose}
      isCenter
      isSmallCard
      poster={
        <CardOutter>
          <CardTop
            src={
              'https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1573035926/Featured_Media/fuelling-innovation-with-stokr_cwdky0.jpg'
            }
          />
          <CardBottom>
            <CardTitle>Fuelling innovation with STOKR</CardTitle>
            <CardSubtitle>STOKR vision video</CardSubtitle>
            <CardTime>
              <img
                src={readingtimesvg}
                style={{ marginLeft: '7px' }}
                alt="reading time"
              />
              2min.
            </CardTime>
          </CardBottom>
        </CardOutter>
      }
    />
  ))

  // STOKR insights video
  slides.push((props) => (
    <Video
      src={
        'https://www.youtube.com/embed/videoseries?list=PLUD4g6HJhtfxrumDstNSmYTpVBNlsnbIs&autoplay=1'
      }
      onOpen={props.videoOnOpen}
      onClose={props.videoOnClose}
      iframe
      isCenter
      isSmallCard
      poster={
        <CardOutter>
          <CardTop
            src={
              'https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1689329046/Featured_Media/stokr_insights_ekuc0g.png'
            }
          />
          <CardBottom>
            <CardTitle>
              Stokr insights with Arnab naskar and tobias seidl
            </CardTitle>
            <CardSubtitle>STOKR podcast video</CardSubtitle>
            <CardTime>
              <img
                src={readingtimesvg}
                style={{ marginLeft: '7px' }}
                alt="reading time"
              />
              30min.
            </CardTime>
          </CardBottom>
        </CardOutter>
      }
    />
  ))

  return slides
}

export default GalleryVideoBlock
