import { createGlobalStyle } from 'styled-components'
import ionicons from 'style/ionicons/ionicons'

export const BaseCss = createGlobalStyle`
${ionicons};

  body, html {
    min-width: 320px;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    position: relative;
  }

  body img {
    max-width: 100%;
    max-height: auto;
  }

  a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: inherit;
  }

  #root {
    margin: 0 auto;
    position:relative;
    /* overflow: hidden */
  }

  .svgClass {
    max-height:600px;
    &> svg {
      max-height:600px;
    }
  }
`

export default BaseCss
