import React, { PureComponent } from 'react'
import { css } from 'styled-components'
import Slider from 'react-slick'
import * as sc from 'constants/style'
import SlickOptions from 'components/ui/slider/SlickOptions'

import SliderArrow from 'components/ui/slider/SliderArrow'
import StyledDotsContainer from 'components/ui/slider/StyledDotsContainer'
import StyledDots from 'components/ui/slider/StyledDots'

const arrowStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export class CardsSlider extends PureComponent {
  settings = {
    // centerMode: true,
    infinite: true,
    // slidesToShow: 1,
    // initialSlide: this.props.initialSlide || 1,
    speed: SlickOptions.transitionLength,
    cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',

    nextArrow: (
      <SliderArrow
        arrowStyle={arrowStyle}
        position={{ top: 0, right: `${sc.COLUMN * 2}px` }}
      />
    ),
    prevArrow: (
      <SliderArrow
        arrowStyle={arrowStyle}
        left
        position={{
          top: 0,
          right: `calc(${sc.COLUMN * 2}px + ${sc.COLUMN}px)`,
        }}
      />
    ),
    appendDots: dots => <StyledDotsContainer>{dots}</StyledDotsContainer>,
    customPaging: () => <StyledDots />,
    variableWidth: true,
    focusOnSelect: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          // slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  slickRef = React.createRef()

  initialTouchPos = null

  touchPos = null

  // secondSlidePosition = null;

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart)
    document.addEventListener('touchend', this.handleTouchEnd)

    // const slickSlider = this.slickRef.current.children[0];
    // this.props.getSliderRef(slickSlider);
  }

  componentDidUpdate() {
    // const slickSlider = this.slickRef.current.children[0];
    // this.props.getSliderRef(slickSlider);
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleTouchStart)
    document.removeEventListener('touchend', this.handleTouchEnd)
  }

  handleTouchStart = event => {
    this.initialTouchPos = event.touches[0].clientX

    if (this.slickRef.current && this.slickRef.current.contains(event.target)) {
      window.addEventListener('touchmove', this.handleTouchMove, {
        passive: false,
      }) // Need to disable passive for Chrome 54+
    }
  }

  handleTouchMove = event => {
    const touchPos = event.touches[0].clientX
    this.touchPos = touchPos

    if (Math.abs(touchPos - this.initialTouchPos) > 5 && event.cancelable) {
      event.preventDefault()
    }
  }

  handleTouchEnd = () => {
    window.removeEventListener('touchmove', this.handleTouchMove)
  }

  render() {
    const { sliderData, sliderSettings } = this.props

    return (
      <div ref={this.slickRef}>
        <Slider {...{ ...this.settings, ...sliderSettings }}>
          {sliderData.map((Slide, index) => (
            <Slide key={index} />
          ))}
        </Slider>
      </div>
    )
  }
}

export default CardsSlider
