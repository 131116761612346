import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Breakdown from 'components/ui/breakdown'
import { breakpoint } from 'utils'
import { TimelineHeadline } from './commons'

const Section = styled.div`
  padding: 96px 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 50%;
    left: 50%;
    bottom: 0;
    border-right: 1px dashed #e1e1e1;

    ${breakpoint.below('tablet')} {
      height: 96px;
    }
  }

  ${breakpoint.below('mobile')} {
    padding-top: 44px;
    text-align: center;
  }
`

const Headline = styled(TimelineHeadline)`
  margin: 0;

  ${breakpoint.below('tablet')} {
    font-size: !28px;
  }
  ${breakpoint.below('mobile')} {
    font-size: 22px;
  }
`

const Column = styled.div`
  flex: 0 0 50%;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  padding: 0 64px;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  ${breakpoint.below('tablet')} {
    padding: 0;
    flex: 1 0 100%;
    text-align: left;
    margin-bottom: 56px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${breakpoint.below('mobile')} {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  ${breakpoint.below('tablet')} {
    flex-wrap: wrap;
  }
`

const Circle = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-left: -40px !important;
  margin-top: -40px !important;
  margin: 0 auto;
  z-index: 2;

  ${breakpoint.below('tablet')} {
    top: auto;
    bottom: 96px;
  }

  ${breakpoint.below('mobile')} {
    position: static;
    margin: 44px auto 0 !important;
  }
`

const Numbers = styled.div`
  display: inline-block;
  padding: 0 32px;

  &:not(:last-child) {
    border-right: 1px solid #d5d5d5;
  }

  ${breakpoint.below('tablet')} {
    &:first-child {
      padding-left: 0;
    }
  }

  ${breakpoint.below('mobile')} {
    flex: 1 0 50%;

    &:first-child {
      padding-right: 22.5px;
      padding-left: 0;
    }
    &:last-child {
      padding-left: 45px;
      padding-right: 0;
    }
  }
`

const BigNumber = styled.div`
  display: block;
  margin-top: 20px;
  font-size: 55px;
  font-weight: 300;
  letter-spacing: 2.43;
  line-height: 57px;
  color: #202020;
  flex: 1 0 50%;

  ${breakpoint.below('mobile')} {
    font-size: 34px;
    margin-top: 0;
  }
`

const imgCircle = require('./img/STO_circle.svg')
const imgCircleBlue = require('./img/STO_circle_blue.svg')

export class TimelineTopProgress extends PureComponent {
  static propTypes = {
    capitalRaised: PropTypes.number,
    daysLeft: PropTypes.number,
    title: PropTypes.string,
    closed: PropTypes.bool,
  }

  render() {
    const { capitalRaised, daysLeft, title, closed } = this.props

    return (
      <Section>
        <Container>
          <Column>
            <Headline as="h2">{title}</Headline>
          </Column>
          <Column centered>
            <Numbers>
              <Breakdown nomobile>Capital raised</Breakdown>
              <Breakdown mobile>Cap. raised</Breakdown>
              <BigNumber>{capitalRaised}%</BigNumber>
            </Numbers>
            <Numbers>
              <Breakdown>Days left</Breakdown>
              <BigNumber>{daysLeft}</BigNumber>
            </Numbers>
          </Column>
        </Container>
        <Circle src={!closed ? imgCircleBlue : imgCircle} alt="" />
      </Section>
    )
  }
}
