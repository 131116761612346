import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
// import dashify from 'dashify'
import styled, { css } from 'styled-components'
import Headline from 'components/ui/headline'
import Breakdown from 'components/ui/breakdown'
import AccordionCheckMark from 'components/ui/accordionCheckMark'
import LearnSlider from 'components/ui/learnSlider'
import RichText from 'components/ui/richText'
import Button from 'components/ui/button'
import IconPlaceholder from 'components/101/IconPlaceholder'
import Icon from 'components/ui/icon'
import AccordionItem from 'components/ui/accordion'
import * as sc from 'constants/style'
import { config } from 'utils'

const SidebarHeadline = styled.div`
  /* padding: 0 ${sc.COLUMN / 2}px; */
  padding: 0 32px;
  padding-top: 56px;
  padding-bottom: 68px;
  border-bottom: 1px solid ${sc.GRAY_BASE_HEX};
  background: white;
  ${Breakdown} {
    font-size: 11px;
    line-height: 1;
  }
  ${Headline} {
    margin-bottom: 0px;
    margin-top: 36px;
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}
`

const LinkStyled = styled.a`
  font-size: 12px;
  color: ${sc.BLUE_BASE_HEX};
  cursor: pointer;
  svg {
    width: 8px;
    transform: rotate(90deg);
    margin-right: 5px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  ${({ desktop }) =>
    desktop &&
    css`
      @media screen and (max-width: ${config.tablet}) {
        display: none;
      }
    `}
  ${({ mobile }) =>
    mobile &&
    css`
      display: block;
      border-bottom: 5px solid ${sc.BLUE_BASE_HEX};
      @media screen and (min-width: ${Number(config.tablet.replace(/px$/, '')) +
        1}px) {
        display: none;
      }
    `}
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;

  ${Button} {
    margin: 0 auto;
    margin-top: ${sc.COLUMN / 2}px;
  }
`

const IntroBlockInner = styled.div`
  ${({ noPaddingBottom }) =>
    noPaddingBottom &&
    css`
      padding-bottom: 0;
    `}
  box-sizing: border-box;
  border-left: 1px solid ${sc.GRAY_BASE_HEX};
  position: relative;
  padding-top: 60px;
  @media screen and (max-width: ${config.lgDesktop}) {
    padding-top: 0;
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    padding-right: ${sc.COLUMN / 2}px;
    padding-left: ${sc.COLUMN / 2}px;
    padding-bottom: ${sc.COLUMN / 2}px;
    border-left: none;
  }
  @media screen and (min-width: ${config.lgDesktop}) {
    padding-left: ${sc.COLUMN}px;
    padding-right: ${sc.COLUMN}px;
    padding-bottom: ${sc.COLUMN * 1.5}px;
  }
  @media screen and (max-width: ${config.tablet}) {
    flex: 1;
  }
  ${({ alternate }) =>
    alternate &&
    css`
      border-left: 0;
      border-right: 1px solid ${sc.GRAY_BASE_HEX};
      padding-right: ${sc.COLUMN * 1}px;
      margin-right: ${sc.COLUMN * 1.5}px;
      @media screen and (max-width: ${config.lgDesktop}) {
        padding-right: 0px;
        border-right: none;
        margin-right: ${sc.COLUMN / 2}px;
      }
      @media screen and (min-width: ${config.lgDesktop}) {
        padding-bottom: 0px;
        margin-right: ${sc.COLUMN}px;
      }
    `}
`

// const StyledSlideImage = styled.div`
//   background-size: cover;
//   background-position: center;
//   padding-top: 56.25% !important;
//   position: relative;
//   @media screen and (max-width: ${config.tablet}) {
//     margin-bottom: 0px;
//   }
// `

// /** Media wrappers */
// const StyledSlide = styled.div`
//   position: relative;
//   box-sizing: border-box;
//   cursor: pointer;
//   width: 100%;
//   margin: 0 auto;
//   ${({ padded }) =>
//     padded &&
//     css`
//       margin-top: 92px;
//       margin-bottom: 52px;

//       @media screen and (max-width: ${config.lgDesktop}) {
//         margin-top: ${sc.COLUMN}px;
//         margin-bottom: ${sc.COLUMN * 2}px;
//       }
//       @media screen and (max-width: ${config.tablet}) {
//         margin-bottom: ${sc.COLUMN / 2}px;
//         min-width: 256px;
//       }
//     `}
// `

const StyledHeadline = styled((props) => <Headline {...props} />)`
  position: relative;
  margin-bottom: ${sc.COLUMN / 2}px;
  width: 75%;

  &::before {
    content: '';
    position: absolute;
    left: -${sc.COLUMN * 2}px;
    height: 100%;
    width: 8px;
    background: ${sc.BLUE_BASE_HEX};
  }

  @media screen and (max-width: ${config.tablet}) {
    margin-bottom: ${sc.COLUMN / 4}px;
    &::before {
      display: none;
    }
  }
  @media screen and (max-width: ${config.lgDesktop}) {
    &::before {
      left: ${-sc.COLUMN / 2}px;
    }
  }

  @media screen and (min-width: ${config.lgDesktop}) {
    &::before {
      left: ${-sc.COLUMN}px;
    }
  }

  ${({ content }) =>
    content &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}

  ${({ alternate }) =>
    alternate &&
    css`
      &::before {
        left: unset;
        right: -${sc.COLUMN}px;
      }
      @media screen and (max-width: ${config.lgDesktop}) {
        &::before {
          left: unset;
          right: -${sc.COLUMN / 2}px;
        }
      }
    `}
`

const ButtonContainer = styled.span`
  display: flex;
  align-items: center;
  ${({ center }) =>
    center &&
    css`
      ${Button} {
        max-width: calc(100% - 40px - 24px);
        margin-right: 24px;
      }

      justify-content: center;
      div {
        margin-top: ${sc.COLUMN / 2}px;
      }
    `}
  ${Button} {
    margin: 42px 0 0 0;
  }
`

// const videoStyle = css`
//   @media screen and (max-width: ${config.tablet}) {
//     margin: ${sc.COLUMN / 2}px;
//     flex: 1;
//     max-height: 50%;
//   }
//   margin-bottom: ${sc.COLUMN / 2}px;
// `

const Subheading101 = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 24px 0;
  text-transform: inherit;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.6px;

  ${({ caps }) =>
    caps &&
    css`
      text-transform: uppercase;
    `}
`

const MobileSliderItem = ({
  itemTitle,
  url,
  text,
  categoryName,
  active,
  handleWatchMode,
  stayOnHomepage,
  itemTitleLarge,
  st_subHeading1,
  st_subHeading2,
  st_itemTitle1,
  st_itemTitle2,
  st_itemTitle3,
  st_itemTitle4,
  st_itemTitle5,
  st_itemTitle6,
  st_itemTitle7,
  st_text1,
  st_text2,
  st_text3,
  st_text4,
  st_text5,
  st_text6,
  st_text7,
  st_text8,
  st_text9,
  st_text10,
  st_text11,
  st_text12,
  st_text13,
  st_text14,
  st_listItem1,
  st_listItem2,
  st_listItem3,
  st_listItem4,
  st_listItem5,
  st_listItem6,
  st_listItem7,
  st_listItem8,
  st_listItem9,
}) => (
  <Section>
    {/* <Video
      videoStyle={videoStyle}
      src={require('./img/video.mp4')}
      poster={
        <StyledSlide>
          <StyledSlideImage
            style={{
              backgroundImage: `url(${require('./img/videoposter.jpg')})`,
            }}
          />
        </StyledSlide>
      }
    /> */}

    {stayOnHomepage === true ? (
      <IntroBlockInner>
        {itemTitleLarge && (
          <StyledHeadline large as="h2">
            {itemTitleLarge}
          </StyledHeadline>
        )}

        {st_subHeading1 && <Subheading101 caps>{st_subHeading1}</Subheading101>}

        <AccordionItem
          title={st_itemTitle1}
          key={st_itemTitle1}
          noPaddingHorizontal
          for101sMobile
        >
          {st_text1}
        </AccordionItem>

        <AccordionItem
          title={st_itemTitle2}
          key={st_itemTitle2}
          noPaddingHorizontal
          for101sMobile
        >
          {st_text2 && <RichText>{st_text2}</RichText>}
          <br />
          {st_text3 && <RichText>{st_text3}</RichText>}

          {st_listItem1 && <ul>{st_listItem1 && <li>{st_listItem1}</li>}</ul>}
          {st_listItem2 && <ul>{st_listItem2 && <li>{st_listItem2}</li>}</ul>}
          {st_listItem3 && <ul>{st_listItem3 && <li>{st_listItem3}</li>}</ul>}
        </AccordionItem>

        <AccordionItem
          title={st_itemTitle3}
          key={st_itemTitle3}
          noPaddingHorizontal
          for101sMobile
        >
          {st_text4 && <RichText>{st_text4}</RichText>}
          <br />
          {st_text5 && <RichText>{st_text5}</RichText>}
          <br />
          {st_text6 && <RichText>{st_text6}</RichText>}
        </AccordionItem>

        <AccordionItem
          title={st_itemTitle4}
          key={st_itemTitle4}
          noPaddingHorizontal
          for101sMobile
        >
          {st_text7 && <RichText>{st_text7}</RichText>}
        </AccordionItem>

        <AccordionItem title={'READ MORE'} for101s>
          {st_subHeading2 && (
            <Subheading101 caps>{st_subHeading2}</Subheading101>
          )}

          <AccordionItem
            title={st_itemTitle5}
            key={st_itemTitle5}
            noPaddingHorizontal
            for101sMobile
            shouldStopPropagation
          >
            {st_text8 && <RichText>{st_text8}</RichText>}
          </AccordionItem>

          <AccordionItem
            title={st_itemTitle6}
            key={st_itemTitle6}
            noPaddingHorizontal
            for101sMobile
            shouldStopPropagation
          >
            {st_text9 && <RichText>{st_text9}</RichText>}
            <br />
            {st_text10 && <RichText>{st_text10}</RichText>}
          </AccordionItem>

          <AccordionItem
            title={st_itemTitle7}
            key={st_itemTitle7}
            noPaddingHorizontal
            for101sMobile
            shouldStopPropagation
          >
            {st_text11 && <RichText>{st_text11}</RichText>}

            {st_listItem4 && <ul>{st_listItem4 && <li>{st_listItem4}</li>}</ul>}
            {st_listItem5 && <ul>{st_listItem5 && <li>{st_listItem5}</li>}</ul>}
            {st_listItem6 && <ul>{st_listItem6 && <li>{st_listItem6}</li>}</ul>}
            {st_listItem7 && <ul>{st_listItem7 && <li>{st_listItem7}</li>}</ul>}
            {st_listItem8 && <ul>{st_listItem8 && <li>{st_listItem8}</li>}</ul>}
            {st_listItem9 && <ul>{st_listItem9 && <li>{st_listItem9}</li>}</ul>}

            {st_text12 && <RichText>{st_text12}</RichText>}
            <br />
            {st_text13 && <RichText>{st_text13}</RichText>}
          </AccordionItem>
        </AccordionItem>
      </IntroBlockInner>
    ) : (
      <IntroBlockInner>
        <StyledHeadline as="h2">{itemTitle}</StyledHeadline>
        <RichText>{text}</RichText>
        {url && (
          <Link to={`/101/${url}`}>
            <ButtonContainer center={active}>
              {/* <Button size="xl" onClick={handleWatchMode}> */}
              <Button>{active ? 'READ NOW' : 'READ NOW'}</Button>
              {active && <IconPlaceholder active large />}
            </ButtonContainer>
          </Link>
        )}
      </IntroBlockInner>
    )}
  </Section>
)

export class LearnSliderMobile extends PureComponent {
  render() {
    const { expanded, categoryList, handleWatchMode, activeOption } = this.props
    return (
      <Container mobile>
        <SidebarHeadline noBorder>
          <Breakdown is101>101s</Breakdown>
          <Headline as="h2">LEARN MORE</Headline>
          {expanded && (
            <LinkStyled onClick={handleWatchMode}>
              <Icon name="ArrowSimple" direction="left" />
              BACK TO PREVIOUS PAGE
            </LinkStyled>
          )}
        </SidebarHeadline>
        {categoryList.map(
          ({ title, completed, progress, items, noPaddingBottomOnMobile }) => {
            let RenderIcon = null

            if (completed) {
              RenderIcon = (props) => <IconPlaceholder {...props} />
            } else if (progress) {
              RenderIcon = () => <IconPlaceholder small />
            }

            return (
              <AccordionCheckMark
                title={title}
                mobile
                open={title !== 'ASSET TOKENIZATION'}
                RenderIcon={RenderIcon}
                key={title}
              >
                {items && (
                  <LearnSlider noPaddingBottom={noPaddingBottomOnMobile}>
                    {items.map(
                      (
                        {
                          itemTitle,
                          itemTitleLarge,
                          url,
                          text,
                          itemActive,
                          stayOnHomepage,
                        },
                        index,
                      ) => (
                        <MobileSliderItem
                          key={index}
                          itemTitle={itemTitle || itemTitleLarge}
                          itemTitleLarge={itemTitleLarge}
                          categoryName={activeOption.title}
                          url={url}
                          active={itemActive}
                          text={text}
                          handleWatchMode={handleWatchMode}
                          stayOnHomepage={stayOnHomepage}
                        />
                      ),
                    )}
                  </LearnSlider>
                )}
              </AccordionCheckMark>
            )
          },
        )}
      </Container>
    )
  }
}

export default LearnSliderMobile

/*
 <AccordionCheckMark title="CATEGORY 1" mobile RenderIcon={IconPlaceholder}>
          <LearnSlider items={items} />
        </AccordionCheckMark>
        <AccordionCheckMark title="CATEGORY 2" mobile>
          <LearnSlider items={items} />
        </AccordionCheckMark>
        <AccordionCheckMark title="CATEGORY 3" mobile>
          <LearnSlider items={items} />
        </AccordionCheckMark>
*/
