// For mobile
const categoryList = [
  {
    title: 'ASSET TOKENIZATION',
    // disabled: true,
    id: 0,
    items: [
      {
        itemTitle: 'What is asset tokenization',
        url: 'asset-tokenization/what-is-asset-tokenization',
        text: `Asset tokenization is the process of converting the legal rights associated with real world assets (stocks, bonds, revenue) into digital tokens using Distributed Ledger Technology (DLT). `,
        id: 'what-is-asset-tokenization',
      },
      {
        itemTitle: 'Why tokenization',
        url: 'asset-tokenization/tokenization-overview',
        text: `Tokenization provides alternative investment funds, securitization vehicles, and other financial entities with a groundbreaking way to manage their investor registers using Distributed Ledger Technology (DLT).`,
        id: 'tokenisation-overview',
      },
      {
        itemTitle: 'Investor Whitelisting',
        url: 'asset-tokenization/investor-whitelisting',
        text: `Digital securities, a key innovation in real-world asset tokenization, require that all holders be whitelisted to ensure compliance and security.`,
        id: 'investor-whitelisting',
      },
    ],
  },
  {
    title: 'GETTING ON THE BLOCKCHAIN',
    // disabled: true,
    id: 1,
    items: [
      {
        itemTitle: 'Introduction to wallets and keys',
        url: 'getting-on-the-blockchain/introduction-to-wallets-and-keys',
        text: `In this section, we’ll delve into the essentials of engaging with blockchain wallets and understanding cryptocurrency transactions. Additionally, we’ll highlight best practices to ensure your safety.`,
        // tooltipContent: 'Wherever you go there you are',
        // itemDisabled: true,
        video: require('./img/video.mp4'),
        videoPoster: require('./img/videoposter.jpg'),
        id: 'blockchain-v1-introduction-to-wallets-and-keys',
      },
      {
        itemTitle: 'The different wallet types',
        url: 'getting-on-the-blockchain/the-different-wallet-types',
        text: `There are several ways in which wallets can be categorised and
          differentiated from one another. For the purposes of this 101,
          we will focus on the varying levels of security as the main
          distinguishing factor between wallet types, whilst also taking
          into account...`,
        // tooltipContent: 'Wherever you go there you are',
        // itemDisabled: true,
        video: require('./img/video.mp4'),
        videoPoster: require('./img/person.jpg'),
        id: 'blockchain-v1-the-different-wallet-types',
      },
      {
        itemTitle: 'Wallets supported by STOKR',
        url: 'getting-on-the-blockchain/wallets-supported-by-stokr',
        text: `To streamline your investment journey and reduce management costs, we offer a range of blockchain wallet options for your security tokens. If you’re a beginner, the SideSwap wallet comes highly recommended. For the seasoned investor, the Green Wallet is our top pick.`,
        // tooltipContent: 'Wherever you go there you are',
        // itemDisabled: true,
        video: require('./img/video.mp4'),
        videoPoster: require('./img/videoposter.jpg'),
        id: 'blockchain-v1-wallets-supported-by-stokr',
      },
      {
        itemTitle: 'Buying Crypto',
        url: 'getting-on-the-blockchain/buying-crypto',
        text: `The STOKR platform accepts Euro, USD Tether, on Bitcoin for crypto
        payments. When you use Euros as payment, there may be a day or two
        wait for bank transfers.`,
        // tooltipContent: 'Wherever you go there you are',
        // itemDisabled: true,
        video: require('./img/video.mp4'),
        videoPoster: require('./img/videoposter.jpg'),
        id: 'blockchain-v1-buying-crypto',
      },
      {
        itemTitle: 'Staying safe',
        url: 'getting-on-the-blockchain/staying-safe',
        text: `Finally, a subject we’ve touched upon previously: security. This
          is something you definitely don’t want to leave up to chance. In
          the pursuit of token security it’s important you know what to
          look out for, which is what the final part of this module is all
          about...`,
        // tooltipContent: 'Wherever you go there you are',
        // itemDisabled: true,
        video: require('./img/video.mp4'),
        videoPoster: require('./img/videoposter.jpg'),
        id: 'blockchain-v1-staying-safe',
      },
    ],
  },
]

export default categoryList
