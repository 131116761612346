import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { breakpoint, config } from 'utils'
import * as sc from 'constants/style'
import Breakdown from 'components/ui/breakdown'
import Headline from 'components/ui/headline'
import TrendingSlide from 'components/ui/trendingSlide'
import FadeIn from 'components/ui/fadeIn'
import BackgroundPattern from 'components/ui/backgroundPattern'
import fetchDataPublic from 'api/fetch-data-public'
import { ProjectStatus } from 'constants/enums'

const Container = styled.div``

const TrendingVenturesWrapper = styled.div``

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  grid-column-gap: 32px;
  grid-row-gap: 32px;

  justify-content: center;
  width: 100%;
  padding: 0 64px 48px;

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  }

  &::before {
    background: ${sc.BLUE_BASE_HEX};
    content: '';
    height: 264px;
    left: 0;
    position: absolute;
    top: -48px;
    width: 400px;

    @media screen and (max-width: 1600px) {
      width: 350px;
    }

    @media screen and (max-width: 1000px) {
      width: 300px;
    }

    @media screen and (max-width: 859px) {
      display: none;
    }
  }

  &::after {
    background: ${sc.GRAY_BASE_HEX};
    background-image: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);
    content: '';
    height: 100%;
    right: 0;
    position: absolute;
    top: -48px;
    width: 400px;

    @media screen and (max-width: 1600px) {
      width: 350px;
    }

    @media screen and (max-width: 1000px) {
      width: 300px;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`

const HeadlineMetaWrapper = styled.div`
  padding: 0 32px;

  @media screen and (max-width: ${config.mobile}) {
    padding-top: 40px;
  }

  @media screen and (min-width: ${config.tablet}) {
    padding-top: 92px;
    padding-bottom: 88px;
  }

  @media screen and (max-width: ${config.tablet}) {
    padding-top: 56px;
    padding-bottom: 66px;
  }

  @media screen and (min-width: 860px) {
    padding-top: 92px;
    padding-left: 64px;
    padding-right: 192px;
    padding-bottom: 120px;
  }

  ${Breakdown} {
    line-height: 1;
    margin-bottom: 28px;
  }

  ${Headline} {
    line-height: 0.8;
    margin-block-start: 40px;
    margin-block-end: 0;

    @media screen and (max-width: ${config.tablet}) {
      margin-block-start: 36px;
      line-height: 1.18;
    }

    @media screen and (max-width: ${config.mobile}) {
      line-height: 24px;
    }
  }
`

const StyledBackgroundPattern = styled(BackgroundPattern)`
  position: absolute;
  height: 116px;
  width: 100%;
  bottom: 0;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 31%;
    background: ${sc.RED_BASE_HEX};
    z-index: 1;

    ${breakpoint.below('tablet')} {
      width: 35%;
    }

    ${breakpoint.below('mobile')} {
      display: none;
    }
  }

  &::after {
    z-index: 0;
  }
`

const InnerBackground = styled.div`
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    background: ${sc.RED_BASE_HEX};
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 30%;
    border: solid transparent;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 0 300px 450px 0;
    border-color: transparent ${sc.RED_BASE_HEX} transparent transparent;
    transform: scaleX(-1);
    z-index: 1;

    ${breakpoint.below('tablet')} {
      left: 35%;
    }
    ${breakpoint.below('mobile')} {
      left: 0;
    }
  }
`

export function TrendingVentures() {
  const [trendingCards, setTrendingCards] = useState(null)

  const createCards = (projects) => {
    var newList = []

    var activeProjects = projects.filter(
      (project) =>
        project.status === ProjectStatus.ACTIVE ||
        project.status === ProjectStatus.ON_HOLD,
    )

    var sortedProjects = activeProjects.sort((a, b) => {
      return Number(a.trending_order) - Number(b.trending_order)
    })

    sortedProjects.forEach((project) => {
      project.modalDescription = `Register your interest to learn more about ${project.title}. Simply put your email id.`
      project.isStatic = project.is_static ? true : false
      project.active = true
      project.url = project.name
      project.src = project.coverimage
      project.order = project.trending_order
      project.platform = project.token_issuance_type
      project.text = project.excerpt
      project.modalTitle = project.title

      newList.push(project)
    })

    return newList
  }

  useEffect(() => {
    ;(async () => {
      const fetchedProjects = await fetchDataPublic('project/public/get')

      if (fetchedProjects && fetchedProjects.projects) {
        var createdCards = createCards(fetchedProjects.projects)

        setTrendingCards(createdCards)
      }
    })()
  }, [])

  return (
    <TrendingVenturesWrapper>
      <Container>
        {trendingCards && (
          <>
            <FadeIn delay={80}>
              <HeadlineMetaWrapper>
                <Breakdown>INVESTMENT OPPORTUNITIES</Breakdown>
                <Headline as="h2">FEATURED ASSETS</Headline>
              </HeadlineMetaWrapper>
            </FadeIn>
            <FadeIn delay={80} partialVisibility>
              <Grid>
                {trendingCards &&
                  trendingCards.map((card) => {
                    return <TrendingSlide key={card.title} project={card} />
                  })}
              </Grid>
              <StyledBackgroundPattern red>
                <InnerBackground />
              </StyledBackgroundPattern>
            </FadeIn>
          </>
        )}
      </Container>
    </TrendingVenturesWrapper>
  )
}

export default TrendingVentures
