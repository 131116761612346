import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Breakdown from 'components/ui/breakdown'

const colors = {
  active: '#000',
  inactive: '#F0F0F0',
}

const StyledWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
`

const StyledToggle = styled.div`
  width: 34px;
  height: 15px;
  margin: 0 8px;
  border-radius: 12px;
  background-color: ${colors.inactive};
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    top: -1px;
    width: 17px;
    height: 17px;
    background-color: ${colors.active};
    border-radius: 50%;
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);

    ${({ active }) =>
      active &&
      css`
        transform: translate3d(19px, 0, 0);
      `}
  }
`

const StyledLabel = styled(Breakdown)`
  color: ${colors.active};
  transition: color 0.4s ease;

  ${({ active }) =>
    active &&
    css`
      color: ${colors.inactive};
    `}
`

export class Toggle extends PureComponent {
  static propTypes = {
    value: PropTypes.bool,
    labelLeft: PropTypes.node.isRequired,
    labelRight: PropTypes.node.isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    value: undefined, // Force undefined
  }

  state = {
    value: false,
  }

  onChange = () => this.setState(({ value }) => ({ value: !value })) // Toggle value by default

  render() {
    const { value, labelLeft, labelRight, onChange } = this.props
    const internalValue = value === undefined ? this.state.value : value
    const internalOnChange = onChange || this.onChange

    return (
      <StyledWrapper onClick={internalOnChange}>
        <StyledLabel active={internalValue === false}>{labelLeft}</StyledLabel>
        <StyledToggle active={internalValue} />
        <StyledLabel active={internalValue === true}>{labelRight}</StyledLabel>
      </StyledWrapper>
    )
  }
}

export default Toggle
