/*  */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import breakpoint from 'utils/breakpoint'

export const Item = styled.li``
const Outer = styled.ul`
  list-style: none;
  padding-left: 0;

  margin: ${({ isMobile }) => isMobile && '0 !important'};
  border-top: ${({ borderTop }) => borderTop && '1px solid #e1e1e1'};

  ${({ borderTop }) =>
    borderTop &&
    css`
      @media screen and (max-width: 991px) {
        padding-top: 32px;
        width: 100vw;
        /* width: calc(100vw - 64px); */
      }
    `}
`

export class Menu extends PureComponent {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    isMobile: PropTypes.bool,
  }

  render() {
    const { children, className, isMobile, borderTop } = this.props

    return (
      <Outer className={className} isMobile={isMobile} borderTop={borderTop}>
        {children.map((item, index) => (
          <Item key={index}>{item}</Item>
        ))}
      </Outer>
    )
  }
}

export default Menu
