import React, { PureComponent } from 'react'
import styled from 'styled-components'

import EmojiPicker from 'components/ui/emojiPicker';

export const emoticonsImg = require('./img/emoticons.svg')

export const StyledEmojiButton = styled.button`
  background: transparent;
  border: none;
  margin-right: 7px;
  padding: 0;
  width: 26px;
  height: 26px;
  cursor: pointer;
  outline: none;
`

export const StyledEmojiWrapper = styled.div`
  position: relative;
`

class EmojiButton extends PureComponent {
  state = {
    active: false,
  }

  wrapperRef = React.createRef()

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  toggleActive = () => {
    this.setState(({ active }) => ({ active: !active }))
  }

  handleClickOutside = event => {
    const { active } = this.state

    if (active && this.wrapperRef.current) {
      const containsTarget = this.wrapperRef.current.contains(event.target)

      if (!containsTarget) {
        this.toggleActive()
      }
    }
  }

  render() {
    const { active } = this.state

    return (
      <StyledEmojiWrapper ref={this.wrapperRef}>
        <StyledEmojiButton onClick={this.toggleActive}>
          <img src={emoticonsImg} alt="Emoji" />
        </StyledEmojiButton>
        {active && (
          <EmojiPicker
            {...this.props}
            style={{
              position: 'absolute',
              left: 0,
              top: 'calc(100% + 10px)',
              zIndex: 999,
              textTransform: 'none',
              letterSpacing: 'initial',
            }}
          />
        )}
      </StyledEmojiWrapper>
    )
  }
}

export default EmojiButton;