import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import RichText from 'components/ui/richText'
import * as sc from 'constants/style'
import { TimelineHeadline, TimelineDate } from './commons'

const Section = styled.div`
  min-height: 258px;
  padding: 52px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ hideDecorations }) =>
    !hideDecorations &&
    css`
    /* &::before {
      content: '';
      position: absolute;
      width: 0;
      left: 50%;
      top: 0;
      bottom: 0;
      border-right: 1px dashed #e1e1e1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      left: 50%;
      top: 52px;
      margin-top: -2px;
      margin-left: -2px;
      border-radius: 50%;
      background: ${sc.GRAY_SECONDARY_HEX};
    } */
  `}
`

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  background: white;
  text-align: center;
  position: relative;

  ${RichText} {
    max-width: 50%;
    margin: 0 auto;
  }
`

export class TimelineMilestoneUpdate extends PureComponent {
  static propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.any,
    hideDecorations: PropTypes.bool,
  }

  render() {
    const { date, title, content, hideDecorations } = this.props

    return (
      <Section hideDecorations={hideDecorations}>
        <Container>
          <TimelineDate>{date}</TimelineDate>
          <TimelineHeadline as="h3">{title}</TimelineHeadline>
          <RichText>{content}</RichText>
        </Container>
      </Section>
    )
  }
}
