import Headline from 'components/ui/headline'
import Breakdown from 'components/ui/breakdown'
import styled from 'styled-components'

export const TimelineDate = styled(Breakdown)`
  font-weight: bold;
`

export const TimelineHeadline = styled(Headline)`
  /* margin-top: 20px; */
  margin-top: ${({ titleNoTopMargin }) => (titleNoTopMargin ? '0' : '20px')};
  margin-bottom: 20px;
  text-align: inherit;
`
