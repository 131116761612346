export const groupBlockContent = blockContent => {
  // const groupedContent = {};
  const groupedContent = []

  Object.keys(blockContent).forEach(item => {
    const indexOfUnderscore = item.indexOf('_')
    const itemIndex = item
      .split('')
      .slice(0, indexOfUnderscore)
      .join('')
    const itemName = item
      .split('')
      .slice(indexOfUnderscore + 1)
      .join('')

    if (groupedContent[itemIndex]) {
      groupedContent[itemIndex] = {
        ...groupedContent[itemIndex],
        [itemName]: blockContent[item],
      }
    } else {
      groupedContent.push({
        [itemName]: blockContent[item],
      })
    }
  })

  return groupedContent
}
