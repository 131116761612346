import styled from 'styled-components/macro'
import rwd from 'components/signup/styles/rwd'

const Text = styled.div`
  display: block;


  ${props => props.fullWidth && 'width: 100%;'}

  ${props =>
    props.center &&
    `
    text-align: center;
  `}

  ${props =>
    props.isConfirm &&
    `
    @media screen and (max-width: 767px) {
      padding-right: 50px;
      padding-top: 30px;
    }
  `}

  h1 {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 1.18;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-top: 1em;
    margin-bottom: 32px;

    ${rwd.Medium`
      font-size: 34px;
      letter-spacing: 1.5px;
      margin-bottom: 45px;
    `}
  }

  h2 {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 34px;
    line-height: 1.18em;
    letter-spacing: 1.5px;
    margin-top: 1em;
    margin-bottom: .18em;

    ${rwd.Medium`
      font-size: 42px;
    `}

    ${rwd.Large`
      font-size: 50px;
    `}
  }

  h4 {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 22px;
    line-height: 1.27em;
    letter-spacing: .8px;
    margin-top: 1em;
    margin-bottom: .27em;
    opacity: .5;

    ${rwd.Medium`
      font-size: 34px;
    `}
  }

  h3 {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 1.27em;
    letter-spacing: .8px;
    margin-top: 1em;
    margin-bottom: .27em;
    text-transform: uppercase;

    ${rwd.Medium`
      font-size: 34px;
    `}
  }

  h5 {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 22px;
    line-height: 1.27em;
    letter-spacing: .8px;
    text-transform: uppercase;
  }

  p {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: .6px;
    margin: 1em 0;
    color: rgba(0,0,0,0.8);
    -webkit-font-smoothing: antialiased;

    ${rwd.XLarge`
      font-size: 20px;
    `}

    a {
      text-decoration: underline;
      transition: color .2s;
      //color: ${props => props.theme.cPrimary};
      color: inherit;
      word-break: break-all !important;

      &:hover {
        color: ${props => props.theme.cText};
      }
    }
  }
  
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

const Caption = styled.p`
  &&& {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 9px;
    line-height: 1.78em;
    letter-spacing: 0.4px;

    ${rwd.Large`
      font-size: 12px;
    `}

    ${props => props.center && 'text-align: center;'}
    ${props => props.link && 'margin-top: 36px;'}
    
    a {
      text-decoration: underline;
      transition: color .2s;
      color: ${props => props.theme.cPrimary};

      &:hover {
        color: ${props => props.theme.cText};
      }
    }
  }
`

Text.Caption = Caption

export default Text
