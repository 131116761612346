import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Label, InputWrap } from './Input.styles'

class Input extends PureComponent {
  state = {
    labelUp: false,
    hasFocus: false,
  }

  componentDidMount() {
    this.checkLabel(false)
  }

  componentDidUpdate() {
    this.checkLabel(false)
  }

  onFocus = (e, field) => {
    const { onFocus } = this.props
    onFocus && onFocus(e, field)

    this.checkLabel(true)
  }

  onBlur = (e, field) => {
    const { onBlur } = this.props
    onBlur && onBlur(e, field)

    this.checkLabel(false)
  }

  onChange = (e, field) => {
    const { onChange } = this.props
    onChange && onChange(e, field)
  }

  checkLabel = focus => {
    const { value } = this.props

    this.setState({
      labelUp: focus || !!value,
      hasFocus: focus,
    })
  }

  render() {
    const { labelUp, hasFocus } = this.state
    const {
      type,
      id,
      name,
      label,
      placeholder,
      value,
      error,
      touched,
      readOnly,
      inverted,
      dashboard,
    } = this.props

    if (value && !labelUp) {
      this.setState({ labelUp: true })
    }

    return (
      <Wrapper inverted={inverted}>
        {label && (
          <Label
            isUp={labelUp}
            active={hasFocus}
            error={error && touched}
            htmlFor={id}
          >
            {label}
          </Label>
        )}
        <InputWrap error={error && touched} dashboard={dashboard}>
          <input
            type={type}
            id={id}
            name={name}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            readOnly={readOnly}
            placeholder={placeholder}
            style={{WebkitAppearance:'none', WebkitBorderRadius: 0}}
          />
        </InputWrap>
      </Wrapper>
    )
  }
}

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  inverted: PropTypes.bool,
  dashboard: PropTypes.bool,
}

Input.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  value: '',
  error: false,
  touched: false,
  readOnly: false,
  inverted: false,
  dashboard: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
}

export default Input
