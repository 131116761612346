import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import AvatarAndName from 'components/ui/comments/AvatarAndName'
import Breakdown from 'components/ui/breakdown'
import BackgroundPattern from 'components/ui/backgroundPattern'
import Button from 'components/ui/button'
import Icon from 'components/ui/icon'
import Info from 'components/ui/info'
import Checkbox from 'components/ui/checkbox'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

/**
 * Enums and configs
 */
export const Steps = {
  DEFAULT: 0,
  CONFIRM: 1,
  PUBLISHED: 2,
}

const config = {
  maxLength: 280,
}

/**
 * Styles for sidebar
 */
const StyledBackgroundPattern = styled(BackgroundPattern)`
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background: white;
    z-index: -1;
  }

  &::after {
    z-index: -2;
  }
`

const SidebarContainer = styled.div`
  width: 100%;

  ${Breakdown} {
    margin-bottom: 24px;
    text-align: center;
  }
`

const SidebarSubmit = styled(Button)`
  margin: 0 auto 24px;

  ${breakpoint.below('mobile')} {
    padding: 0 16px;
    margin-left: 4px;
    margin-right: 4px;
  }
`

const SidebarCancel = styled(Button)`
  margin: 0 auto 16px;

  ${breakpoint.below('mobile')} {
    padding: 0 16px;
    margin-left: 4px;
    margin-right: 4px;
  }
`

const SmallText = styled.div`
  color: #9b9b9b;
  font-size: 9px;
  letter-spacing: 0.4px;
  line-height: 16px;
`

const RelativeWrapper = styled.div`
  position: relative;
`

const MessageContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`

const SidebarCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  label {
    margin-right: 8px;
  }
`

const SuccessMessage = styled.strong`
  span {
    white-space: normal;
    font-weight: 700;
    font-size: 11px;
    display: block;
    margin: 0 auto;

    ${breakpoint.below('mobile')} {
      width: 60%;
      min-width: 150px;
    }
  }
`

const SuccessIcon = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 16px auto 0;
  background: ${sc.BLUE_BASE_HEX};
`

/**
 * Components for sidebar
 */
class SidebarDefault extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    notifyInvestors: PropTypes.bool,
    onNotifyChange: PropTypes.func,
  }

  render() {
    const { notifyInvestors, onNotifyChange, disabled } = this.props

    return (
      <SidebarContainer>
        <SidebarCheckbox>
          <Checkbox
            label="Notify all investors"
            value={notifyInvestors}
            onChange={onNotifyChange}
          />
          <Info content="Lorem ipsum dolor sit amet consectetur edipiscing elit" />
        </SidebarCheckbox>
        <SidebarSubmit disabled={disabled}>
          <FormattedMessage id="button.update" />
        </SidebarSubmit>
      </SidebarContainer>
    )
  }
}

class SidebarConfirm extends PureComponent {
  static propTypes = {
    notifyInvestors: PropTypes.bool,
  }

  render() {
    const { notifyInvestors } = this.props

    return (
      <SidebarContainer>
        <strong>
          <Breakdown>
            <FormattedMessage id="updates.message.confirmation" />
          </Breakdown>
        </strong>
        <SidebarCancel transparent>
          <FormattedMessage id="button.cancel" />
        </SidebarCancel>
        <SidebarSubmit>
          <FormattedMessage id="button.update" />
        </SidebarSubmit>
        {notifyInvestors && (
          <SmallText>
            <FormattedMessage id="updates.message.notifyInvestors" />
          </SmallText>
        )}
      </SidebarContainer>
    )
  }
}

class SidebarPublished extends PureComponent {
  render() {
    return (
      <SidebarContainer>
        <SuccessMessage>
          <Breakdown>
            <FormattedMessage id="updates.message.published" />
          </Breakdown>
          <SuccessIcon>
            <Icon name="Check" />
          </SuccessIcon>
        </SuccessMessage>
      </SidebarContainer>
    )
  }
}

/**
 * Styles for TimelineVentureMessage component
 */
const Section = styled.div`
  margin: 0 -65px;
  width: calc(100% + 65px + 65px);

  ${breakpoint.below('mobile')} {
    margin: 0 -32px;
    width: calc(100% + 32px + 32px);
  }
`

const Container = styled.div`
  padding: 64px 65px 96px;

  ${breakpoint.below('mobile')} {
    padding-left: 32px;
    padding-right: 32px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    border: solid transparent;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 0 300px 450px 0;
    border-color: transparent ${sc.WHITE_HEX} transparent transparent;
    transform: scaleX(-1);
    z-index: -1;

    ${breakpoint.below('tablet')} {
      border-width: 0 400px 600px 0;
    }
  }
`

const MessageWrapper = styled.div`
  display: flex;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  position: relative;

  ${breakpoint.below('mobile')} {
    display: block;
  }

  &::before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 100%;
    height: 96px;
    width: 1px;
    background: #e1e1e1;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - 7px);
    z-index: -1;
    border: 8px solid transparent;
    border-bottom: none;
    border-top-color: ${sc.BLUE_BASE_HEX};
  }
`

const MessageContainer = styled.div`
  flex: 1 0 75%;
  display: flex;
  flex-direction: column;

  ${breakpoint.below('tablet')} {
    flex: 1 0 68%;
  }
`

const MessageTitle = styled.input`
  display: block;
  width: 100%;
  height: 72px;
  line-height: 72px;
  padding: 0 73px 0 32px;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
  color: ${sc.BASE_COLOR_HEX};

  &:focus {
    outline: none;
  }
`

const MessageContent = styled.textarea`
  display: block;
  flex: 1 0 auto;
  width: 100%;
  line-height: 1.2;
  padding: 25px 73px 10px 32px;
  border: none;
  font-size: 14px;
  color: #202020;
  resize: none;

  &:focus {
    outline: none;
  }
`

const StyledCharacterCount = styled.div`
  color: #c6c2c2;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 1.65px;
  text-transform: uppercase;
  padding: 16px 32px 22px;
`

const CharacterCount = ({ length, maxLength }) => (
  <StyledCharacterCount>
    {length}/{maxLength} characters
  </StyledCharacterCount>
)

const MessageSidebar = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1 25%;
  max-width: 190px;
  border-left: 1px solid #e1e1e1;
  padding: 32px 19px;
  text-align: center;

  ${breakpoint.below('tablet')} {
    flex: 1 1 32%;
  }

  ${breakpoint.below('mobile')} {
    border-left: none;
    border-top: 1px solid #e1e1e1;
    width: 100%;
    max-width: none;
  }
`

const TitleInfo = styled.div`
  position: absolute;
  right: 32px;
  top: 28px;
`

const ContentInfo = styled.div`
  position: absolute;
  right: 32px;
  top: 28px;
`

/**
 * Main component -> TimelineVentureMessage
 */
export class TimelineVentureMessage extends PureComponent {
  static propTypes = {
    ventureName: PropTypes.string.isRequired,
    venturePicture: PropTypes.string,
    step: PropTypes.number,
  }

  state = {
    step: this.props.step || Steps.DEFAULT,
    notifyInvestors: true,
    title: '',
    content: '',
  }

  onTitleChange = event => this.setState({ title: event.target.value })

  onContentChange = event =>
    this.setState({
      content: event.target.value.substr(0, config.maxLength), // Limit length
    })

  onNotifyChange = () => {
    this.setState(({ notifyInvestors }) => ({
      notifyInvestors: !notifyInvestors,
    }))
  }

  renderSidebar = ({ notifyInvestors }) => {
    const { step, title, content } = this.state

    switch (step) {
      case Steps.CONFIRM:
        return <SidebarConfirm notifyInvestors={notifyInvestors} />

      case Steps.PUBLISHED:
        return <SidebarPublished />

      default:
      case Steps.DEFAULT:
        return (
          <SidebarDefault
            disabled={content.length === 0 || title.length === 0}
            notifyInvestors={notifyInvestors}
            onNotifyChange={this.onNotifyChange}
          />
        )
    }
  }

  render() {
    const { ventureName, venturePicture } = this.props
    const { notifyInvestors, title, content } = this.state

    return (
      <Section>
        <StyledBackgroundPattern>
          <Container>
            <AvatarAndName picture={venturePicture} name={ventureName} />
            <MessageWrapper>
              <MessageContainer>
                <RelativeWrapper>
                  <MessageTitle
                    placeholder="Enter a title for your update"
                    value={title}
                    type="text"
                    onChange={this.onTitleChange}
                  />
                  <TitleInfo>
                    <Info content="Lorem ipsum dolor sit amet consectetur edipiscing elit" />
                  </TitleInfo>
                </RelativeWrapper>
                <MessageContentWrapper>
                  <MessageContent
                    value={content}
                    onChange={this.onContentChange}
                    placeholder="Give your followers the latest buzz"
                    maxLength={config.maxLength}
                  />
                  <CharacterCount
                    length={content.length}
                    maxLength={config.maxLength}
                  />
                  <ContentInfo>
                    <Info content="Lorem ipsum dolor sit amet consectetur edipiscing elit" />
                  </ContentInfo>
                </MessageContentWrapper>
              </MessageContainer>
              <MessageSidebar>
                {this.renderSidebar({ notifyInvestors })}
              </MessageSidebar>
            </MessageWrapper>
          </Container>
        </StyledBackgroundPattern>
      </Section>
    )
  }
}
