import React from 'react'
import check from './img/checkmark.svg'
import checkLarge from './img/checkmarkLarge.svg'
import Placeholder from 'components/101/Placeholder'

export { check, checkLarge }

const IconPlaceholder = ({ disabled, large, small, active }) => {
  if (small && active) {
    return (
      <Placeholder small={small} active={active}>
        <img src={check} alt="check" />
      </Placeholder>
    )
  }
  if (large && active) {
    return (
      <Placeholder active={active} large={large}>
        <img src={checkLarge} alt="check large" />
      </Placeholder>
    )
  }
  return <Placeholder disabled={disabled} small={small} large={large} />
}

export default IconPlaceholder;