import React, { PureComponent } from 'react'
//import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

export class EmojiPicker extends PureComponent {
  render() {
    return <Picker set="apple" {...this.props} />
  }
}

export default EmojiPicker
