import styled from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const StyledDotsContainer = styled.ul`
  list-style: none;
  margin: 0;
  position: absolute;
  padding: ${sc.BASE_MARGIN}px;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  width: auto;

  ${breakpoint.below('tablet')} {
    padding: 12px ${sc.BASE_MARGIN}px;
  }

  li {
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    cursor: default !important;
  }
  &&& {
    ${props => props.dotsStyle}
  }
`

export default StyledDotsContainer
