import React, { memo } from 'react'
import styled from 'styled-components'
import Tooltip from 'components/ui/tooltip'
import Icon from 'components/ui/icon'

const StyledTooltip = styled.span`
  font-size: inherit;
  line-height: 0.8em;
  display: inline-flex;
  align-items: center;
  cursor: help;
`

export const Info = memo(props => (
  <StyledTooltip>
    <Tooltip {...props}>
      <Icon name="Info" />
    </Tooltip>
  </StyledTooltip>
))

Info.propTypes = {}

export default Info
