import styled from 'styled-components'

export const FadedContent = styled.div`
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-exit {
    display: none !important;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
`

export default FadedContent
