import React, { PureComponent } from 'react'
// import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'
import Headline from 'components/ui/headline'
import RichText from 'components/ui/richText'
import Button from 'components/ui/button'
import Progress from 'components/ui/progress'
import NewVentureModal from 'components/modals/NewVentureModal/NewVentureModal'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import liqiudLogo from 'images/liquid-icon.svg'

import algoLogo from 'images/algo-logo.png'
import ethLogo from 'images/eth-logo.svg'
import retailInvestors from 'images/retail-inv.svg'
import professionalInvestors from 'images/prof-inv.svg'
import { ProjectStates, ProjectTypes } from 'constants/enums'
import Tooltip from '../tooltip'
import { generateProjectState, getTrancheState } from 'utils/getProjectState'
import { km_ify, AuthContext } from '@stokr/components-library'
import { checkSaleTimeLeft } from '@stokr/components-library/dist/utils/check-sale-time-left'

const Slide = styled.section`
  z-index: 2;
  max-width: 600px;

  @media screen and (max-width: 1760px) {
    display: ${({ fourthCard }) => fourthCard && 'none'};
  }

  @media screen and (max-width: 1750px) {
    display: ${({ thirdCard }) => thirdCard && 'none'};
  }

  @media screen and (max-width: 850px) {
    display: ${({ thirdCard }) => thirdCard && 'none'};
  }

  ${Button} {
    ${({ inactive }) =>
      inactive &&
      css`
        opacity: 0.3;
      `}
  }

  ${({ inactive }) =>
    inactive &&
    css`
      @media screen and (max-width: 1241px) {
        display: none;
      }

      @media screen and (min-width: 1624px) {
        display: none;
      }
    `}
`

const SliderBackgroundContainer = styled.div`
  background: white; /* Used to hide de decorative bars behind the placeholder cards image */
`

const SliderBackground = styled.section`
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 0.2s ease-out;

  height: 300px;

  @media screen and (min-width: 550px) and (max-width: 859px) {
    height: 350px;
  }

  @media screen and (max-width: 549px) {
    height: 250px;
  }

  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.3;
    `}
`

const SlideDetails = styled.section`
  width: 100%;
  min-height: 340px; /* default minus DetailsBlockWrapper height */
  background: white;
  border-top: none;

  @media screen and (max-width: 1315px) {
    min-height: 331px;
  }
`

const DetailsHeader = styled.section`
  min-height: 175px;
  padding-bottom: 33px;
  padding-top: 20px;
  padding-left: 31px;
  padding-right: 31px;
  border-left: 1px solid ${sc.GRAY_BASE_HEX};
  border-right: 1px solid ${sc.GRAY_BASE_HEX};

  ${Headline} {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 549px) {
    min-height: 175px;
    padding-bottom: 21px;
    padding-top: 21px;
  }
`

const DetailsBlockWrapper = styled.section`
  min-height: 104px;
  display: flex;
`

const DetailsBlock = styled.section`
  flex: 1;
  background: white;
  border-top: 1px solid ${sc.GRAY_BASE_HEX};
  padding: 0 20px;
  padding-top: 28px;
  padding-bottom: 32px;

  &:first-of-type {
    border-left: 1px solid ${sc.GRAY_BASE_HEX};
  }
  &:nth-last-of-type(2) {
    border-left: 1px solid ${sc.GRAY_BASE_HEX};
    border-right: 1px solid ${sc.GRAY_BASE_HEX};
  }
  &:last-of-type {
    border-right: 1px solid ${sc.GRAY_BASE_HEX};
  }
  ${RichText} {
    text-align: center;
    &:first-of-type {
      margin-bottom: 20px;
      font-size: 11px;
      line-height: 12px;
      min-height: 25px;
    }
    &:last-of-type {
      font-size: 22px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 400px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const progressStyle = css`
  border: 1px solid #dadada;
`

const DetailsCapital = styled.section`
  height: 100%;
  min-height: 180px;
  background: ${({ active }) => (active ? sc.BLUE_BASE_HEX : 'white')};
  border: ${({ active }) => !active && `1px solid ${sc.GRAY_BASE_HEX}`};
  padding: 26px 32px 32px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background: ${({ active }) => active && sc.BLUE_BASE_RELEASED_HEX};
  }
  ${RichText} {
    text-align: center;
    font-size: 11px;
    color: ${({ active }) => active && sc.GRAY_BASE_HEX};
    margin-bottom: 22px;
  }

  ${Button} {
    margin-top: 32px;
    left: 50%;
    transform: translateX(-50%);
  }

  a:hover {
    opacity: 1;
  }
`

const Status = styled.span`
  display: inline-block;
  vertical-align: top;
  padding: 2px 16px;
  background-color: #88fa51;
  color: #202020;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1.78px;
  line-height: 16px;
  border-radius: 8px;
  // margin-left: 12px;
  // margin-top: 4px;
  //margin-bottom:10px;
  text-transform: uppercase;

  ${breakpoint.below('mobile')} {
    max-width: 50%;
  }
`
const StatusBar = styled.div`
  min-height: 30px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
`

export class TrendingSlide extends PureComponent {
  state = {
    hardCap: 0,
    softCap: 0,
    privateSale: 0,
    softCapPercentage: 0,
    publicSalePercentage: 0,
    privateSalePercentage: 0,
    isActionLoading: false,
    isModalOpen: false,
    popupSuccess: '',
    popupError: '',
    visibleBlock: false,
    animation: false,
    showProgress: false,
    imageLoaded: false,
    shareText: 'PROFIT SHARE',
    detailsblockData: {
      firstBlockTitle: '-',
      firstBlockValue: '-',
      secondBlockTitle: '-',
      secondBlockValue: '-',
      thirdBlockTitle: '-',
      thirdBlockValue: '-',
    },
  }

  static contextType = AuthContext

  generateDetailsBlockData = (project) => {
    const {
      type,
      sharePercentage,
      investmentCount,
      name,
      shareType,
      totalSold,
      sales,
      tokenCurrency,
    } = project

    const currencySign =
      tokenCurrency === 'EUR' ? '€' : tokenCurrency === 'USD' ? '$' : ''

    let detailsblockData = {
      firstBlockTitle: '-',
      firstBlockValue: '-',
      secondBlockTitle: shareType?.toUpperCase(),
      secondBlockValue: sharePercentage || '-',
      thirdBlockTitle: 'INVESTORS',
      thirdBlockValue: investmentCount || '-',
    }

    if (type === ProjectTypes.COMPANY || type === ProjectTypes.TRANCHES) {
      //days left
      let { daysLeft, daysLeftText } = checkSaleTimeLeft(project)
      if (!daysLeft || isNaN(daysLeft) || daysLeft < 0) {
        daysLeft = '-'

        if (daysLeftText !== 'FUNDING CLOSED') daysLeftText = 'DAYS UNTIL START'
      }

      if (daysLeftText === 'FUNDING CLOSED') {
        detailsblockData.firstBlockTitle = 'AMOUNT RAISED'
        detailsblockData.firstBlockValue = totalSold
          ? `${currencySign}${km_ify(totalSold)}`
          : '-'
      } else {
        detailsblockData.firstBlockTitle = daysLeftText
        detailsblockData.firstBlockValue = daysLeft
      }

      //Min investment in the sales array
      let minInvestment = sales?.find(
        (sale) => sale.isVariable,
      )?.purchaseMinimum

      let isTrancheClosed = false
      //we need to check if the tranche is closed to display the correct value in details
      if (type === ProjectTypes.TRANCHES) {
        let trancheState = getTrancheState(project)
        if (trancheState.includes(ProjectStates.CLOSED)) {
          isTrancheClosed = true
        }
      }

      //until the offering closes, the third box of the homepage card shows ‘MIN INVESTMENT’
      //after the offering closes, the third box of the homepage card shows ‘INVESTORS’
      if (project.hasClosed || isTrancheClosed) {
        detailsblockData.thirdBlockTitle = 'INVESTORS'
        detailsblockData.thirdBlockValue = investmentCount || '-'
      } else {
        detailsblockData.thirdBlockTitle = 'MIN INVESTMENT'
        detailsblockData.thirdBlockValue = minInvestment
          ? `${currencySign}${km_ify(minInvestment)}`
          : '-'
      }
    } else if (type === ProjectTypes.FUND) {
      // const oneDay = 1000 * 60 * 60 * 24
      // daysLeft = Math.round(
      //   (new Date() - new Date(project.startingDate)) / oneDay,
      // )
      detailsblockData.firstBlockTitle = 'SUBSCRIPTION'
      detailsblockData.firstBlockValue = 'monthly'
      detailsblockData.secondBlockTitle = 'REDEMPTION'
      detailsblockData.secondBlockValue = 'quarterly'
      detailsblockData.thirdBlockTitle = 'MIN INVESTMENT'
      detailsblockData.thirdBlockValue = `${currencySign}125K`

      if (name === 'microstrategy') {
        detailsblockData.firstBlockTitle = 'UNDERLYING'
        detailsblockData.firstBlockValue = 'MSTR'
        detailsblockData.secondBlockTitle = 'REDEEMABLE'
        detailsblockData.secondBlockValue = 'yes'
        detailsblockData.thirdBlockTitle = 'MATURITY'
        detailsblockData.thirdBlockValue = `none`
      }
    }

    //there is still parkingo sto which is hardcoded
    //should be deleted?
    if (name === 'parkingo') {
      detailsblockData.firstBlockTitle = 'DAYS LEFT'
      detailsblockData.firstBlockValue = '-'
      detailsblockData.thirdBlockValue = '-'
    }

    if (name === 'nexus') {
      //Number of property is the number of sales
      let numberOfProperties = sales?.length || 0

      detailsblockData.firstBlockTitle = 'NUMBER OF PROPERTIES'
      detailsblockData.firstBlockValue = numberOfProperties.toString()
    }

    if (name === 'global-cannabis-holdings') {
      detailsblockData.firstBlockValue = '0'
      detailsblockData.thirdBlockValue = '0'
      project.totalSold = 0
    }

    if (name === 'blockstream-mining') {
      detailsblockData.thirdBlockTitle = 'PAYOUT'
      detailsblockData.thirdBlockValue = `${km_ify(1220)} ₿`
    }

    this.setState({
      detailsblockData,
    })
  }

  generateProgressBarData = (data) => {
    if (data) {
      const hardCap = data.greenCap + data.whiteCap
      if (data.type === ProjectTypes.FUND) {
        this.setState({
          hardCap,
          softCap: data.totalSoftCap,
          //capitalRaised: data.totalSold, //1140773
          privateSale: data.whiteSold,
          softCapPercentage: (data.totalSoftCap / hardCap) * 100,
          publicSalePercentage: 100,
          privateSalePercentage: 0,
        })
      } else {
        if (data.name === 'global-cannabis-holdings') {
          this.setState({
            // hardCap:,
            softCap: 0,
            // capitalRaised: data.totalSold,
            privateSale: 0,
            softCapPercentage: 0,
            publicSalePercentage: 0,
            privateSalePercentage: 0,
          })
        } else {
          this.setState({
            // hardCap:,
            softCap: data.totalSoftCap,
            // capitalRaised: data.totalSold,
            privateSale: data.whiteSold,
            softCapPercentage: (data.totalSoftCap / hardCap) * 100,
            publicSalePercentage: (data.greenSold / data.greenCap) * 100,
            privateSalePercentage: (data.whiteSold / data.whiteCap) * 100,
          })
        }
      }
    }
  }
  async componentDidMount() {
    const { project } = this.props

    try {
      if (project.url) {
        this.generateProgressBarData(project)
        this.generateDetailsBlockData(project)
      }
    } catch (e) {
      console.log(e)
    }
  }

  getCustomStyle = () => {
    const { type } = this.props.project
    //we add custom style to prevent overflow in the cards
    if (type === ProjectTypes.FUND) {
      return { fontSize: `calc(100% + 0.6vmin)` }
    }
    return {}
  }

  resetModalState = () => {
    this.setState(
      {
        isModalOpen: false,
      },
      () => {
        // This callback prevents the modal of showing
        // its initial page right before closing
        setTimeout(() => {
          this.setState({
            isActionLoading: false,
            popupSuccess: '',
            popupError: '',
          })
        }, 500)
      },
    )
  }

  handleVisibilityChange = (isVisible) => {
    this.setState({ visibleBlock: isVisible })

    //re-render animation in the given interval if the block is currently visible
    if (isVisible) {
      //on first render set to true to start animation
      if (!this.state.animation) {
        this.setState({ animation: true, showProgress: true })
      }

      //set image loaded to true on first visibility
      if (!this.state.imageLoaded) {
        this.setState({ imageLoaded: true })
      }

      //on every 3s change animation(false) but show progress bar until next animation change occurs(true)
      this.reAnimateProgress = setInterval(() => {
        this.setState({
          animation: !this.state.animation,
          showProgress: true,
        })
      }, 6000)

      this.reAnimateProgress2 = setInterval(() => {
        this.setState({ showProgress: !this.state.showProgress })
      }, 11900)
    } else {
      clearInterval(this.reAnimateProgress)
      clearInterval(this.reAnimateProgress2)
    }
  }

  render() {
    const { project } = this.props
    const {
      active,
      inactive,
      src,
      title,
      text,
      modalTitle,
      modalDescription,
      url,
      platform,
      isStatic,
      type,
    } = project

    const {
      privateSalePercentage,
      publicSalePercentage,
      softCapPercentage,
      isActionLoading,
      isModalOpen,
      popupSuccess,
      popupError,
      visibleBlock,
      animation,
      showProgress,
      detailsblockData,
      imageLoaded,
    } = this.state

    const platformLogo =
      platform === 'liquid'
        ? liqiudLogo
        : platform === 'ethereum'
        ? ethLogo
        : platform === 'algorand'
        ? algoLogo
        : ''
    const investorsLogo =
      url === 'blockstream-basic'
        ? professionalInvestors
        : type === ProjectTypes.COMPANY
        ? retailInvestors
        : type === ProjectTypes.FUND || type === ProjectTypes.TRANCHES
        ? professionalInvestors
        : ''
    const investorsLogoTooltip =
      investorsLogo === retailInvestors
        ? 'Retail Offer'
        : investorsLogo === professionalInvestors
        ? 'Professional Investor Offer'
        : ''

    const tooltipStyle = {
      padding: 8,
      minWidth: 'auto',
      // width: 'auto',
      maxWidth: 225,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    }
    return (
      <Slide inactive={inactive}>
        {/* {!active && <InactiveCardOverlay />} */}
        <VisibilitySensor
          partialVisibility
          onChange={this.handleVisibilityChange}
        >
          <>
            <SliderBackgroundContainer>
              <SliderBackground
                style={{
                  backgroundImage:
                    visibleBlock || imageLoaded ? `url(${src})` : 'none',
                  opacity: visibleBlock || imageLoaded ? 1 : 0.3,
                }}
                //src={visibleBlock ? src : null}
                inactive={inactive}
              />
            </SliderBackgroundContainer>
            <SlideDetails>
              <DetailsHeader>
                <StatusBar>
                  <Status data-cy="projectState">
                    {generateProjectState(project)}
                  </Status>
                  <div
                    style={{
                      width: 80,
                      display: 'flex',
                      justifyContent: 'space-between',
                      maxHeight: 30,
                    }}
                  >
                    <Tooltip
                      data-cy="investorTooltip"
                      position="top"
                      content={investorsLogoTooltip}
                      theme="light"
                      type="inverted"
                      arrow
                      duration={200}
                      contentStyle={tooltipStyle}
                      hover
                    >
                      <img
                        data-cy="investorLogo"
                        src={investorsLogo}
                        alt="investor-logo"
                        width="28"
                        height="28"
                        loading="lazy"
                      />
                    </Tooltip>

                    <Tooltip
                      position="top"
                      content={`${
                        platform[0].toUpperCase() + platform.slice(1)
                      } Network`}
                      theme="light"
                      type="inverted"
                      arrow
                      duration={200}
                      contentStyle={tooltipStyle}
                      hover
                    >
                      <img
                        src={platformLogo}
                        alt="platform-logo"
                        width="28"
                        height="28"
                        loading="lazy"
                      />
                    </Tooltip>
                  </div>
                </StatusBar>
                <Headline as="h4" inactive={inactive} stoThumbnailHeading>
                  {title}
                </Headline>
                <RichText inactive={inactive}>{text}</RichText>
              </DetailsHeader>
              <DetailsBlockWrapper>
                <DetailsBlock>
                  <RichText
                    inactive={inactive}
                    style={{ marginBottom: '' }}
                    data-cy="firstBlockTitle"
                  >
                    {detailsblockData.firstBlockTitle}
                  </RichText>
                  <RichText inactive={inactive} style={this.getCustomStyle()}>
                    {!isNaN(detailsblockData.firstBlockValue) &&
                    project.name !== 'nexus' ? (
                      <>
                        {visibleBlock && (
                          <CountUp
                            end={detailsblockData.firstBlockValue}
                            duration={11.9}
                            //delay={0.25}
                            //easingFn={this.easingFn}

                            separator=","
                            onEnd={({ pauseResume, reset, start, update }) => {
                              if (visibleBlock) start()
                            }}
                          />
                        )}
                      </>
                    ) : (
                      detailsblockData.firstBlockValue
                    )}
                  </RichText>
                </DetailsBlock>
                <DetailsBlock>
                  <RichText inactive={inactive} data-cy="secondBlockTitle">
                    {detailsblockData.secondBlockTitle}
                  </RichText>
                  <RichText inactive={inactive} style={this.getCustomStyle()}>
                    {detailsblockData.secondBlockValue}
                  </RichText>
                </DetailsBlock>
                <DetailsBlock>
                  <RichText
                    inactive={inactive}
                    style={{ marginBottom: '' }}
                    data-cy="thirdBlockTitle"
                  >
                    {detailsblockData.thirdBlockTitle}
                  </RichText>
                  <RichText inactive={inactive} style={this.getCustomStyle()}>
                    {!isNaN(detailsblockData.thirdBlockValue) ? (
                      <>
                        {visibleBlock && (
                          <CountUp
                            end={Number(detailsblockData.thirdBlockValue)}
                            duration={11.9}
                            //delay={0.25}
                            easingFn={easingFn}
                            separator=","
                            onEnd={({ pauseResume, reset, start, update }) => {
                              if (visibleBlock) start()
                            }}
                          />
                        )}
                      </>
                    ) : (
                      detailsblockData.thirdBlockValue
                    )}
                  </RichText>
                </DetailsBlock>
              </DetailsBlockWrapper>
              <DetailsCapital active={active}>
                <RichText
                  inactive={inactive}
                  data-cy="amountRaised"
                  style={{ fontSize: 12 }}
                >
                  {generateOfferingStatus(project)}
                </RichText>
                <Progress
                  //privateInvestment={0} // private sale
                  privateInvestment={privateSalePercentage} // private sale
                  success={publicSalePercentage} // public sale
                  animation={animation}
                  showProgress={showProgress}
                  empty={
                    //softcap
                    softCapPercentage -
                    privateSalePercentage -
                    publicSalePercentage
                  } // soft cap
                  disabled={100} // hard cap
                  progressStyle={!active && progressStyle}
                  inactive={inactive}
                />
                {active && !isStatic ? (
                  <a href={`https://stokr.io/${url}`}>
                    <Button color="white" data-cy="cardButton">
                      Full details
                    </Button>
                  </a>
                ) : active && isStatic ? (
                  <Button
                    data-cy="cardButton"
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation()

                      this.setState({ isModalOpen: true })
                    }}
                  >
                    I'm Interested
                  </Button>
                ) : (
                  <Button inactive={inactive}>More soon</Button>
                )}
                <RichText
                  style={{
                    letterSpacing: 3,
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    marginTop: 10,
                    width: '100%',
                  }}
                >
                  {' '}
                  {platform && `POWERED BY ${platform.toUpperCase()}`}
                </RichText>
              </DetailsCapital>
            </SlideDetails>
          </>
        </VisibilitySensor>

        {isModalOpen && (
          <NewVentureModal
            title={project.title}
            description={
              'Register your interest and learn more about this investment opportunity.'
            }
            user={this.context.user}
            project={project}
            isModalOpen={isModalOpen}
            popupError={popupError}
            popupSuccess={popupSuccess}
            onModalClose={(e) => {
              e.stopPropagation()

              this.resetModalState()
            }}
            onFormSend={async ({ email, allowcontact }) => {}}
          />
        )}
      </Slide>
    )
  }
}

const easingFn = (t, b, c, d) => {
  // t: current time, b: begInnIng value, c: change In value, d: duration
  //console.log('t: ', t, ' b: ', b)
  // console.log('c: ', c, ' d: ', d)
  //console.log('EaseinExpo: ', c * Math.pow(2, 10 * (t / d - 1)) + 1)

  const ts = (t /= d) * t
  const tc = ts * t
  //return c*(t/=d)*t*t + b;
  return b + c * (tc + -6 * ts + 6 * t)
}

const generateOfferingStatus = (project) => {
  const { type, totalSold, tokenCurrency } = project
  const currencySign =
    tokenCurrency === 'EUR' ? '€' : tokenCurrency === 'USD' ? '$' : ''
  if (type === ProjectTypes.TRANCHES || type === ProjectTypes.COMPANY) {
    return totalSold
      ? `${currencySign} ${km_ify(totalSold)} RAISED`
      : 'CAPITAL RAISED'
  } else if (type === ProjectTypes.FUND && project.name !== 'microstrategy') {
    return totalSold ? `NAV ${currencySign} ${km_ify(totalSold)}` : ''
  } else return ''
}

TrendingSlide.displayName = 'TrendingSlide'
export default TrendingSlide
