import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RichText from 'components/ui/richText'
import { TimelineHeadline, TimelineDate } from './commons'

const Section = styled.div`
  padding: 128px 0;
  position: relative;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 128px; /* padding */
    left: 50%;
    top: 0;
    border-right: 1px solid #e1e1e1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 128px; /* padding */
    left: 50%;
    bottom: 0;
    border-right: 1px dashed #e1e1e1;
  }
`

const Circle = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  background: white;
  text-align: center;
  position: relative;
`

const imgCircle = require('./img/STO_circle.svg')
const imgCircleBlue = require('./img/STO_circle_blue.svg')

export class TimelineSTOStart extends PureComponent {
  static propTypes = {
    date: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.any,
    started: PropTypes.bool,
  }

  render() {
    const { date, title, content, started } = this.props

    return (
      <Section>
        <Circle src={started ? imgCircleBlue : imgCircle} alt="" />
        <Container>
          <TimelineDate>{date}</TimelineDate>
          <TimelineHeadline as="h3">{title}</TimelineHeadline>
          <RichText>{content}</RichText>
        </Container>
      </Section>
    )
  }
}
