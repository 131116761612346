import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpoint } from 'utils'

import * as sc from 'constants/style'

const withMarginsCss = css`
  padding-left: ${sc.BASE_MARGIN * 2}px;
  padding-right: ${sc.BASE_MARGIN * 2}px;

  ${breakpoint.below('tablet')} {
    padding: 0 ${sc.BASE_MARGIN * 2}px;
  }
  ${breakpoint.below('mobile')} {
    padding: 0 ${sc.BASE_MARGIN * 2}px;
  }
`

export const Container = styled.div`
  ${({ compact }) => !compact && withMarginsCss};
  padding: ${({ isMobile }) => isMobile && '52px 32px 56px !important'};
  padding: ${({ noPaddingHorizontal }) =>
    noPaddingHorizontal && '52px 0px 56px !important'};

  ${breakpoint.below('mobile')} {
    ${({ noContainerPadding }) =>
      noContainerPadding &&
      css`
        padding: 0;
      `}
  }

  ${({ borderBottom }) =>
    borderBottom &&
    `
  border-bottom: 1px solid #e1e1e1;
   `}
  ${props =>
    props.fullContainerWidth &&
    `
    width:100%;
  `}
`

Container.propTypes = {
  compact: PropTypes.bool,
}

export default Container
