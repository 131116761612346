import React, { memo, useEffect, useRef, useState } from 'react'
//import { useRefMounted } from 'react-use'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import { PlayButton } from './PlayButton'

const Outer = styled.div``

const ImgPreview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(${({ bg }) => bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
const Preview = styled.div`
  background-color: #000000;
  .video__media {
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    transform: translate(-50%, -50%);
  }
`
const VideoButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  padding: 0;
  width: 68px;
  height: 48px;
  border: none;
  background-color: transparent;
  transform: translate(-50%, -50%);
  cursor: pointer;
  &:hover {
    .video__button-shape {
      fill: #ff0000;
      fill-opacity: 1;
    }
  }
  .video__button-shape {
    fill: #212121;
    fill-opacity: 0.8;
  }

  .video__button-icon {
    fill: #ffffff;
  }
  &:focus {
    outline: none;
  }
`
export const YoutubeEmbed = memo(props => {
  //const refMounted = useRefMounted()
  const { videoId } = props
  const ref = useRef(null)
  const [parentSize, setParentSize] = useState({
    width: 0,
    height: 0,
  })

  const [videoOpen, toggleVideo] = useState(false)

  useEffect(() => {
    // if (refMounted && ref.current.parentNode) {
    //   const { offsetHeight, offsetWidth } = ref.current.parentNode
    //   if (
    //     parentSize.width !== offsetWidth ||
    //     parentSize.height !== offsetHeight
    //   ) {
    //     setParentSize({
    //       height: offsetHeight,
    //       width: offsetWidth,
    //     })
    //   }
    // }
  })

  const options = {
    height: `${parentSize.height}px`,
    width: `${parentSize.width}px`,
    playerVars: {
      showinfo: 0,
      controls: 0,
      allowfullscreen: false,
      modestbranding: 0,
      title: 0,
      rel: 0,
      wmode: 'transparent',
      mode: 'opaque',
      autoplay: 1,
      fs: 0,
    },
    ...props.opts,
  }

  const openVideo = () => toggleVideo(true)

  return (
    <Outer ref={ref} height={parentSize.height} width={parentSize.width}>
      {videoOpen ? (
        <YouTube {...props} opts={options} />
      ) : (
        <Preview
          onClick={openVideo}
          height={parentSize.height}
          width={parentSize.width}
        >
          <ImgPreview
            bg={`https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`}
          />
          <VideoButton type="button" aria-label="Start video">
            <PlayButton />
          </VideoButton>
        </Preview>
      )}
    </Outer>
  )
})

export default YoutubeEmbed

// .video__button {
//   position: absolute;
//   top: 50%;
//   left: 50 %;
//   z - index: 1;
//   display: none;
//   padding: 0;
//   width: 68px;
//   height: 48px;
//   border: none;
//   background - color: transparent;
//   transform: translate(-50 %, -50 %);
//   cursor: pointer;
// }

// .video__button - shape {
//   fill: #212121;
//   fill - opacity: 0.8;
// }

// .video__button - icon {
//   fill: #ffffff;
// }

// .video__button: focus {
//   outline: none;
// }

// .video: hover.video__button - shape,
// .video__button: focus.video__button - shape {
//   fill: #ff0000;
//   fill - opacity: 1;
// }

// /* Enabled */

// .video--enabled {
//   cursor: pointer;
// }

// .video--enabled.video__button {
//   display: block;
// }
