import theme from 'components/signup/styles/theme'
import { useEffect, useState } from 'react'

const { default: styled } = require('styled-components')

const BannerContainer = styled.div`
  position: ${(props) => props.position || 'relative'};
  top: 0;
  left: 0;
  right: 0;
  //background-color: ${(props) => props.backgroundColor || theme.cPrimary};
  color: ${(props) => props.textColor || 'white'};
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  background: linear-gradient(to right, rgb(134, 250, 89), rgb(116, 253, 226));
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
`

const BannerText = styled.div`
  font-size: ${(props) => props.fontSize || '16px'};

  * {
    font-size: ${(props) => props.fontSize || '16px'};
    margin: 0;
    letter-spacing: inherit;
    font-weight: inherit;
    color: inherit;
  }
`

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${(props) => props.textColor || 'white'};
  font-size: 20px;
  cursor: pointer;
`

const Banner = ({
  message,
  backgroundColor,
  textColor,
  fontSize,
  containerStyle,
  position,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    const bannerState = sessionStorage.getItem('bannerState')
    if (bannerState !== null) {
      setIsOpen(JSON.parse(bannerState))
    }
  }, [])

  const handleClose = () => {
    setIsOpen(false)
    sessionStorage.setItem('bannerState', JSON.stringify(false))
  }
  return (
    isOpen && (
      <BannerContainer
        backgroundColor={backgroundColor}
        textColor={textColor}
        style={containerStyle}
        position={position}
      >
        <BannerText fontSize={fontSize} textColor={textColor}>
          {message}
        </BannerText>
        {props.children}
        <CloseButton textColor={textColor} onClick={handleClose}>
          ×
        </CloseButton>
      </BannerContainer>
    )
  )
}

export default Banner
