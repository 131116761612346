import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export class Logo extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
  }

  static defaultProps = {
    width: 70,
    height: 17,
  }

  render() {
    const { width, height } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 70 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Symbols" fill="none" fillRule="evenodd">
          <g id="Top-Nav-1024" transform="translate(-32 -39)" fill="#000">
            <g id="Top-Nav">
              <g id="Logo" transform="translate(32 39)">
                <g id="Group">
                  <path
                    d="M66.555242,6.16782412 L66.555103,6.16782412 C66.5514882,8.76665019 63.0238681,8.50517947 62.2490512,8.50617204 L62.2490512,6.16782412 L62.2490512,6.16399565 L62.2490512,3.82564773 C63.0238681,3.8264985 66.5514882,3.56516958 66.555103,6.16399565 L66.555242,6.16399565 L66.555242,6.16583899 L66.555242,6.16782412 Z M66.8324688,10.6725221 C68.2988232,9.97857546 69.4256651,8.66257236 69.4256651,6.16640617 C69.4256651,1.35231059 65.2390406,0.921110786 62.4986109,0.896296591 C62.4767832,0.896013001 62.4546773,0.89572941 62.4329886,0.895587614 C62.3800179,0.895162228 62.3284376,0.895020433 62.2765793,0.895020433 C62.2468268,0.894878638 62.2162401,0.894736842 62.1867656,0.894736842 C62.1767554,0.894736842 62.1674404,0.894736842 62.1575692,0.894736842 C62.0823537,0.894736842 62.0092237,0.895162228 61.9367888,0.895445819 C61.9152391,0.895587614 61.8922991,0.895587614 61.8708884,0.89572941 C61.7910849,0.895162228 61.7125327,0.895020433 61.6353707,0.895020433 L61.6291144,0.895020433 C61.6168797,0.895020433 61.6054792,0.895162228 61.5933835,0.895162228 C61.5101043,0.895162228 61.4289105,0.895445819 61.349246,0.895871205 C61.2239796,0.896438387 61.1020499,0.897289159 60.9870717,0.897856341 L59.3784891,0.897856341 L59.3670886,0.897856341 L59.3670886,6.16640617 L59.3670886,11.434956 L59.3784891,11.434956 L59.3784891,17 L62.2490512,17 L62.2490512,11.4379337 C62.3834937,11.4377919 62.52169,11.4366575 62.6637791,11.4343888 C62.667533,11.4343888 62.6711478,11.4343888 62.6749016,11.434247 C62.8087879,11.4321201 62.9457329,11.4285752 63.0850415,11.4237542 C63.1061741,11.4229034 63.1273067,11.4220526 63.1485784,11.4212018 C63.420522,11.4109926 63.7008074,11.3941189 63.9856809,11.3681704 C64.0075086,11.366327 64.0287803,11.3653345 64.0506081,11.3632075 L66.8618042,17 L70,17 L66.8324688,10.6725221 Z"
                    id="Fill-1"
                  />
                  <path
                    d="M33.2278481,0 L33.2278481,3.25720306 C36.0908776,3.25720306 38.4199231,5.60917812 38.4199231,8.50007222 C38.4199231,11.3908219 36.0908776,13.7429414 33.2278481,13.7429414 C30.3649617,13.7429414 28.0357731,11.3908219 28.0357731,8.50007222 C28.0357731,8.49935 28.0359161,8.49877223 28.0359161,8.49805001 L24.8102696,8.49805001 C24.8102696,8.49877223 24.8101266,8.49935 24.8101266,8.50007222 C24.8101266,13.1944891 28.5789119,17 33.2278481,17 C37.8767843,17 41.6455696,13.1944891 41.6455696,8.50007222 C41.6455696,3.80551095 37.8767843,0 33.2278481,0"
                    id="Fill-3"
                  />
                  <path
                    d="M7.9484999,4.62134099 C7.11165312,3.50634299 6.25994933,2.94877176 5.39265303,2.94877176 C4.97033151,2.94877176 4.62494282,3.05710814 4.35633987,3.27334755 C4.08773691,3.48987586 3.95343543,3.73384938 3.95343543,4.00512367 C3.95343543,4.27668686 4.04919694,4.53337185 4.24130837,4.7753231 C4.50211507,5.09830995 5.28880107,5.79151832 6.60121924,6.85494821 C7.82905543,7.83806473 8.57352398,8.45789326 8.83462489,8.71457825 C9.4868623,9.34552932 9.94919477,9.94860182 10.2217694,10.5246625 C10.4941969,11.1004342 10.6305578,11.729363 10.6305578,12.4115933 C10.6305578,13.7395084 10.1510147,14.836017 9.1923699,15.7016968 C8.23343086,16.5672323 6.9833827,17 5.44193123,17 C4.23763089,17 3.1888143,16.7174587 2.29518727,16.1528095 C1.40170733,15.5878714 0.636497809,14.7003798 0,13.4899014 L2.70485826,11.9275463 C3.51831629,13.3578754 4.45445497,14.07304 5.51342139,14.07304 C6.06563142,14.07304 6.53002328,13.9192023 6.90600858,13.6109492 C7.28214098,13.302985 7.47013363,12.9472083 7.47013363,12.5436192 C7.47013363,12.1770089 7.3280359,11.8102541 7.04413463,11.4433549 C6.76008627,11.0766002 6.13476799,10.5154178 5.16773851,9.75980763 C3.32561647,8.32211167 2.13587894,7.21245826 1.59867303,6.43128075 C1.06146711,5.65010324 0.793011252,4.87065911 0.793011252,4.09323726 C0.793011252,2.97087238 1.23989832,2.00797866 2.13352536,1.20484497 C3.02700529,0.401566841 4.12980725,0 5.44148993,0 C6.28539747,0 7.08885276,0.186916364 7.85200289,0.561182438 C8.61530013,0.935159616 9.44170288,1.61753435 10.3316524,2.60787329 L10.6329114,2.91785978 L8.20930661,4.96541733 L7.9484999,4.62134099 Z"
                    id="Fill-6"
                  />
                  <polygon
                    id="Fill-9"
                    points="55.2467797 0.894736842 51.7892146 0.894736842 47.8456016 6.2929407 47.1836838 7.18500654 47.1836838 0.894736842 44.3037975 0.894736842 44.3037975 17 47.1836838 17 47.1836838 8.95835969 47.8456016 9.97083315 52.4080366 17 55.8227848 17 50.0632911 8.21563333"
                  />
                  <polygon
                    id="Fill-11"
                    points="13.2911392 3.82295361 16.2447257 3.82295361 16.2447257 17 19.1983122 17 19.1983122 3.82295361 22.1518987 3.82295361 22.1518987 0.894736842 13.2911392 0.894736842"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Logo
