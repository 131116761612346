/*  */
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { breakpoint } from 'utils'

const withMarginsHelper = ({ compact, compactTop }) =>
  typeof compact === 'string'
    ? css`
        padding-left: 65px;
        padding-right: 65px;
        padding-top: ${compactTop ? 0 : 50}px;
        padding-bottom: ${compactTop ? 0 : 50}px;
        padding-${compact}: 0px;

        ${breakpoint.below('tablet')} {
          padding-left: ${sc.BASE_MARGIN * 3}px;
          padding-right: ${sc.BASE_MARGIN * 3}px;
          ${!compactTop &&
            css`
              padding-top: ${sc.BASE_MARGIN * 2}px;
              padding-bottom: ${sc.BASE_MARGIN * 2}px;
            `}
          padding-${compact}: 0px;
        }

        ${breakpoint.below('mobile')} {
          padding-left: ${sc.BASE_MARGIN * 2}px;
          padding-right: ${sc.BASE_MARGIN * 2}px;
          ${!compactTop &&
            css`
              padding-top: ${sc.BASE_MARGIN * 2}px;
              padding-bottom: ${sc.BASE_MARGIN * 2}px;
            `}
          padding-${compact}: 0px;
        }

      `
    : css`
        padding-left: 65px;
        padding-right: 65px;
        padding-top: ${compactTop ? 0 : 50}px;
        padding-bottom: ${compactTop ? 0 : 50}px;
        ${breakpoint.below('tablet')} {
          padding-left: ${sc.BASE_MARGIN * 3}px;
          padding-right: ${sc.BASE_MARGIN * 3}px;
          ${!compactTop &&
            css`
              padding-top: ${sc.BASE_MARGIN * 2}px;
              padding-bottom: ${sc.BASE_MARGIN * 2}px;
            `}
        }

        ${breakpoint.below('mobile')} {
          padding-left: ${sc.BASE_MARGIN * 2}px;
          padding-right: ${sc.BASE_MARGIN * 2}px;
          ${!compactTop &&
            css`
              padding-top: ${sc.BASE_MARGIN * 2}px;
              padding-bottom: ${sc.BASE_MARGIN * 2}px;
            `}
        }
      `

export const Content = styled.div`
  ${withMarginsHelper};

  padding: 30px 0 20px 65px;
  padding-top: ${({ noPaddingTop }) => noPaddingTop && '0 !important'};
  padding-bottom: ${({ noPaddingBottom }) => noPaddingBottom && '0 !important'};

  ${({ withBottomBorder }) =>
    withBottomBorder &&
    css`
      border-bottom: 1px solid rgb(237, 237, 237);
      position: relative;
      top: 1px;
    `}

  @media screen and (max-width: 991px) {
    display: ${({ desktop }) => desktop && 'none'};
  }

  @media screen and (min-width: 992px) {
    /* padding: 30px 3% 10px; */
    padding: ${({ bigPadding }) =>
      bigPadding ? '50px 65px !important' : '64px 3% 62px'};

    &:first-of-type {
      padding-left: 65px;
    }

    &:last-of-type {
      padding-right: 65px;
    }

    ${({ paddingLeft }) =>
      paddingLeft &&
      css`
        padding-left: 65px;
        padding-right: 0;
      `}
  }

  @media screen and (min-width: 1161px) {
    padding: 64px 65px 62px;
  }

  /* FIX SIDE GAP */
  @media screen and (min-width: 768px) and (max-width: 1160px) {
    padding: ${({ fixSideGap }) => fixSideGap && '0 65px'};
  }

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      display: none;

      @media screen and (min-width: 1020px) {
        display: block;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      @media screen and (min-width: 1020px) {
        display: none;
      }
    `}
`

Content.propTypes = {
  compact: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default Content
