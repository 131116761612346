import styled, { css } from 'styled-components'
import * as sc from 'constants/style'
import { config } from 'utils'

export const RichText = styled.div`
  color: ${({ active }) => active && '#202020'};

  ${({ is101 }) =>
    is101 &&
    css`
      line-height: 26px;
      @media screen and (min-width: 1020px) {
        margin-right: 92px;
      }
    `};

  ${({ inactive }) =>
    inactive &&
    css`
      filter: blur(3px);
    `};

  @media screen and (max-width: ${config.tablet}) {
    color: ${({ isWhite }) => isWhite && sc.WHITE_HEX};
  }

  ul {
    padding-left: ${sc.BASE_MARGIN}px;
    li {
      list-style: none;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0.5em;
        left: -${sc.BASE_MARGIN}px;
        height: 4px;
        width: 4px;
        background-color: ${sc.RED_BASE_HEX};
        border-radius: 50%;
        transform: translateY(50%);
      }
    }
  }

  ${({ paddingBottom }) =>
    paddingBottom &&
    `
  padding-bottom: ${paddingBottom};
  `}
`

export default RichText
