/*  */
import styled, { css } from 'styled-components/macro'
import { Form } from 'formik'
import rwd from 'components/signup/styles/rwd'

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const FormField = styled.div`
  position: relative;
  /* display: flex; */

  ${({ isRow }) =>
    isRow &&
    css`
      display: flex !important;
      flex-direction: row !important;
      align-items: center;
    `}

  @media screen and (max-width: 767px) {
  }
`

export const FormInfo = styled.div`
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 9px;
  line-height: 1.78em;
  letter-spacing: 0.4px;
  color: ${props => props.theme.cBlack};
    color: #9b9b9b;
  ${props => props.center && 'text-align: center;'}
  ${props => props.marginTop && 'margin-top: 18px;'}
  
  ${rwd.Large`
    font-size: 12px;
  `}

  a {
    text-decoration: underline;
    transition: color .2s;

    &:hover {
      color: ${props => props.theme.cPrimary};
    }
  }
`

export const FormError = styled.div`
  display: none;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  margin: 0;
  position: relative;
  left: 0;
  top: 2px;
  margin-bottom: -12px;
  letter-spacing: 0.4px;
  color: ${props => props.theme.cWarning};
  color: ${props => props.isSuccess && props.theme.cSuccess};

  ${props => props.show && 'display: block;'}
  ${({ withMarginLeft }) => withMarginLeft && 'margin-left: 28px;'}
`
