import React, { memo } from 'react'
import styled from 'styled-components'
import * as sc from 'constants/style'
import Icon from 'components/ui/icon'

const Outer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`
const ArrowWrapper = styled.div`
  color: ${sc.RED_BASE_HEX};
`
const Currency = styled.div`
  padding-right: ${sc.BASE_MARGIN}px;
`

export const CurrencySelector = memo(() => (
  <Outer>
    <Currency>EUR</Currency>
    <ArrowWrapper>
      <Icon name="ArrowSimple" direction="bottom" />
    </ArrowWrapper>
  </Outer>
))

CurrencySelector.propTypes = {}

export default CurrencySelector
